import React, { useEffect, useState } from "react";
import { IonAvatar } from "@ionic/react";
import { getContrastingTextColor } from "../utils/UtilityFunctions";

interface UserAvatarProps {
  profileColor?: string;
  initials?: string;
}

const UserAvatar: React.FC<UserAvatarProps> = ({ profileColor, initials }) => {
  if (!profileColor) {
    // Return a default avatar if the profileColor is not available
    return <IonAvatar />;
  }

  const textColor = getContrastingTextColor(profileColor);

  return (
    <IonAvatar style={{ backgroundColor: profileColor }}>
      <span className="initials" style={{ color: textColor }}>
        {initials}
      </span>
    </IonAvatar>
  );
};

export default UserAvatar;
