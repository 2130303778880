import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonInput,
  IonButtons,
  IonButton,
  IonAlert,
  IonList,
  IonItem,
  IonLabel,
  IonThumbnail,
  IonIcon,
  IonToast,
  IonModal,
  IonPopover,
  IonText,
  IonProgressBar,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent
} from '@ionic/react';
import { useHistory, RouteComponentProps, withRouter } from 'react-router-dom';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
  getFirestore,
  collection,
  doc,
  updateDoc,
  deleteDoc,
  setDoc,
  onSnapshot,
  query,
  where,
  getDocs,
  orderBy,
  DocumentData,
} from 'firebase/firestore';
import {
  trashOutline,
  createOutline,
  copyOutline,
  shareOutline,
  ellipsisVerticalOutline,
  ellipsisVertical,
  bulb,
  radioOutline,
} from 'ionicons/icons';
import { auth, onAuthStateChange } from '../../services/firebaseAuth';
import { User } from 'firebase/auth';
import HeaderToolbar from '../../components/HeaderToolbar';
import SideMenu from '../../components/SideMenu';
import { getCachedUser, cacheBuilds, loadBuilds } from "../../services/userCache";
import {
  getFormattedCreatedAt,
  getContrastingTextColor,
  isValidUrl,
  getWebsiteDomain,
  isSingleEmoji,
  getBaseDomain,
  convertUsernameMentionsToLinks,
  shortenNumber,
  getFormattedLastUpdated
} from '../../utils/UtilityFunctions';
import './WorkshopScreen.css';

interface Build {
  id: string;
  name: string;
  thumbnail?: string;
  timestampUpdated?: any;
}

const WorkshopScreen: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [username, setUsername] = useState<string | null>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [builds, setBuilds] = useState<Build[]>([]);

  // Get the user's data from the cached data
  const cachedUser = getCachedUser();
  const userRole = cachedUser?.role;

  // Set the totalBuilds based on the user's role
  const totalBuilds = useMemo(() => {
    if (userRole === "admin") {
      return 5;
    } else if (userRole === "basic") {
      return 5;
    } else {
      // Default for other roles or in case of any issues with role data
      return 5;
    }
  }, [userRole]);

  const buildCount = builds.length;
  const progressBarPercentage = (buildCount / totalBuilds) * 100;

  const history = useHistory();
  const db = getFirestore();

  useEffect(() => {
    const unsubscribe = onAuthStateChange((user: User) => {
      setUser(user);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (user) {
      setIsLoading(true); // Set loading state to true when fetching builds

      const uid = user.uid;

      // Check if the cached user data exists
      const cachedUser = getCachedUser();
      if (cachedUser && cachedUser.builds) {
        setBuilds(cachedUser.builds); // Use the cached builds
        setIsLoading(false); // Set loading state to false after setting the builds
      } else {
        const userDocRef = doc(db, "users", uid);
        onSnapshot(userDocRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            setUsername(docSnapshot.data()?.username ?? null);
          }
        });

        // Fetch the user's builds from Firestore
        const buildsQuery = query(
          collection(db, 'builds'),
          where('userID', '==', uid),
          orderBy('timestampUpdated', 'desc')
        );
        getDocs(buildsQuery).then((querySnapshot) => {
          const builds: Build[] = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            builds.push({
              id: doc.id,
              name: data.name,
              timestampUpdated: data.timestampUpdated,
              thumbnail: data.thumbnail,
            });
          });
          setBuilds(builds);
          cacheBuilds(builds); // Cache the builds
          setIsLoading(false); // Set loading state to false after fetching and setting the builds
        });
      }
    }
  }, [db, user]);

  const handleBuildDelete = (deletedBuildId: string) => {
    setBuilds(prevBuilds => prevBuilds.filter(build => build.id !== deletedBuildId));
  };

  return (
    <>
      <SideMenu/>
      <IonPage class="workshopScreen" id="main-content">
        <HeaderToolbar />

        <IonContent>

          <div className="limitedWidth">
              {user && (
                <>
                  <div className="workshop-header">

                  {!isLoading && builds.length !== 0 && (

                    <>

                    <IonCard className="hint-notice">
                      <IonCardContent>
                        <div className="icon-text-container">
                          <div className="icon-container">
                            <IonIcon icon={bulb} className="bulb-icon" color="primary" />
                          </div>
                          <span className="text-content">
                            Post your builds using the big yellow + button at the bottom of the screen to share them with the world.
                          </span>
                        </div>
                      </IonCardContent>
                    </IonCard>

                    <IonProgressBar
                      className="progressBar"
                      value={progressBarPercentage / 100}
                      color="primary"
                    />
                    <div className="buildsTextContainer">
                      <div className="buildsTextLeft">Active builds</div>
                      <div className="buildsTextRight">
                        {buildCount}/{totalBuilds}
                      </div>
                    </div>

                    </>

                    )}

                  </div>
                  <IonList>
                    {user && username && (
                      <BuildsList uid={user.uid} username={username} onBuildDelete={handleBuildDelete} builds={builds} totalBuilds={totalBuilds} />
                    )}
                  </IonList>
                </>
              )}
          </div>
        </IonContent>
      </IonPage>
    </>
  );

  interface BuildsListProps {
    uid: string;
    username: string;
    onBuildDelete: (deletedBuildId: string) => void;
    builds: Build[];
    totalBuilds: number;
  }

  function BuildsList({ uid, username, onBuildDelete, builds: listBuilds, totalBuilds }: BuildsListProps) {
    const [builds, setBuilds] = useState<Build[]>(listBuilds);
    const [selectedBuildId, setSelectedBuildId] = useState<string | null>(null);
    const [showCopyToast, setShowCopyToast] = useState<boolean>(false);
    const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
    const [showRenameAlert, setShowRenameAlert] = useState<boolean>(false);
    const [newBuildName, setNewBuildName] = useState<string>('');
    const [showRenameModal, setShowRenameModal] = useState<boolean>(false);
    const [newBuildNameInput, setNewBuildNameInput] = useState('');

    const deleteBuildConfirmationToastRef = useRef<HTMLIonToastElement>(null);

    useEffect(() => {
      const cachedUser = getCachedUser();
      if (cachedUser && cachedUser.builds) {
        setBuilds(cachedUser.builds);
      } else {
        const buildsQuery = query(collection(db, "builds"), where("userID", "==", uid));
        const unsubscribe = onSnapshot(buildsQuery, (snapshot) => {
          const builds: Build[] = snapshot.docs.map((doc) => {
            const data = doc.data();
            return {
              id: doc.id,
              name: data.name,
              timestampUpdated: data.timestampUpdated,
              thumbnail: data.thumbnail,
            };
          });

          setBuilds(builds);
          cacheBuilds(builds); // Cache the builds
        });
        return () => unsubscribe();
      }
    }, [db, uid]);

    const handleCopyLink = () => {
      const baseDomain = window.location.origin;
      const link = `${baseDomain}/view/${username}/${selectedBuildId}`;
      navigator.clipboard.writeText(link);
      setShowCopyToast(true);
    };

    const handleShare = () => {
      const baseDomain = window.location.origin;
      const link = `${baseDomain}/view/${username}/${selectedBuildId}`;

      if (navigator.share) {
        navigator
          .share({
            title: "Share Build",
            text: "Check out my Blokkiti build:",
            url: link,
          })
          .then(() => {
            // Success, handle any additional logic if needed
          })
          .catch((error) => {
            // Error handling, show an error message or perform fallback action
            console.error("Error sharing build:", error);
          });
      } else {
        // Fallback for browsers that do not support navigator.share
        // You can show a dialog or perform an alternative action
        alert("Share functionality is not supported on this browser. Copy the link instead: " + link);
      }
    };

    const handleRename = (buildId: string) => {
      setSelectedBuildId(buildId);
      const buildToRename = builds.find((build) => build.id === buildId);
      if (buildToRename) {
        setNewBuildName(buildToRename.name); // Set the current build name as the initial value
      }
      setShowRenameModal(true);
    };

    const handleRenameConfirm = async () => {
      // Perform rename logic here, e.g., update the build name in the database
      const renamedBuild = builds.find((build) => build.id === selectedBuildId);

      const buildId = renamedBuild?.id;

      try {
        const buildRef = doc(collection(db, "builds"), buildId);
        await updateDoc(buildRef, { name: newBuildName }); // Use the newBuildName variable here
        // Update the build in the state or perform necessary actions
        setBuilds((prevBuilds) =>
          prevBuilds.map((build) => (build.id === selectedBuildId ? { ...build, name: newBuildName } : build)) // Update the name property
        );
        setShowRenameModal(false);
      } catch (error) {
        console.error("Error updating build name:", error);
        // Handle error scenario, e.g., show an error message
      }
    };

    const handleDelete = (buildId: string) => {
      setSelectedBuildId(buildId);
      setShowDeleteAlert(true); // Add this line to open the delete confirmation alert
    };

    const confirmDelete = async () => {
      const buildId = selectedBuildId;
      if (buildId === null) {
        // Handle the case when buildId is null
        return;
      }
      // Delete the build document from Firestore
      const buildRef = doc<DocumentData>(collection(db, "builds"), buildId);

      if (buildRef) {
        await deleteDoc(buildRef);
      }

      deleteBuildConfirmationToastRef.current?.present();

      // Call the onBuildDelete callback with the deleted build's ID
      onBuildDelete(selectedBuildId!);

      // Remove the build from the list
      setBuilds((prevBuilds) => prevBuilds.filter((build) => build.id !== selectedBuildId));
      // Close the delete confirmation alert
      setShowDeleteAlert(false);

    };

    useEffect(() => {
      setBuilds(listBuilds); // Update the local state when builds prop changes
    }, [listBuilds]);

    const handleModalDismiss = () => {
      setNewBuildName("");
      setShowRenameModal(false);
    };


    return (
    <>
      {!isLoading && builds.length === 0 ? (
        <div className="no-builds-text">
          <img src="assets/images/no-builds.svg" alt="illustration of a cube magically forming with particle floating around it" height="200px" width="400px" />
          <p><b>No builds yet</b><br /><span>Click the big yellow + button below to start creating</span></p>
        </div>
      ) : (
        builds.map((build) => (
          <IonItem key={build.id} detail={false} lines="none" button onClick={() => history.push(`/create/${build.id}`)}>
            <IonThumbnail slot="start">
              {build.thumbnail ? (
                <img src={build.thumbnail} alt="Project Thumbnail" style={{}} />
              ) : (
                <div
                  className="thumbnailBlank"
                  style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#d8ebf2',
                  }}
                ></div>
              )}
            </IonThumbnail>
            <IonLabel>
              {build.name}
              <span style={{ color: 'var(--ion-color-medium)', fontSize: '0.8em' }}>
                <br />
                Last modified {getFormattedLastUpdated(build.timestampUpdated)}
              </span>
            </IonLabel>
            <IonButton
              fill="clear"
              id={`share-popover-${build.id}`}
              className="heart-button"
              onClick={(event) => {
                event.stopPropagation();
                setSelectedBuildId(build.id); // Fix the typo here
              }}
            >
              <IonIcon icon={ellipsisVertical} slot="icon-only" color="medium" size="small" />
            </IonButton>
            <IonPopover className="postSharePopover" trigger={`share-popover-${build.id}`} dismissOnSelect={true}>
              <IonContent>
                <IonList lines="none">
                  <IonItem
                    button={true}
                    detail={false}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleCopyLink();
                    }}
                  >
                    <IonIcon slot="start" icon={radioOutline} color="primary" />
                    <IonLabel>Copy live-view link</IonLabel>
                  </IonItem>
                  <IonItem
                    button={true}
                    detail={false}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleShare();
                    }}
                  >
                    <IonIcon slot="start" icon={shareOutline} color="primary" />
                    <IonLabel>Share live-view link</IonLabel>
                  </IonItem>
                  <IonItem
                    button={true}
                    detail={false}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleRename(build.id); // Pass projectId argument here
                    }}
                  >
                    <IonIcon slot="start" icon={createOutline} color="primary" />
                    <IonLabel>Rename build</IonLabel>
                  </IonItem>
                  <IonItem
                    button={true}
                    detail={false}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleDelete(build.id); // Pass projectId argument here
                    }}
                  >
                    <IonIcon slot="start" icon={trashOutline} color="primary" />
                    <IonLabel>Delete build</IonLabel>
                  </IonItem>
                </IonList>
              </IonContent>
            </IonPopover>
          </IonItem>
        ))
      )}

        <IonModal isOpen={showRenameModal} onDidDismiss={handleModalDismiss}>
          <IonHeader>
            <IonToolbar color="medium" mode="ios">
              <IonButtons slot="start">
                <IonButton onClick={handleModalDismiss}>Cancel</IonButton>
              </IonButtons>
              <IonTitle>Rename build</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={handleRenameConfirm} color="primary" style={{ fontWeight: 'bold' }}>Done</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonItem>
              <IonInput
                value={newBuildName} // Fix the variable name here
                onIonChange={(e) => {
                  const value = e.detail.value as string;
                  if (value.length <= 100) { // Limiting to 100 characters
                    setNewBuildName(value); // Fix the function name here
                  }
                }}
                label="Build name"
                labelPlacement="floating"
                maxlength={100}
              ></IonInput>
            </IonItem>
          </IonContent>
        </IonModal>

        <IonAlert
          isOpen={showDeleteAlert}
          onDidDismiss={() => setShowDeleteAlert(false)}
          header={'Confirm delete'}
          message={`Are you sure you want to delete ${builds.find((build) => build.id === selectedBuildId)?.name}?`}
          buttons={[
            {
              text: 'Cancel',
              role: 'cancel',
            },
            {
              text: 'Delete',
              handler: confirmDelete,
            },
          ]}
        />

        <IonToast
          isOpen={showCopyToast}
          onDidDismiss={() => setShowCopyToast(false)}
          message="Link copied to clipboard!"
          duration={2000}
          position="top"
          color="medium"
          class="auto-width-toast"
        />

        <IonToast
          ref={deleteBuildConfirmationToastRef}
          message="Build deleted."
          color="medium"
          position="top"
          duration={3000}
          class="auto-width-toast"
        />

      </>
    );
  }
};
export default WorkshopScreen;
