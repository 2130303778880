import { IonPage, IonContent, IonText, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonButton, IonIcon, IonGrid, IonRow, IonCol } from '@ionic/react';
import { shareOutline, copyOutline, cubeOutline, linkOutline } from 'ionicons/icons';
import { RouteComponentProps, withRouter } from 'react-router';
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useRouteMatch, useHistory, useParams } from 'react-router-dom';
import { getFirestore, doc, updateDoc, getDoc, getDocs, onSnapshot, collection, query, where, limit, DocumentData, DocumentReference } from 'firebase/firestore';
import { auth, onAuthStateChange } from '../services/firebaseAuth';
import { User } from 'firebase/auth';
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

interface Object {
  id: string,
  shape: string,
  color: number,
  position: [number, number, number],
  scale: [number, number, number],
  rotation: [number, number, number, EulerOrder]
}
type EulerOrder = "XYZ" | "YZX" | "ZXY" | "XZY" | "YXZ" | "ZYX";

interface BuildPreviewLiveProps {
  attachedBuildObjects: any;
}

const BuildPreviewLive: React.FC<BuildPreviewLiveProps> = ({ attachedBuildObjects }) => {

  const [canvasDimensions, setCanvasDimensions] = useState({ width: 800, height: 600 });
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const sceneRef = useRef<THREE.Scene | undefined>();
  const cameraRef = useRef<THREE.PerspectiveCamera | undefined>();
  const controlsRef = useRef<OrbitControls | null>(null);

  const [skyObjectRefs, setSkyObjectRefs] = useState<THREE.Mesh[]>([]);
  const [objectRefs, setObjectRefs] = useState<THREE.Mesh[]>([]);
  let floorMesh: THREE.Mesh | null = null;

  const [userAuthenticated, setUserAuthenticated] = useState<boolean>(false);
  const [userProject, setUserProject] = useState<any>(null);
  const [currentProjectId, setCurrentProjectId] = useState<string | undefined>(undefined);
  const [projectExists, setProjectExists] = useState<boolean>(false);

  const [projectName, setProjectName] = useState<string>("");
  
  const [createdBy, setCreatedBy] = useState<string>("");
  const [lastUpdated, setLastUpdated] = useState<string>("");
  const [blokkiCount, setBlokkiCount] = useState<number>(0);

  const [capturedImage, setCapturedImage] = useState<string | null>(null);

  const [rootDomain, setRootDomain] = useState<string>("");
  const history = useHistory();
  const user = auth.currentUser;
  const db = getFirestore();

  const [formattedLastUpdated, setFormattedLastUpdated] = useState<string>("");

  // Helper function to check if a file exists at a given URL
  async function checkFileExists(url: string) {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      return response.ok;
    } catch (error) {
      console.error(`Error checking if file exists at ${url}:`, error);
      return false;
    }
  }

  useEffect(() => {
    if (attachedBuildObjects.length === 0) {
      // If the array is empty, remove all objects from the scene and clear the state
      objectRefs.forEach((object) => {
        sceneRef.current?.remove(object);
      });
      setObjectRefs([]);
      setProjectExists(false);
      return;
    }

    // Create a map of existing objects for efficient lookup
    const existingObjectsMap = new Map<string, THREE.Mesh>();
    objectRefs.forEach((object) => {
      existingObjectsMap.set(object.userData.id, object);
    });

    // Function to load and add a single object to the scene
    async function loadAndAddObject(object: Object) {
      const existingObject = existingObjectsMap.get(object.id);
      if (existingObject) {
        // If the object already exists, update its properties
        // ... Update position, rotation, scale, color, etc. based on object properties ...
        // Update the object in the scene (if necessary)

        // Remove the object from the map to track remaining objects that need to be removed
        existingObjectsMap.delete(object.id);
      } else {
        // If the object does not exist in the scene, load it and add it to the scene
        const shapeFileName = `${object.shape}.gltf`;
        const objectFileUrl = `/assets/objects/${shapeFileName}`;

        try {
          const response = await fetch(objectFileUrl);
          if (!response.ok) {
            console.error(`Error loading object from ${objectFileUrl}`);
            return;
          }

          const gltf = await new GLTFLoader().loadAsync(objectFileUrl);
          const mesh = gltf.scene.children[0] as THREE.Mesh;

          // ... Perform the necessary setup for the mesh based on the object properties ...
          const euler = new THREE.Euler(
            object.rotation[0],
            object.rotation[1],
            object.rotation[2],
            object.rotation[3]
          );
          const quaternion = new THREE.Quaternion().setFromEuler(euler);
          mesh.setRotationFromQuaternion(quaternion);

          mesh.position.set(
            object.position[0] as number,
            object.position[1] as number - 1,
            object.position[2] as number
          );

          mesh.scale.set(
            object.scale[0] as number,
            object.scale[1] as number,
            object.scale[2] as number
          );

          const color = new THREE.Color(object.color);
          mesh.material = new THREE.MeshStandardMaterial({
            color: color,
            roughness: 1.0,
            metalness: 0.0,
            transparent: true,
            opacity: 1,
          });

          mesh.castShadow = true;
          mesh.receiveShadow = true;
          mesh.userData.isSelectable = true;
          mesh.userData.color = object.color; // Add color value to userData
          mesh.userData.shape = object.shape; // Add shape value to userData
          mesh.userData.id = object.id;

          mesh.geometry.computeBoundingBox();

          sceneRef.current?.add(mesh);
          setObjectRefs((prevObjectRefs) => [...prevObjectRefs, mesh]);

          // Update the project existence status
          setProjectExists(true);
          setBlokkiCount(attachedBuildObjects.length); // Set blokkiCount to the total number of objects
        } catch (error) {
          console.error(`Error loading object from ${objectFileUrl}:`, error);
        }
      }
    }

    // Load and add objects one by one
    const promises = attachedBuildObjects.map(loadAndAddObject);
    Promise.all(promises).then(() => {
      // At this point, the existingObjectsMap only contains objects that need to be removed
      const objectsToRemove = Array.from(existingObjectsMap.values());
      objectsToRemove.forEach((object) => {
        sceneRef.current?.remove(object);
      });

      // Update the state with the updated objectRefs array
      setObjectRefs((prevObjectRefs) => prevObjectRefs.filter((obj) => !objectsToRemove.includes(obj)));

      // Update the project existence status
      setProjectExists(true);
      setBlokkiCount(attachedBuildObjects.length); // Set blokkiCount to the total number of objects
    });
  }, [attachedBuildObjects]);


  useEffect(() => {
    // Constants
    const colorPalette = {
      background: 0xd8ebf2,
      floor: 0xb4ccd6,
      cube: 0xe5eef2,
    };
    
    const boxDimensions = new THREE.Box3(
      new THREE.Vector3(-3, -3, -3),
      new THREE.Vector3(3, 3, 3)
    );
    const forbiddenArea = new THREE.Box3(
      new THREE.Vector3(-5, -0.0625, -5),
      new THREE.Vector3(5, 10.0625, 5)
    );
    const cubeCount = 20;
    const cubeSize = { width: 0.25, height: 0.25, depth: 0.25 };

    // Initialize the Three.js scene
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(colorPalette.background);
    scene.fog = new THREE.Fog(colorPalette.background, 8, 40);
    sceneRef.current = scene;


    // Create and set up the camera
    const camera = new THREE.PerspectiveCamera(50, 1920 / 1080, 0.1, 1000);
    camera.position.set(10, 3, 10);
    camera.lookAt(0, 0, 0); // Adjust the target position to y = 1

    cameraRef.current = camera;

    // Create the renderer
    const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current!, antialias: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    renderer.setSize(canvasDimensions.width, canvasDimensions.height);

    // Initialize the camera aspect ratio
    camera.aspect = canvasDimensions.width / canvasDimensions.height;
    camera.updateProjectionMatrix();

    // Initialize objects, lights, and controls
    const initScene = () => {
      // Create the floor
      const floorGeometry = new THREE.BoxGeometry(10, 0.0625, 10);
      const floorMaterial = new THREE.MeshStandardMaterial({ color: colorPalette.floor, visible: false });
      const floor = createMesh(floorGeometry, floorMaterial);
      floor.receiveShadow = true;
      floor.position.y = -0.15625;
      scene.add(floor);
    }

    // Create a mesh with specified geometry and material
    const createMesh = (geometry: THREE.BufferGeometry, material: THREE.Material) => {
      const mesh = new THREE.Mesh(geometry, material);
      mesh.material.transparent = true;
      mesh.material.opacity = 0.25;
      return mesh;
    };

    // Initialize lights
    const initLights = () => {
      // Lighting // Directional Light 1
      const dirLight = new THREE.DirectionalLight(0xffffff, 0.1); // Adjust color and intensity
      dirLight.position.set(2.15, 30, 2.15); // Adjust x, y, and z positions
      dirLight.target.position.set(0, 0, 0);
      dirLight.castShadow = true;
      dirLight.shadow.mapSize.width = 2048;
      dirLight.shadow.mapSize.height = 2048;
      dirLight.shadow.bias = -0.001; // Adjust as needed
      dirLight.shadow.normalBias = .02;
      dirLight.shadow.radius = 1;
      dirLight.shadow.camera.near = .1;
      dirLight.shadow.camera.far = 50;
      dirLight.shadow.camera.left = -15;
      dirLight.shadow.camera.right = 15;
      dirLight.shadow.camera.top = 15;
      dirLight.shadow.camera.bottom = -15;

      scene.add(dirLight);

      const ambientLight = new THREE.AmbientLight(0xffffff, .82); // color, intensity
      scene.add(ambientLight);

      // Lighting // Hemisphere Light 3
      const hemiLight3 = new THREE.HemisphereLight(0xf7efe3, 0x888888, .22); // Adjust color and intensity
      hemiLight3.position.set(15, 5, -3);

      hemiLight3.castShadow = false;
      scene.add(hemiLight3);
    };

    // Create random cubes outside the central part and forbidden area
    const createRandomCubes = () => {
      const geometry = new THREE.BoxGeometry(cubeSize.width, cubeSize.height, cubeSize.depth);
      const material = new THREE.MeshStandardMaterial({ color: colorPalette.cube });

      for (let i = 0; i < cubeCount; i++) {
        const cube = createMesh(geometry, material);
        cube.material.opacity = 1;

        let isInsideSceneBox = true;
        while (isInsideSceneBox) {
          cube.position.set(
            (Math.random() > 0.5 ? 1 : -1) * (Math.random() * 14 + 0),
            Math.random() * 40 - 20,
            (Math.random() > 0.5 ? 1 : -1) * (Math.random() * 14 + 0)
          );

          // Update cubeBox with the new cube position
          const cubeBox = new THREE.Box3().setFromObject(cube);

          // Check if the updated cubeBox intersects with forbiddenArea or boxDimensions
          isInsideSceneBox = boxDimensions.intersectsBox(cubeBox) || forbiddenArea.intersectsBox(cubeBox);
        }

        scene.add(cube);
        setSkyObjectRefs(prevSkyObjectRefs => [...prevSkyObjectRefs, cube]);
      }
    };

    // Set camera position and add orbit controls
    const initCameraControls = () => {
      const controls = new OrbitControls(camera, renderer.domElement);
      
      controlsRef.current = controls;
      
      controls.enableDamping = true;
      controls.dampingFactor = 0.05;
      controls.screenSpacePanning = false;
      controls.minDistance = 0.1;
      controls.maxDistance = 30;
      controls.maxPolarAngle = Math.PI / 1;
      controlsRef.current = controls; // Store the controls in a ref


    };

    // Initialize the scene, lights, cubes, and camera controls
    initScene();
    initLights();
    createRandomCubes();
    initCameraControls();

    const animate = () => {
      // Auto-rotate the camera
      const autoRotationSpeed = 0.002;
      camera.position.x = camera.position.x * Math.cos(autoRotationSpeed) + camera.position.z * Math.sin(autoRotationSpeed);
      camera.position.z = camera.position.z * Math.cos(autoRotationSpeed) - camera.position.x * Math.sin(autoRotationSpeed);
      camera.lookAt(0, 0, 0);

      renderer.render(scene, camera);
      requestAnimationFrame(animate);

      if (controlsRef.current) {
        controlsRef.current.update();
      }
    };

    animate();

    // Clean up event listeners and Three.js objects on unmount
    return () => {

      controlsRef.current?.dispose();
      renderer.dispose();
      scene.remove(...scene.children);
    };
  }, []);

  return (
    <>
      <canvas
        ref={canvasRef}
        width="400"
        height="300"
        className="post-preview-canvas"
      />
    </>
  );

};

export default BuildPreviewLive;
