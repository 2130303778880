import { getFirestore, doc, getDoc, collection, query, where, getDocs } from "firebase/firestore";
import { app } from "./firebase";

interface Build {
  id: string;
  name: string;
}

export const USER_CACHE_KEY = "cachedUser";

export const loadUserData = async (userID: string) => {
  const db = getFirestore(app);
  const userDocRef = doc(db, "users", userID);
  const userDocSnapshot = await getDoc(userDocRef);
  const user = userDocSnapshot.data();

  if (user) {
    // Cache the user data
    localStorage.setItem(USER_CACHE_KEY, JSON.stringify(user));
    loadBuilds(userID);
  }
};

export const loadBuilds = async (userID: string) => {
  const db = getFirestore(app);
  const buildsQuery = query(collection(db, "builds"), where("userID", "==", userID));
  const buildsSnapshot = await getDocs(buildsQuery);

  const builds: Build[] = [];
  buildsSnapshot.forEach((doc) => {
    const build = doc.data() as Build;
    builds.push(build);
  });

  if (builds.length > 0) {
    cacheBuilds(builds);
  } else {
  }
};

// Cache User function
export const getCachedUser = () => {
  const cachedUserData = localStorage.getItem(USER_CACHE_KEY);
  return cachedUserData ? JSON.parse(cachedUserData) : null;
};

// Cache Builds function
export const cacheBuilds = (builds: Build[]) => {
  const cachedUserData = localStorage.getItem(USER_CACHE_KEY);
  if (cachedUserData) {
    const cachedUser = JSON.parse(cachedUserData);
    cachedUser.builds = builds;
    localStorage.setItem(USER_CACHE_KEY, JSON.stringify(cachedUser));
  }
};

// Cache User function
export const cacheUser = (userData: any) => {
  localStorage.setItem(USER_CACHE_KEY, JSON.stringify(userData));
};
