import { 
  IonContent,
  IonHeader,
  IonFooter,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonText,
  IonAvatar,
  IonList,
  IonItem,
  IonChip,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonPopover,
  IonButtons,
  IonButton,
  IonBackButton,
  IonFab,
  IonFabList,
  IonFabButton,
  IonIcon,
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonSegment,
  IonSegmentButton,
  IonAlert,
  IonModal,
  IonRouterOutlet,
  IonRadio,
  IonCheckbox,
  IonItemDivider,
} from '@ionic/react';
import {
  ellipsisHorizontalOutline,
  homeOutline,
  searchOutline,
  addCircleOutline,
  mailOutline,
  personOutline,
  playCircle,
  radio,
  library,
  search,
  home,
  heart,
  pin,
  star,
  call,
  globe,
  basket,
  barbell,
  trash,
  person,
  cubeOutline,
  ellipseOutline,
  prismOutline,
  triangleOutline,
  chevronUpOutline,
  chevronBackOutline,
  chevronForwardOutline,
  eyedropOutline,
  optionsOutline,
  trashOutline,
  arrowUndoOutline,
  arrowRedoOutline,
  arrowBackOutline,
  arrowForwardOutline,
  checkmark,
  checkmarkCircleOutline,
  closeOutline,
  colorPaletteOutline,
  shapesOutline,
  flaskOutline,
  add,
  handLeftOutline, 
  scanOutline,
  playBackOutline, 
  playForwardOutline,
  refreshOutline,
  refreshCircleOutline,
  pushOutline,
  arrowUpCircleOutline,
  arrowDownCircleOutline,
  arrowBack,
  arrowForward,
  arrowUp,
  arrowDown,
} from 'ionicons/icons';
import { RouteComponentProps, withRouter } from 'react-router';
import React, { useState, useEffect } from 'react';
import SVG from 'react-inlinesvg';
import logo from '../../assets/logomark_color.svg';
import itemStartImageA from '../../assets/shapeIcons/BasicCube_N_F_1x1.svg';
import itemStartImageB from '../../assets/shapeIcons/BasicCube_N_F_1x1.svg';
import itemStartImageC from '../../assets/shapeIcons/BasicCube_N_F_1x1.svg';
import itemStartImageD from '../../assets/shapeIcons/BasicCube_N_F_1x1.svg';
import itemStartImageE from '../../assets/shapeIcons/BasicCube_N_F_1x1.svg';
import EditorCanvas from '../../components/EditorCanvas';
import OptionsShapes from './OptionsShapes';
import OptionsColors from './OptionsColors';
import HeaderToolbar from '../../components/HeaderToolbar';
import './CreateScreen.css';

type Props = RouteComponentProps;

const CreateScreen: React.FC<Props> = ({ history }) => {
  
  const [items, setItems] = useState([
    { slot: 1, object: 'BasicCube_N_F_1x1', color: '#b0cfd8', image: itemStartImageA },
    { slot: 2, object: 'BasicCube_N_F_1x1', color: '#7b9aa5', image: itemStartImageB },
    { slot: 3, object: 'BasicCube_N_F_1x1', color: '#5a7482', image: itemStartImageC },
    { slot: 4, object: 'BasicCube_N_F_1x1', color: '#2399e8', image: itemStartImageD },
    { slot: 5, object: 'BasicCube_N_F_1x1', color: '#F6BD00', image: itemStartImageE },
  ]);

  const [selectedItem, setSelectedItem] = useState<string>(items[0].slot.toString());
  const [isFillColorChanged, setIsFillColorChanged] = useState<{ [key: string]: boolean }>({});
  const [isImageSrcChanged, setIsImageSrcChanged] = useState<{ [key: string]: boolean }>({});

  // Update the variable name from `selectedItem` to `currentSelectedItem`
  const currentSelectedItem = items.find(item => item.slot.toString() === selectedItem);
  const selectedItemObject = currentSelectedItem ? currentSelectedItem.object : '';
  const selectedItemColor = currentSelectedItem ? currentSelectedItem.color : '';

  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState('OptionsShapes');

  const [cameraOrientation, setCameraOrientation] = useState<string>("north");

  const [desiredRotation, setDesiredRotation] = useState({ x: 0, y: 0, z: 0 });
  const rotationDirections = [arrowUp, arrowForward, arrowDown, arrowBack];
  const [rotationIndex, setRotationIndex] = useState(0);
  const currentRotationIcon = rotationDirections[rotationIndex];
  const [showRotationButtons, setShowRotationButtons] = useState(false);
  const [rotation, setRotation] = useState({ x: 0, y: 0, z: 0 });
  
  const handleItemChange = (event: CustomEvent) => {
    setSelectedItem(event.detail.value); // Update the selected item
  };

  const handleObjectChange = (value: string, objectName: string, imageSrc: string) => {
    // Find the item that matches the value (slot number)
    const updatedItems = items.map(item => {
      if (item.slot.toString() === value) {
        // Check if image source has changed
        const isImageChanged = item.image !== imageSrc;

        // Update the image source and object for the matching item
        return { ...item, image: imageSrc, object: objectName }; // Update 'object' property with the desired value
      }
      return item; // Return the original item for other items
    });

    setItems(updatedItems); // Update the items state with the updatedItems array
    setIsImageSrcChanged({ ...isImageSrcChanged, [value]: true }); // Trigger the change animation for the specific item
    setTimeout(() => {
      setIsImageSrcChanged({ ...isImageSrcChanged, [value]: false });
    }, 500); // Reset the state variable after 0.5 seconds for the specific item
  };

  const handleObjectColorChange = (value: string, color: string) => {
    // Find the item that matches the value (slot number)
    const updatedItems = items.map(item => {
      if (item.slot.toString() === value) {
        // Update the color for the matching item
        return { ...item, color: color, isFillColorChanged: true };
      }
      return item; // Return the original item for other items
    });

    setItems(updatedItems); // Update the items state with the updatedItems array
    setIsFillColorChanged({ ...isFillColorChanged, [value]: true }); // Trigger the change animation for the specific item
    setTimeout(() => {
      setIsFillColorChanged({ ...isFillColorChanged, [value]: false });
    }, 500); // Reset the state variable after 0.5 seconds for the specific item
  };

  const handleOptionChange = (event: CustomEvent) => {
    setSelectedOption(event.detail.value);
  };

  // Set initial rotation
  useEffect(() => {
    setDesiredRotation(rotation);
  }, []);

  // Handle when the rotate left button is pressed
  const handleRotateLeft = () => {
    // Get the current rotation state
    const { x, y, z } = desiredRotation;

    // Calculate the new y value by subtracting Math.PI / 2 and wrapping within 0 to 2 * Math.PI
    const newY = (y - (Math.PI / 2) + (2 * Math.PI)) % (2 * Math.PI);

    // Update the rotation state with the normalized y value
    setDesiredRotation({ x: 0, y: newY, z: 0 });

    setRotationIndex((rotationIndex + 1) % 4);
  };

  // Handle when the rotate right button is pressed
  const handleRotateRight = () => {
    // Get the current rotation state
    const { x, y, z } = desiredRotation;

    // Calculate the new y value by adding Math.PI / 2 and wrapping within 0 to 2 * Math.PI
    const newY = (y + (Math.PI / 2) + (2 * Math.PI)) % (2 * Math.PI);

    // Update the rotation state with the normalized y value
    setDesiredRotation({ x: 0, y: newY, z: 0 });

    setRotationIndex((rotationIndex + 3) % 4);
  };

  // Effect hook to listen for changes in cameraOrientation and modify rotation
  useEffect(() => {
    let modifiedRotation = { ...rotation };
    switch (cameraOrientation) {
      case 'west':
        modifiedRotation.y = desiredRotation.y + Math.PI / 2;
        break;
      case 'east':
        modifiedRotation.y = desiredRotation.y - Math.PI / 2;
        break;
      case 'south':
        modifiedRotation.y = desiredRotation.y + Math.PI;
        break;
      case 'north':
        modifiedRotation.y = desiredRotation.y;
        break;
      default:
        break;
    }
    setRotation(modifiedRotation);
  }, [cameraOrientation, desiredRotation]);

  const handleCameraOrientationChange = (orientation: string) => {
    setCameraOrientation(orientation);
  };

  return (
    
    <IonPage className="preventSelect create-screen">
      <HeaderToolbar />
      <IonContent scroll-y="false" className="preventSelect">
        <EditorCanvas
          width={window.innerWidth}
          height={window.innerHeight}
          selectedItemObject={selectedItemObject}
          selectedItemColor={selectedItemColor}
          rotation={rotation}
          onCameraOrientationChange={handleCameraOrientationChange}
        />
        <IonModal className="overlay pokkitModal" isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
          <IonHeader className="preventSelect">
            <IonToolbar mode="ios" color="medium">
              <IonButtons slot="start">
                <IonButton onClick={() => setShowModal(false)} color="primary">
                  <IonIcon icon={checkmarkCircleOutline} />
                </IonButton>
              </IonButtons>
              <IonTitle className="preventSelect">Pokkit</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonToolbar mode="md" color="medium">
          <IonSegment className="inventoryModal preventSelect" scrollable value={selectedItem} onIonChange={handleItemChange}>
            {items.map((item, index) => (
              <IonSegmentButton key={item.slot} value={item.slot.toString()}>
                <SVG src={item.image} style={{ fill: item.color }} className={(isFillColorChanged[item.slot.toString()] || isImageSrcChanged[item.slot.toString()]) ? 'svgAnimation' : ''} />
              </IonSegmentButton>
            ))}
          </IonSegment>
          </IonToolbar>
          <IonContent className="preventSelect objectOptions">
            <IonToolbar mode="md">
              <IonSegment value={selectedOption} onIonChange={handleOptionChange}>
                <IonSegmentButton value="OptionsShapes" layout="icon-end">
                  {/*<<IonLabel>Shape</IonLabel>*/}
                  <IonIcon icon={shapesOutline}></IonIcon>
                </IonSegmentButton>
                <IonSegmentButton value="OptionsColors" layout="icon-end">
                  {/*<IonLabel>Finish</IonLabel>*/}
                  <IonIcon icon={flaskOutline}></IonIcon>
                </IonSegmentButton>
              </IonSegment>
            </IonToolbar>
            {selectedOption === 'OptionsShapes' && <OptionsShapes handleObjectChange={handleObjectChange} selectedItem={selectedItem} itemColor={items.find(item => item.slot.toString() === selectedItem)?.color ?? '#FF0000'} />}
            {selectedOption === 'OptionsColors' && <OptionsColors handleObjectColorChange={handleObjectColorChange} selectedItem={selectedItem}/>}
          </IonContent>
        </IonModal>
      </IonContent>

      <div className={`overlay rotation-controls`}>
        <div className="rotation-indicator">
          <IonIcon icon={currentRotationIcon} />
        </div>
        {showRotationButtons && (
          <div className="button-list">
            {/* Upper buttons */}
          </div>
        )}
        <IonButton className={`main-button ${showRotationButtons ? 'active' : ''}`} onClick={() => setShowRotationButtons(!showRotationButtons)}>
          <IonIcon icon={showRotationButtons ? add : cubeOutline}></IonIcon>
        </IonButton>
        {showRotationButtons && (
          <div className="side-buttons">
            <IonButton className="circle-button" onClick={handleRotateRight} size="small">
              <IonIcon slot="icon-only" icon={refreshOutline} style={{ transform: 'scaleX(-1)', marginTop: '-1px', transformOrigin: 'center' }} />
            </IonButton>
            <IonButton className="circle-button" onClick={handleRotateLeft} size="small">
              <IonIcon slot="icon-only" icon={refreshOutline} style={{ transformOrigin: 'center' }} />
            </IonButton>
          </div>
        )}
      </div>

      <IonFooter className="overlay preventSelect">
        <IonToolbar mode="md">
          <IonButton id="open-custom-dialog" expand="block" fill="clear" size="small" onClick={() => setShowModal(true)}>
            <IonIcon slot="icon-only" icon={chevronUpOutline} />
          </IonButton>
          <IonSegment className="inventoryFooter" scrollable value={selectedItem} onIonChange={handleItemChange}>
            {items.map((item, index) => (
              <IonSegmentButton key={item.slot} value={item.slot.toString()}>
                <SVG src={item.image} style={{ fill: item.color }} />
              </IonSegmentButton>
            ))}
          </IonSegment>
        </IonToolbar>
      </IonFooter>
  </IonPage>
      
  );
};

export default withRouter(CreateScreen);
