// React Components
import React, { useState, useRef, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { useParams, Link, useLocation } from 'react-router-dom';

import {
  IonPage,
  IonContent,
  IonText,
  IonItem,
  IonCard,
  IonIcon,
  IonSpinner,
} from '@ionic/react';
import {
  ellipse,
  checkmarkCircleOutline,
} from 'ionicons/icons';

// Firebase Services
import firebase from 'firebase/compat/app';
import { auth } from '../../services/firebaseAuth';
import {
  getFirestore,
  collection,
  query,
  orderBy,
  limit,
  onSnapshot,
  where,
  doc,
  updateDoc,
  writeBatch,
} from 'firebase/firestore';

// Custom Utilities & Services
import { getCachedUser, cacheUser } from '../../services/userCache';
import UserAvatar from '../../components/UserAvatar';
import {
  getFormattedCreatedAt,
  getContrastingTextColor,
  isValidUrl,
  getWebsiteDomain,
  isSingleEmoji,
  getBaseDomain,
  convertUsernameMentionsToLinks,
  shortenNumber,
} from '../../utils/UtilityFunctions';
import SharePopover from '../../components/SharePopover';

// Custom Components and Style Sheets
import HeaderToolbar from '../../components/HeaderToolbar';
import SideMenu from '../../components/SideMenu';
import './NotificationsScreen.css';

type Props = RouteComponentProps;

interface Notification {
  id: string;
  followerID: string;
  unread: boolean;
}

interface User {
  id: string;
  displayName: string;
  username: string;
}

const NotificationsScreen: React.FC<Props> = ({ history }) => {
  
  const db = getFirestore();
  const currentUserID = auth.currentUser?.uid;

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isNotificationsLoaded, setIsNotificationsLoaded] = useState<boolean>(false);

  useEffect(() => {
    // Fetch notifications
    const notificationsRef = collection(db, 'notifications');
    const queryNotifications = query(
      notificationsRef,
      orderBy('timestamp', 'desc'),
      limit(20),
      where('userID', '==', currentUserID)
    );

    const unsubscribeNotifications = onSnapshot(queryNotifications, (snapshot) => {
      const fetchedNotifications: Notification[] = [];
      snapshot.forEach((doc) => {
        const notification: Notification = {
          id: doc.id,
          followerID: doc.data().followerID,
          unread: doc.data().unread,
        };
        fetchedNotifications.push(notification);
      });
      setNotifications(fetchedNotifications);
      setIsNotificationsLoaded(true);
    });

    // Fetch users
    const usersRef = collection(db, 'users');
    const queryUsers = query(usersRef);

    const unsubscribeUsers = onSnapshot(queryUsers, (snapshot) => {
      const fetchedUsers: User[] = [];
      snapshot.forEach((doc) => {
        const user: User = {
          id: doc.id,
          displayName: doc.data().displayName,
          username: doc.data().username,
        };
        fetchedUsers.push(user);
      });
      setUsers(fetchedUsers);
    });

    const userRef = doc(collection(db, 'users'), currentUserID);
    updateDoc(userRef, {
      notifications: 0,
    });

    return () => {
      unsubscribeNotifications();
      unsubscribeUsers();
    };

  }, [db]);

  useEffect(() => {
    setIsLoading(!isNotificationsLoaded);
  }, [isNotificationsLoaded]);

  const handleNotificationClick = async (notification: Notification) => {
    try {
      // Update the 'unread' status of the clicked notification in Firestore
      const notificationRef = doc(db, 'notifications', notification.id);
      await updateDoc(notificationRef, { unread: false });

      // Update the 'unread' status locally in the state variable
      const updatedNotifications = notifications.map((n) => {
        if (n.id === notification.id) {
          return {
            ...n,
            unread: false,
          };
        }
        return n;
      });
      setNotifications(updatedNotifications);
    } catch (error) {
      //console.error('Error updating notification:', error);
    }
  };

  return (
    <>
      <SideMenu />
      <IonPage id="main-content">
        <HeaderToolbar />
        <IonContent>
          <div className="limitedWidth notifications-screen">
            {isLoading ? (
              <div className="loadingSpinner ion-text-center">
                <IonSpinner name="circular" color="primary" />
              </div>
            ) : (
              <>
                {notifications.length === 0 ? (
                  <div className="no-notifications">
                    <img src="assets/images/empty-notifications.svg" alt="illustration of a megaphone with barely any noise coming out" height="200px" width="400px" />
                    <p><b>You have no notifications</b><br /><span>Interact with others to get notifications</span></p>
                  </div>
                ) : (
                  <>
                    <IonText>
                      <h2>Most recent notifications</h2>
                    </IonText>
                    {notifications.map((notification) => {
                      const user = users.find((user) => user.id === notification.followerID);
                      if (user) {
                        return (
                          <IonItem
                            key={notification.id}
                            button
                            routerLink={`/${user.username}`}
                            onClick={() => handleNotificationClick(notification)}
                            detail={true}
                            lines="none"
                          >
                            <IonIcon
                              slot="start"
                              icon={notification.unread ? ellipse : checkmarkCircleOutline}
                              color={notification.unread ? 'primary' : 'medium'}
                            />
                            {user.displayName} (@{user.username}) is now following you
                          </IonItem>
                        );
                      }
                      return null;
                    })}
                  </>
                )}
              </>
            )}
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

export default withRouter(NotificationsScreen);
