import {
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
} from "@ionic/react";
import React, { useState } from "react";

const colorSets = [
  {
    title: "Primari Blokki",
    finish: "matte",
    colors: [
      { id: "color1", value: "#e8463a" },
      { id: "color2", value: "#ff8724" },
      { id: "color3", value: "#F6BD00" },
      { id: "color4", value: "#36b04a" },
      { id: "color5", value: "#2399e8" },
      { id: "color6", value: "#775ab7" },
      { id: "color7", value: "#e5eef2" },
      { id: "color8", value: "#222830" },
    ],
  },
  {
    title: "Milki Blokki",
    finish: "matte",
    colors: [
      { id: "color9", value: "#d3a6dd" },
      { id: "color10", value: "#ed938e" },
      { id: "color11", value: "#eaa57f" },
      { id: "color12", value: "#f4e692" },
      { id: "color13", value: "#aaf2af" },
      { id: "color14", value: "#90e0e0" },
      { id: "color15", value: "#a4c9ea" },
      { id: "color16", value: "#aaa5d3" },
    ],
  },
  {
    title: "Naturril Blokki",
    finish: "matte",
    colors: [
      { id: "color17", value: "#633430" },
      { id: "color18", value: "#a8463f" },
      { id: "color19", value: "#d67e3a" },
      { id: "color20", value: "#e0af41" },
      { id: "color21", value: "#6e935f" },
      { id: "color22", value: "#5d7f93" },
      { id: "color23", value: "#7e698c" },
      { id: "color24", value: "#847266" },
    ],
  },
  {
    title: "Neonn Blokki",
    finish: "matte",
    colors: [
      { id: "color25", value: "#ff6622" },
      { id: "color26", value: "#fc1546" },
      { id: "color27", value: "#f746b3" },
      { id: "color28", value: "#be43ff" },
      { id: "color29", value: "#2444ff" },
      { id: "color30", value: "#31ffff" },
      { id: "color31", value: "#88ff57" },
      { id: "color32", value: "#f6ff3e" },
    ],
  },/*
  {
    title: "Metallik Blokki",
    finish: "glossy",
    colors: [
      { id: "color33", value: "#C18585" },
      { id: "color34", value: "#F7AE9D" },
      { id: "color35", value: "#FFDF5C" },
      { id: "color36", value: "#D3BA6A" },
      { id: "color37", value: "#BB870D" },
      { id: "color38", value: "#834700" },
      { id: "color39", value: "#C7D3D3" },
      { id: "color40", value: "#809091" },
    ],
  },*/
  {
    title: "Glas Blokki",
    finish: "glass",
    colors: [
      { id: "color41", value: "#FFC0E7" },
      { id: "color42", value: "#FFA9A9" },
      { id: "color43", value: "#FFC49B" },
      { id: "color44", value: "#F9F27A" },
      { id: "color45", value: "#A4F4CC" },
      { id: "color46", value: "#98DEFC" },
      { id: "color47", value: "#A5ACCC" },
      { id: "color48", value: "#D1E8F7" },
    ],
  },
  {
    title: "Proto Blokki",
    finish: "matte",
    colors: [
      { id: "color49", value: "#f0f7f7" },
      { id: "color50", value: "#d3e8ed" },
      { id: "color51", value: "#b0cfd8" },
      { id: "color52", value: "#9bbcc4" },
      { id: "color53", value: "#7b9aa5" },
      { id: "color54", value: "#5a7482" },
      { id: "color55", value: "#3a4e5d" },
      { id: "color56", value: "#1b2830" },
    ],
  },
];

const colorBoxStyle = {
  borderRadius: "5%",
  paddingBottom: "100%",
};

const OptionsColors = ({ handleObjectColorChange, selectedItem }: { handleObjectColorChange: (value: string, color: string) => void; selectedItem: string }) => {
const handleClick = (value: string, color: string) => {
  handleObjectColorChange(value, color); // Call the handleObjectColorChange function with the correct arguments
};
  return (
    <IonGrid>
      {colorSets.map((set, i) => (
        <IonCard key={i}>
          <IonCardHeader>
            <IonCardTitle className="catTitle">{set.title}</IonCardTitle>
          </IonCardHeader>
          <IonRow>
            {set.colors.map((color) => (
              <IonCol size="12/8" size-md="12/8" key={color.id}>
                <div
                  className={set.finish}
                  style={{ backgroundColor: color.value, ...colorBoxStyle }}
                  onClick={() => handleClick(selectedItem, color.value)}
                  id={color.id}
                />
              </IonCol>
            ))}
          </IonRow>
        </IonCard>
      ))}
    </IonGrid>
  );
};

export default OptionsColors;
