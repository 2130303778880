import React, { useEffect, useMemo, useState } from "react";
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  IonApp,
  IonSpinner,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from '@ionic/react-router';
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
import { onAuthStateChange } from './services/firebaseAuth';
import { User } from 'firebase/auth';

import TabNavigation from './components/TabNavigation';
import LoginScreen from './screens/LoginScreen/LoginScreen';
import DiscoverScreen from './screens/DiscoverScreen/DiscoverScreen';
import ViewScreen from './screens/ViewScreen/ViewScreen';
import ProfileScreen from './screens/ProfileScreen/ProfileScreen';
import PostScreen from './screens/PostScreen/PostScreen';

import Sponsorship from './pages/Sponsorship';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';

import '@ionic/react/css/ionic.bundle.css';
import './theme/variables.css';
import './global.css';

setupIonicReact();

const App: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [username, setUsername] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Listen for changes in the user's authentication state
    const unsubscribe = onAuthStateChange((user: User) => {
      setUser(user);
      setLoading(false); // Set loading to false when user state is set
    });

    return () => {
      unsubscribe(); // Unsubscribe from the authentication state listener when the component unmounts
    };
  }, []);

  useEffect(() => {
    if (user) {
      const uid = user.uid;
      const userDocRef = doc(getFirestore(), "users", uid);
      // Listen for changes in the user document
      const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          setUsername(docSnapshot.data()?.username ?? null);
        }
      });

      return () => {
        unsubscribe(); // Cleanup the onSnapshot listener when the component unmounts
      };
    }
  }, [user]);

  const isLoggedIn = useMemo(() => user !== null, [user]);

  if (loading) {
    // Display a loading spinner while the app is loading
    return (
      <div className="loadingSpinner ion-text-center app-spinner">
        <IonSpinner name="circular" color="primary" />
      </div>
    );
  }

  return (
    <IonApp>
      <IonReactRouter>
        {isLoggedIn ? (
          // If the user is logged in, render the TabNavigation component
          <TabNavigation user={user} username={username} />
        ) : (
          // If the user is not logged in, render different routes based on the path
          <Switch>
            <Route exact path="/" component={LoginScreen} />
            <Redirect from="/home" to="/" />
            <Redirect from="/workshop" to="/" />
            <Redirect from="/notifications" to="/" />
            <Redirect from="/create" to="/" />
            <Redirect from="/settings" to="/" />
            {/*<Route path="/support-blokkiti" component={Sponsorship} exact={true} />*/}
            <Route path="/privacy" component={PrivacyPolicy} exact={true} />
            <Route path="/terms" component={TermsOfService} exact={true} />
            <Route path="/discover" component={DiscoverScreen} exact={true} />
            <Route path="/view/:username/:projectid" component={ViewScreen} exact={true} />
            <Route path="/:username" component={ProfileScreen} exact={true} />
            <Route path="/:username/post/:postid" component={PostScreen} exact={true} />
            <Redirect to="/" />
          </Switch>
        )}
      </IonReactRouter>
    </IonApp>
  );
};

export default App;