import React, { useEffect, useRef, useState, useCallback } from "react";
import { debounce } from "lodash";
import { v4 as uuidv4 } from 'uuid';
import { IonSpinner, IonToast, IonButton, IonIcon } from '@ionic/react';
import { banOutline, add, chevronUp, chevronDown, chevronForward, chevronBack, moveOutline, expandOutline, planetOutline, eyeOutline } from 'ionicons/icons';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { getFirestore, doc, updateDoc, getDoc, serverTimestamp, DocumentReference, DocumentData, setDoc } from 'firebase/firestore';
import { auth, onAuthStateChange } from '../services/firebaseAuth';
import { User } from 'firebase/auth';
import * as THREE from "three";
import { BoxGeometry, MeshBasicMaterial, Mesh, Raycaster, GridHelper, Object3D, EdgesGeometry, LineBasicMaterial, LineSegments, Vector3, InstancedMesh } from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { OutlinePass } from "three/examples/jsm/postprocessing/OutlinePass";

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

type KeyState = {
  [key: string]: boolean;
};

const directionMap: { [key: string]: THREE.Vector3 } = {
  w: new THREE.Vector3(0, 0, -1), // Forward
  s: new THREE.Vector3(0, 0, 1),  // Backward
  a: new THREE.Vector3(-1, 0, 0), // Left
  d: new THREE.Vector3(1, 0, 0),  // Right
  q: new THREE.Vector3(0, -1, 0), // Down
  e: new THREE.Vector3(0, 1, 0),  // Up
};

interface Object {
  id: string,
  shape: string,
  color: number,
  position: [number, number, number],
  scale: [number, number, number],
  rotation: [number, number, number, EulerOrder]
}
type EulerOrder = "XYZ" | "YZX" | "ZXY" | "XZY" | "YXZ" | "ZYX";

interface EditorCanvasProps {
  width: number;
  height: number;
  selectedItemObject: string;
  selectedItemColor: string;
  rotation: { x: number; y: number; z: number };
  onCameraOrientationChange: (orientation: string) => void;
}

const EditorCanvas: React.FC<EditorCanvasProps> = ({
  width,
  height,
  selectedItemObject,
  selectedItemColor,
  rotation,
  onCameraOrientationChange
}) => {

  // Three.js scene and camera references
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const sceneRef = useRef<THREE.Scene | undefined>();
  const cameraRef = useRef<THREE.PerspectiveCamera | undefined>();
  const controlsRef = useRef<OrbitControls | null>(null);

  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);
  
  const initialTargetPositionRef = useRef<THREE.Vector3>(new THREE.Vector3()); // Store the initial target position
  const [perspectiveState, setPerspectiveState] = useState<'orbit' | 'firstPerson'>('orbit');
  const [cameraOrientation, setCameraOrientation] = useState("north");

  const composerRef = useRef<EffectComposer>();
  const outlinePassRef = useRef<OutlinePass>();

  // Other Three.js references
  let floorMesh: THREE.Mesh | null = null;
  const gridRef = useRef<GridHelper>();
  const raycasterRef = useRef<THREE.Raycaster | null>(null);
  const mouseRef = useRef(new THREE.Vector2());
  const rollOverRef = useRef<THREE.Mesh | null>(null);
  const [skyObjectRefs, setSkyObjectRefs] = useState<THREE.Mesh[]>([]);
  const [objectRefs, setObjectRefs] = useState<THREE.Mesh[]>([]);
  const objectsRef = useRef<THREE.Mesh[]>([]);
  const newObjectId = useRef(0);
  const selectedItemColorRef = useRef(selectedItemColor);
  const selectedItemObjectRef = useRef(selectedItemObject);
  let prevTargetObject: THREE.Object3D | null = null;
  const [particles, setParticles] = useState<THREE.Mesh[]>([]);
  
  const [isLoading, setIsLoading] = useState(true);
  const previousObjectCount = useRef(0);
  const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(null);

  const [isLiveMode, setIsLiveMode] = useState(false);
  const [mode, setMode] = useState('offline');

  const [mainButtonActive, setMainButtonActive] = useState(false);
  const [showButtons, setShowButtons] = useState(false);

  const [showToast, setShowToast] = useState(false);

  // Other references for app functionality
  const [isOverOverlay, setIsOverOverlay] = useState(false);

  let touchStartLocation: { x: number; y: number } | null = null;
  const longPressTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [isLongPress, setIsLongPress] = useState(false);

  let pressTimer: NodeJS.Timeout | undefined;
  let longPressOccurred: boolean = false;
  let initialMousePosition: { x: number; y: number } | null = null;
  const cursorPositionRef = useRef<{ x: number, y: number }>({ x: 0, y: 0 });
  let isCursorOverObject = false
  let selectedObject: THREE.Object3D<THREE.Event> | null = null;
  let isOverlapping = false;

  // State for current project ID
  const [currentProjectId, setCurrentProjectId] = useState<string | null>(null);
  const [projectExists, setProjectExists] = useState<boolean>(false);
  const history = useHistory();
  const match = useRouteMatch<{ projectId: string }>('/create/:projectId');
  const db = getFirestore();
  
  useEffect(() => {
    if (match?.params?.projectId) {
      setCurrentProjectId(match.params.projectId);
    }
  }, [match]);

  // Helper function to check if a file exists at a given URL
  async function checkFileExists(url: string) {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      return response.ok;
    } catch (error) {
      console.error(`Error checking if file exists at ${url}:`, error);
      return false;
    }
  }

  // Generate a random UUID (Universally Unique Identifier)
  const generateUniqueId = () => {
    return uuidv4();
  };

  // Get current item and color and attach it to some references
  useEffect(() => {
    selectedItemColorRef.current = selectedItemColor;
    selectedItemObjectRef.current = selectedItemObject;
  }, [selectedItemColor, selectedItemObject]);

  // Add objects from DB to the scene
  useEffect(() => {
    if (!currentProjectId) return;
    const projectDocRef = doc(db, 'builds', currentProjectId);
    getDoc(projectDocRef).then((docSnapshot) => {
      if (docSnapshot.exists()) {
        setProjectExists(true);

        const isLive = docSnapshot.data()?.live;
        setIsLiveMode(isLive);

        if (isLive) {
          setMode("live");
        }

        setIsLoading(true);

        const objectsData: Object[] = docSnapshot.data()?.objects;
        previousObjectCount.current = objectsData.length;  

        const loadObjects = async () => {
          const loadedObjectRefs: THREE.InstancedMesh[] = [];

          // Add floorMesh to objectRefs
          //setObjectRefs([floorMesh]);

          await Promise.all(
            objectsData.map(async (object: Object) => {
              const shapeFileName = `${object.shape}.gltf`;
              const objectFileUrl = `/assets/objects/${shapeFileName}`;

              // Check if the file exists before loading it
              const fileExists = await checkFileExists(objectFileUrl);
              if (!fileExists) {
                console.error(`File not found at ${objectFileUrl}`);
                return;
              }

              return new Promise<void>((resolve) => {
                new GLTFLoader().load(objectFileUrl, (gltf) => {
                  const mesh = gltf.scene.children[0] as THREE.InstancedMesh;
                  const euler = new THREE.Euler(
                    object.rotation[0], // X angle
                    object.rotation[1], // Y angle
                    object.rotation[2], // Z angle
                    object.rotation[3]  // Euler order
                  );
                  const quaternion = new THREE.Quaternion().setFromEuler(euler);
                  mesh.setRotationFromQuaternion(quaternion);
                  mesh.position.set(
                    object.position[0] as number,
                    object.position[1] as number,
                    object.position[2] as number
                  );
                  mesh.scale.set(
                    object.scale[0] as number,
                    object.scale[1] as number,
                    object.scale[2] as number
                  );

                  // Assuming a single material for the mesh
                  const material = mesh.material as THREE.MeshStandardMaterial;
                  const color = new THREE.Color(object.color);
                  material.color = color;

                  mesh.castShadow = true;
                  mesh.receiveShadow = true;
                  mesh.userData.isSelectable = true;
                  mesh.userData.color = object.color; // Add color value to userData
                  mesh.userData.shape = object.shape; // Add shape value to userData
                  mesh.userData.id = object.id;

                  mesh.geometry.computeBoundingBox();

                  sceneRef.current?.add(mesh);

                  loadedObjectRefs.push(mesh); // Accumulate the loaded object

                  resolve();
                });
              });
            })
          );

          setObjectRefs((prevObjectRefs) => [...prevObjectRefs, ...loadedObjectRefs]); // Update the objectRefs state with the loaded objects
          setIsLoading(false);
        };

        loadObjects();
      } else {
        //console.log('No objects found in database.'); // Debugging statement
        setProjectExists(false);
        history.push('/error');
      }
    });
  }, [currentProjectId]);

  // Initialize scene, camera, and controls on mount
  useEffect(() => {

    // Clear the objects array
    setSkyObjectRefs([]);
    setObjectRefs([]);

    // Create a scene
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xd8ebf2);
    scene.fog = new THREE.Fog(0xd8ebf2, 8, 40);
    sceneRef.current = scene;

    // Create a camera
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.updateProjectionMatrix();
    camera.position.set(0, 3, 14);

    cameraRef.current = camera;

    // Store the initial target position
    initialTargetPositionRef.current.copy(cameraRef.current!.position);

    // Create a renderer
    const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current!, antialias: true, preserveDrawingBuffer: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(width, height);
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    const composer = new EffectComposer(renderer);
    composerRef.current = composer;

    const renderPass = new RenderPass(sceneRef.current!, cameraRef.current!);
    composer.addPass(renderPass);

    const outlinePass = new OutlinePass(new THREE.Vector2(window.innerWidth, window.innerHeight), sceneRef.current!, cameraRef.current!);
    outlinePass.edgeStrength = 2;
    outlinePass.edgeGlow = 0;
    outlinePass.visibleEdgeColor.set('#ffffff');
    outlinePass.hiddenEdgeColor.set('#000000');
    outlinePass.overlayMaterial.blending = THREE.SubtractiveBlending
    outlinePassRef.current = outlinePass;
    composer.addPass(outlinePass);

    // Controls
    if (camera) {
      // Add camera controls
      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;
      controls.dampingFactor = 0.05;
      controls.screenSpacePanning = false;
      controls.rotateSpeed = 1;
      controls.minDistance = 1;
      controls.maxDistance = 30;
      controls.maxPolarAngle = Math.PI / 1;
      controlsRef.current = controls; // Store the controls in a ref

    }

    // Create a rectangle
    const rectGeometry = new THREE.BoxGeometry(10, 0.0625, 10);
    const rectMaterial = new THREE.MeshStandardMaterial({color: 0xb4ccd6, visible: false});
    const rect = new THREE.Mesh(rectGeometry, rectMaterial);
    rect.material.transparent = true;
    rect.material.opacity = .25;
    rect.receiveShadow = true;
    rect.name = "floor";
    rect.userData.isSelectable = false;
    rect.position.y = -0.15625;
    scene.add(rect);
    setObjectRefs(prevObjectRefs => [...prevObjectRefs, rect]);
    floorMesh = rect; // Set the floor mesh to the local variable

    // Lighting // Directional Light 1
    const dirLight = new THREE.DirectionalLight(0xfffaf0, 0.125); // Adjust color and intensity
    dirLight.position.set(6, 60, 6); // Adjust x, y, and z positions
    dirLight.target.position.set(0, 0, 0);
    dirLight.castShadow = true;
    dirLight.shadow.mapSize.width = 2048;
    dirLight.shadow.mapSize.height = 2048;
    dirLight.shadow.bias = -0.001; // Adjust as needed
    dirLight.shadow.normalBias = .02;
    dirLight.shadow.radius = 1;
    dirLight.shadow.camera.near = .1;
    dirLight.shadow.camera.far = 500;
    dirLight.shadow.camera.left = -25;
    dirLight.shadow.camera.right = 25;
    dirLight.shadow.camera.top = 25;
    dirLight.shadow.camera.bottom = -25;

    scene.add(dirLight);




/*

const dirLight = new THREE.DirectionalLight(0xffffff, getRandomFloat(0.05, 0.2));
dirLight.position.set(getRandomFloat(-5, 5), getRandomFloat(20, 40), getRandomFloat(-5, 5));
dirLight.target.position.set(getRandomFloat(-2, 2), 0, getRandomFloat(-2, 2));
dirLight.castShadow = true;
dirLight.shadow.mapSize.width = getRandomInt(1024, 4096);
dirLight.shadow.mapSize.height = getRandomInt(1024, 4096);
dirLight.shadow.bias = getRandomFloat(-0.002, 0.002);
dirLight.shadow.normalBias = getRandomFloat(0.01, 0.03);
dirLight.shadow.radius = getRandomFloat(0, 2);
dirLight.shadow.camera.near = getRandomFloat(0.01, 0.2);
dirLight.shadow.camera.far = getRandomFloat(30, 70);
dirLight.shadow.camera.left = getRandomFloat(-10, -5);
dirLight.shadow.camera.right = getRandomFloat(5, 10);
dirLight.shadow.camera.top = getRandomFloat(5, 20);
dirLight.shadow.camera.bottom = getRandomFloat(-20, -5);

console.log("Directional Light:");
console.log("Color: #ffffff");
console.log("Intensity:", dirLight.intensity);
console.log("Position:", dirLight.position);
console.log("Target Position:", dirLight.target.position);
console.log("Cast Shadow:", dirLight.castShadow);
console.log("Shadow Map Size (Width x Height):", dirLight.shadow.mapSize.width, "x", dirLight.shadow.mapSize.height);
console.log("Shadow Bias:", dirLight.shadow.bias);
console.log("Shadow Normal Bias:", dirLight.shadow.normalBias);
console.log("Shadow Radius:", dirLight.shadow.radius);
console.log("Shadow Camera Near:", dirLight.shadow.camera.near);
console.log("Shadow Camera Far:", dirLight.shadow.camera.far);
console.log("Shadow Camera Left:", dirLight.shadow.camera.left);
console.log("Shadow Camera Right:", dirLight.shadow.camera.right);
console.log("Shadow Camera Top:", dirLight.shadow.camera.top);
console.log("Shadow Camera Bottom:", dirLight.shadow.camera.bottom);

function getRandomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function getRandomFloat(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

*/




    const ambientLight = new THREE.AmbientLight(0xf7f0e3, .85); // color, intensity
    scene.add(ambientLight);

    // Lighting // Hemisphere Light 3
    const hemiLight3 = new THREE.HemisphereLight(0xf7f0e3, 0x6a6d75, .2); // Adjust color and intensity
    hemiLight3.position.set(15, 5, -3);

    hemiLight3.castShadow = false;
    scene.add(hemiLight3);

    // Add resize listener
    const handleResize = () => {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;
      camera.aspect = newWidth / newHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(newWidth, newHeight);
    };

    window.addEventListener('resize', handleResize);
    
    // Sky particles
    const centralBox = new THREE.Box3(new THREE.Vector3(-3, -3, -3), new THREE.Vector3(3, 3, 3)); // Define the central part of the scene using a Box3 object
    const floorBox = new THREE.Box3().setFromObject(floorMesh); // Define the bounding box of the floor

    // Define the forbidden area box
    /*
    const forbiddenBoxMaterial = new THREE.MeshStandardMaterial({
      color: 0xff0000, // Red color (you can change it to any color you want)
      opacity: 0.5,    // Set the opacity to 50% (0.0 to 1.0)
      transparent: true, // Enable transparency
    });
    */
    // Define the forbidden area box
    const forbiddenBox = new THREE.Box3(
      new THREE.Vector3(-5, -0.0625, -5),
      new THREE.Vector3(5, 10.0625, 5)
    );
    /*
    // Create the forbidden box mesh and assign the material to it
    const forbiddenBoxGeometry = new THREE.BoxGeometry(10, 10.125, 10);
    const forbiddenBoxMesh = new THREE.Mesh(forbiddenBoxGeometry, forbiddenBoxMaterial);
    forbiddenBoxMesh.position.y = 4.9375; // Adjust the position so it's centered vertically
    scene.add(forbiddenBoxMesh);
    */

    // Generate random cubes outside the central part of the scene and the forbidden area
    const numInstances = 30;

    for (let i = 0; i < numInstances; i++) {
      const geometry = new THREE.BoxGeometry(0.25, 0.25, 0.25);
      const material = new THREE.MeshStandardMaterial({ color: 0xe5eef2 });
      const cube = new THREE.InstancedMesh(geometry, material, numInstances);
      cube.receiveShadow = false;
      cube.castShadow = false;
      cube.userData.isSelectable = false;

      // Randomly position the cube outside the central part of the scene and the forbidden area
      let isInsideSceneBox = true;
      while (isInsideSceneBox) {
        cube.position.set(
          (Math.random() > 0.5 ? 1 : -1) * (Math.random() * 14 + 0), // x position to the left or right of the center
          Math.random() * 40 - 20, // y position between -20 and 20 (adjust this range as needed)
          (Math.random() > 0.5 ? 1 : -1) * (Math.random() * 14 + 0) // z position to the front or back of the center
        );

        // Update cubeBox with the new cube position
        const cubeBox = new THREE.Box3().setFromObject(cube);

        // Check if the updated cubeBox intersects with any of the forbidden areas
        isInsideSceneBox = centralBox.intersectsBox(cubeBox) || floorBox.intersectsBox(cubeBox) || forbiddenBox.intersectsBox(cubeBox);
      }

      scene.add(cube);
      setSkyObjectRefs(prevSkyObjectRefs => [...prevSkyObjectRefs, cube]);
    }

    const updateCameraOrientation = () => {
      const quaternion = cameraRef.current?.quaternion;
      if (!quaternion) {
        return; // Exit early if quaternion is undefined
      }

      const forwardVector = new THREE.Vector3(0, 0, -1);
      const cameraDirection = forwardVector.applyQuaternion(quaternion);
      const angle = Math.atan2(cameraDirection.x, cameraDirection.z);

      let orientation = "south";

      if (angle >= -Math.PI / 4 && angle < Math.PI / 4) {
        orientation = "south";
      } else if (angle >= Math.PI / 4 && angle < (3 * Math.PI) / 4) {
        orientation = "east";
      } else if (angle >= (3 * Math.PI) / 4 || angle < - (3 * Math.PI) / 4) {
        orientation = "north";
      } else {
        orientation = "west";
      }

      setCameraOrientation(orientation);
    };

    const animate = () => {
      requestAnimationFrame(animate);

      composerRef.current?.render();

      // Update controls
      if (controlsRef.current) {
        controlsRef.current.update();
      }
      
      // Update camera orientation
      updateCameraOrientation();

      //renderer.render(scene, camera);
    };
    animate();

    return () => {
      window.removeEventListener('resize', handleResize);
      scene.remove(...scene.children);
      renderer.dispose();
      cameraRef.current?.updateProjectionMatrix();
    };
  }, []);

  // Particle burst animation
  function createParticleBurst(position: THREE.Vector3, scene: THREE.Scene, particles: THREE.Mesh[], cameraRef: React.MutableRefObject<THREE.PerspectiveCamera | undefined>) {
    const particleGeometry = new THREE.PlaneGeometry(0.1, 0.1);
    const particleMaterial = new THREE.MeshBasicMaterial({ color: 0xe6eff2, side: THREE.DoubleSide });
    const particle = new THREE.Mesh(particleGeometry, particleMaterial);
    particle.position.copy(position);

    const particleCount = 5;
    const particleSpread = 0.25;
    const particleVelocity = new THREE.Vector3(0, 1, 0).normalize().multiplyScalar(0.5); // fixed velocity
    const particleScale = 1; // fixed scale

    for (let i = 0; i < particleCount; i++) {
      const newParticle = particle.clone();
      newParticle.position.x += Math.random() * particleSpread - particleSpread / 2;
      newParticle.position.y += Math.random() * particleSpread - particleSpread / 2;
      newParticle.position.z += Math.random() * particleSpread - particleSpread / 2;
      newParticle.scale.setScalar(particleScale);
      newParticle.userData.velocity = particleVelocity.clone();
      scene.add(newParticle);
      particles.push(newParticle);
    }

    const duration = 400;
    const start = performance.now();

    const animateParticles = (time: number) => {
      const elapsed = time - start;

      if (elapsed < duration) {
        for (let i = 0; i < particles.length; i++) {
          const particle = particles[i];
          const progress = elapsed / duration;
          const scale = 1 - progress;
          particle.scale.setScalar(scale);

          // face the camera
          if (cameraRef.current) {
            particle.lookAt(cameraRef.current.position);
            particle.rotateY(Math.PI);
          }

          particle.position.add(particle.userData.velocity.clone().multiplyScalar(0.05));
        }
        requestAnimationFrame(animateParticles);
      } else {
        for (let i = 0; i < particles.length; i++) {
          const particle = particles[i];
          scene.remove(particle);
        }
      }
    };

    requestAnimationFrame(animateParticles);
  }

  // Zoom camera effect - a quick zoom in / out motion
  const clock = new THREE.Clock();
  function zoomInOut(camera: THREE.PerspectiveCamera, zoomAmount: number, duration: number) {
    const originalPosition = camera.position.clone();
    const zoomedOutPosition = camera.position.clone().multiplyScalar(zoomAmount / 0.052);

    const clock = new THREE.Clock();
    let timeElapsed = 0;

    function animate() {
      const deltaTime = clock.getDelta();
      timeElapsed += deltaTime;

      if (timeElapsed < duration) {
        const t = timeElapsed / duration;
        camera.position.lerpVectors(originalPosition, zoomedOutPosition, t);
      } else if (timeElapsed < 2 * duration) {
        const t = (timeElapsed - duration) / duration;
        camera.position.lerpVectors(zoomedOutPosition, originalPosition, t);
      } else {
        camera.position.copy(originalPosition);
        return;
      }

      requestAnimationFrame(animate);
    }

    animate();
  }

  // Remove previous rollover object from scene
  const removePreviousRollover = async () => {
    return new Promise<void>((resolve) => {
      if (rollOverRef.current) {
        const scene = sceneRef.current;
        scene?.remove(rollOverRef.current);
        rollOverRef.current = null;
      }
      resolve();
    });
  };

  useEffect(() => {
    const scene = sceneRef.current;
    const rollOver = rollOverRef.current;

    // Remove previous rollOverHelper from scene
    if (rollOver) {
      scene?.remove(rollOver);
    }

    // Check if rollover object is loaded and available
    const updateRollover = async () => {
      if (rollOver) {
        await removePreviousRollover(); // Wait for previous rollover to be removed
      }
    };
    updateRollover();

    // Load the GLTF object based on the selected item
    const loader = new GLTFLoader();
    loader.load(`/assets/objects/${selectedItemObject}.gltf`, (gltf) => {
      // Create a mesh with the loaded object and set the material color
      const mesh = gltf.scene.children[0] as THREE.InstancedMesh;

      // Assuming a single material for the mesh
      const material = mesh.material as THREE.MeshStandardMaterial;
      const colorValue = parseInt(selectedItemColor.slice(1), 16);
      const color = new THREE.Color(colorValue);
      material.color = color;

      // Set the mesh rotation, scale, and other properties
      mesh.rotation.set(rotation.x, rotation.y, rotation.z);
      mesh.scale.set(0.25, 0.25, 0.25);
      mesh.castShadow = false;
      mesh.receiveShadow = false;
      mesh.userData.selectable = true;

      mesh.geometry.computeBoundingBox();
      
      if(!isTouchDevice || !isMouseDown){
        sceneRef.current?.add(mesh);
      }
    
      // Set the rollOverRef to the mesh
      rollOverRef.current = mesh;
      rollOverRef.current?.geometry.computeBoundingBox();
      rollOverRef.current?.geometry.boundingBox?.getCenter(rollOverRef.current.position);

      (rollOverRef.current.material as THREE.MeshBasicMaterial).opacity = 0;
      rollOverRef.current.position.set(0, -5, 0);

    });
    return () => {
      // Remove rollOverHelper from scene when component unmounts
      if (rollOverRef.current) {
        scene?.remove(rollOverRef.current);
      }
    };
  }, [selectedItemObject, selectedItemColor, rotation]);

  // Set up grid on mount
  useEffect(() => {
    const scene = sceneRef.current;
    const gridHelper = new THREE.GridHelper(10, 40, 0x92bbc6, 0xa6cfda);
    gridHelper.position.set(0, -0.125, 0); // Set the position of the grid slightly above the floor plane
    gridRef.current = gridHelper;
    scene?.add(gridHelper);

    // Return clean-up function
    return () => {
      scene?.remove(gridHelper); // Remove gridHelper from scene
    };
  }, []);

  useEffect(() => {
    // Update the z-rotation and y-rotation of rollOverRef object based on the rotation prop
    if (rollOverRef.current) {
      rollOverRef.current.rotation.x = rotation.x;
      rollOverRef.current.rotation.y = rotation.y;
      rollOverRef.current.rotation.z = rotation.z;
    }
  }, [rotation.x, rotation.y, rotation.z, objectRefs, selectedItemObject, selectedItemColor]);
  
  function roundToNearest(value: number, multiple: number) {
    return Math.round(value / multiple) * multiple;
  }

  // Handle when the mouse moves
  const handleMouseMove = useCallback((event: MouseEvent) => {

    cursorPositionRef.current.x = event.clientX;
    cursorPositionRef.current.y = event.clientY;

    const canvas = canvasRef.current;
    const scene = sceneRef.current;
    const raycaster = new THREE.Raycaster();
    const camera = cameraRef.current; // Get the cameraRef value
    const mouse = mouseRef.current;
    const objects = objectRefs;
    const gridHelper = gridRef.current;
    const rollOverMesh = rollOverRef.current;

    // Add a variable to store the last position of rollOverMesh
    let lastPosition = new THREE.Vector3();

    // Null checks
    if (!canvas || !raycaster || !camera || !gridHelper || !rollOverMesh) return;

    // Get the 2D screen coordinates of the mouse cursor
    const rect = canvas.getBoundingClientRect();
    mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
    var mousePos = new THREE.Vector3(mouse.x, mouse.y, 0.5);
    mousePos.unproject(camera);

    // Update the picking ray with the camera and pointer position
    raycaster.setFromCamera(mouse, camera);

    // Calculate objects intersecting the picking ray
    const intersects = raycaster.intersectObjects(objectsRef.current);

    // Check if any objects were intersected
    if (intersects.length > 0) {

      const intersect = intersects[0];

      isCursorOverObject = true;
      let targetObject: THREE.Object3D<THREE.Event> | null = intersects[0]?.object ?? null;


      if (!isTouchDevice || !isMouseDown) {
        (rollOverMesh.material as THREE.MeshBasicMaterial).opacity = 0.75;
      }

      // Calculate the direction from the camera to the click position
      var lastPos = new THREE.Vector3();
      var dir = mousePos.sub(camera.position).normalize();
      
      var distance = -camera.position.y / dir.y;
      lastPos.copy(camera.position).add(dir.multiplyScalar(distance));

      // Get the camera's position and direction vector
      const cameraPosition = new THREE.Vector3();
      const cameraDirection = new THREE.Vector3();
      camera.getWorldPosition(cameraPosition);
      camera.getWorldDirection(cameraDirection);

      const gridSize = 0.25; // Size of the grid
      const blockSize = 0.125; // Size of the blocks

      // Get the position and size of the floor grid
      const floorGridSize = 10; // Assuming the floor grid size is 10x10
      const floorGridPosition = new THREE.Vector3(
        -gridSize * floorGridSize + blockSize,
        0,
        -gridSize * floorGridSize + blockSize
      );

      // Move the roll-over mesh to the closest point on the surface of the object if intersected
      if (targetObject.userData.isSelectable) {

        prevTargetObject = targetObject;
        selectedObject = targetObject;

        if (outlinePassRef.current) {
          outlinePassRef.current.selectedObjects = []; // clear the array
          outlinePassRef.current.selectedObjects.push(targetObject);
        }

        const point = intersects[0].point;
        const normal = intersects[0].face ? intersects[0].face.normal.clone().applyQuaternion(targetObject.quaternion) : new THREE.Vector3(0, 1, 0);
        const snappedPosition = new THREE.Vector3();
        snappedPosition.copy(point).add(normal.multiplyScalar(blockSize));
        const targetObjectPosition = new THREE.Vector3();
        targetObjectPosition.copy(targetObject.position).add(normal.multiplyScalar(blockSize));
        snappedPosition.x = roundToNearest(snappedPosition.x - floorGridPosition.x, gridSize) + floorGridPosition.x;
        snappedPosition.y = roundToNearest(snappedPosition.y - floorGridPosition.y, gridSize) + floorGridPosition.y;
        snappedPosition.z = roundToNearest(snappedPosition.z - floorGridPosition.z, gridSize) + floorGridPosition.z;
        const snappedPositionToTargetObject = new THREE.Vector3();
        snappedPositionToTargetObject.subVectors(snappedPosition, targetObjectPosition);
        const distanceToTargetObject = snappedPositionToTargetObject.length();
        if (distanceToTargetObject < blockSize) {
          // Snap to the next nearest empty grid space
          const offsetVector = normal.clone().applyQuaternion(new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 1, 0), Math.PI / 2));
          snappedPosition.add(offsetVector.multiplyScalar(gridSize));
        }

        // Check if the snapped position is outside the forbidden area
        const forbiddenBox = new THREE.Box3(
          new THREE.Vector3(-5, -0.0625, -5),
          new THREE.Vector3(5, 10.0625, 5)
        );

        if (forbiddenBox.containsPoint(snappedPosition)) {
          // The snapped position is outside the forbidden area
          // Set the position of the rollover mesh
          rollOverMesh.position.copy(snappedPosition);
          // Make the rollover mesh visible
          (rollOverMesh.material as THREE.MeshBasicMaterial).opacity = 0.75;
        } else {
          // The snapped position is inside the forbidden area
          // Move the rollover mesh out of sight
          rollOverMesh.position.set(0, -5000, 0);
          // Hide the rollover mesh
          (rollOverMesh.material as THREE.MeshBasicMaterial).opacity = 0;
        }

      } else {

        if (outlinePassRef.current) {
          outlinePassRef.current.selectedObjects = []; // clear the array
        }

        prevTargetObject = null;
        selectedObject = null;
        targetObject = null;

        // Calculate snapped position for rollOverMesh when no object is intersected
        const snappedPosition = new THREE.Vector3();
        snappedPosition.x = roundToNearest(lastPos.x - floorGridPosition.x, gridSize) + floorGridPosition.x;
        snappedPosition.y = roundToNearest(lastPos.y - floorGridPosition.y, gridSize) + floorGridPosition.y;
        snappedPosition.z = roundToNearest(lastPos.z - floorGridPosition.z, gridSize) + floorGridPosition.z;

        // Compare the newly calculated snapped position with the last position
        if (!rollOverMesh.position.equals(snappedPosition)) {
          // Set the position of the rollover mesh
        
          // Check if the snapped position is outside the forbidden area
          const forbiddenBox = new THREE.Box3(
            new THREE.Vector3(-5, -0.0625, -5),
            new THREE.Vector3(5, 10.0625, 5)
          );

          if (forbiddenBox.containsPoint(snappedPosition)) {
            // The snapped position is outside the forbidden area
            // Set the position of the rollover mesh
            rollOverMesh.position.copy(snappedPosition);
            // Make the rollover mesh visible
            (rollOverMesh.material as THREE.MeshBasicMaterial).opacity = 0.75;
          } else {
            // The snapped position is inside the forbidden area
            // Move the rollover mesh out of sight
            rollOverMesh.position.set(0, -5000, 0);
            // Hide the rollover mesh
            (rollOverMesh.material as THREE.MeshBasicMaterial).opacity = 0;
          }
        }
      }
    } else {

      if (outlinePassRef.current) {
        outlinePassRef.current.selectedObjects = []; // clear the array
      }

      prevTargetObject = null;
      selectedObject = null;

      lastPosition.set(0, 0, 0);
      (rollOverMesh.material as THREE.MeshBasicMaterial).opacity = 0;
      rollOverMesh.position.set(0, -5000, 0);
      isCursorOverObject = false;
    }
    
    isOverlapping = false;
    const overlapThreshold = 0.99;
    var originPoint = rollOverMesh.position.clone();
    const positionAttr = rollOverMesh.geometry.attributes.position;
    for (let vertexIndex = 0; vertexIndex < positionAttr.count; vertexIndex++) {
      const localVertex = new THREE.Vector3();
      localVertex.fromBufferAttribute(positionAttr as THREE.BufferAttribute, vertexIndex);
      var globalVertex = localVertex.applyMatrix4(rollOverMesh.matrixWorld);
      var directionVector = globalVertex.sub( rollOverMesh.position );
      var rolloverRaycaster = new THREE.Raycaster( originPoint, directionVector.clone().normalize() );
      var collisionResults = rolloverRaycaster.intersectObjects(objectsRef.current.filter(obj => obj.name !== 'floor'));
      if ( collisionResults.length > 0 && collisionResults[0].distance < overlapThreshold * directionVector.length() ) {
        //console.log("Hit");
        isOverlapping = true;
      }
    }
  }, [objectRefs, isTouchDevice]);

  const handleLongPress = () => {
    longPressOccurred = true;
    if (initialMousePosition !== null && initialMousePosition.x === cursorPositionRef.current.x && initialMousePosition.y === cursorPositionRef.current.y && !isOverlapping) {
      if (selectedObject) {
        // Create particle burst at object position
        if (sceneRef.current) {
          createParticleBurst(selectedObject.position.clone(), sceneRef.current, particles, cameraRef);
        }
        // Remove object from scene

        sceneRef.current?.remove(selectedObject);
        setObjectRefs((prevObjectRefs) => prevObjectRefs.filter((obj) => obj !== selectedObject));
        
        const objectId = selectedObject.id;

        // Delete the object from the database
        const projectDocRef = doc(db, `builds/${currentProjectId}`);
        const updatedObjects = objectsRef.current
          .slice(1)
          .filter(obj => obj.id !== objectId)
          .map(obj => ({
            id: obj.id,
            shape: obj.userData.shape,
            color: obj.userData.color,
            position: obj.position.toArray(),
            rotation: obj.rotation.toArray(),
            scale: obj.scale.toArray(),
          }));

        // Add the current timestamp to the update data
        const updateData = {
          objects: updatedObjects,
          timestampUpdated: serverTimestamp()
        };

        updateDoc(projectDocRef, updateData)
          .then(() => {
            //console.log('Object deleted from database!');
          })
          .catch((error) => {
            //console.error('Error deleting object from database: ', error);
          });


        selectedObject = null;
      }
    } else {
      // Handle long press with movement
    }
  };

  const handleOverlayMouseOver = () => {
    setIsOverOverlay(true);
  };

  const handleOverlayMouseOut = () => {
    setIsOverOverlay(false);
  };

  useEffect(() => {
    const overlays = document.querySelectorAll('.overlay');
    overlays.forEach((overlay) => {
      overlay.addEventListener('mouseover', handleOverlayMouseOver);
      overlay.addEventListener('mouseout', handleOverlayMouseOut);
    });

    return () => {
      overlays.forEach((overlay) => {
        overlay.removeEventListener('mouseover', handleOverlayMouseOver);
        overlay.removeEventListener('mouseout', handleOverlayMouseOut);
      });
    };
  }, []);

  // Mouse Down Event handler
  const handlePointerDown = (event: MouseEvent) => {

    if(isTouchDevice) return;
    if(!isMouseDown){setIsMouseDown(true);}

    prevTargetObject = null;

    // Store the initial mouse position
    initialMousePosition = { x: cursorPositionRef.current.x, y: cursorPositionRef.current.y };

    if (rollOverRef.current) {
      const material = rollOverRef.current.material as THREE.MeshBasicMaterial;
      const delay = 50; // in milliseconds
      const opacityStep = 0.01;
      const duration = 100; // in milliseconds
      const startTime = Date.now();

      setTimeout(() => {
        const animate = () => {
          const elapsed = Date.now() - startTime;
          const opacity = Math.max(1 - elapsed / duration, 0);
          material.opacity = opacity;
          if (opacity > 0) {
            requestAnimationFrame(animate);
          }
        };
        animate();
      }, delay);
    }

    // Start the press timer
    pressTimer = setTimeout(() => handleLongPress(), 100);
  };

  // Define a new function to handle outlining logic
  const handleTouchPlacement = (event:TouchEvent) => {

    cursorPositionRef.current.x = event.touches[0].clientX;
    cursorPositionRef.current.y = event.touches[0].clientY;

    const canvas = canvasRef.current;
    const scene = sceneRef.current;
    const raycaster = new THREE.Raycaster();
    const camera = cameraRef.current; // Get the cameraRef value
    const mouse = mouseRef.current;
    const objects = objectRefs;
    const gridHelper = gridRef.current;
    const rollOverMesh = rollOverRef.current;

    // Add a variable to store the last position of rollOverMesh
    let lastPosition = new THREE.Vector3();

    // Null checks
    if (!canvas || !raycaster || !camera || !gridHelper || !rollOverMesh) return;

    // Get the 2D screen coordinates of the mouse cursor
    const rect = canvas.getBoundingClientRect();
    mouse.x = ((event.touches[0].clientX - rect.left) / rect.width) * 2 - 1;
    mouse.y = -((event.touches[0].clientY - rect.top) / rect.height) * 2 + 1;
    var mousePos = new THREE.Vector3(mouse.x, mouse.y, 0.5);
    mousePos.unproject(camera);

    // Update the picking ray with the camera and pointer position
    raycaster.setFromCamera(mouse, camera);

    // Calculate objects intersecting the picking ray
    const intersects = raycaster.intersectObjects(objectsRef.current);

    // Check if any objects were intersected
    if (intersects.length > 0) {

      const intersect = intersects[0];

      isCursorOverObject = true;
      let targetObject: THREE.Object3D<THREE.Event> | null = intersects[0]?.object ?? null;

      // Calculate the direction from the camera to the click position
      var lastPos = new THREE.Vector3();
      var dir = mousePos.sub(camera.position).normalize();
      
      var distance = -camera.position.y / dir.y;
      lastPos.copy(camera.position).add(dir.multiplyScalar(distance));

      // Get the camera's position and direction vector
      const cameraPosition = new THREE.Vector3();
      const cameraDirection = new THREE.Vector3();
      camera.getWorldPosition(cameraPosition);
      camera.getWorldDirection(cameraDirection);

      const gridSize = 0.25; // Size of the grid
      const blockSize = 0.125; // Size of the blocks

      // Get the position and size of the floor grid
      const floorGridSize = 10; // Assuming the floor grid size is 10x10
      const floorGridPosition = new THREE.Vector3(
        -gridSize * floorGridSize + blockSize,
        0,
        -gridSize * floorGridSize + blockSize
      );

      // Move the roll-over mesh to the closest point on the surface of the object if intersected
      if (targetObject.userData.isSelectable) {

        prevTargetObject = targetObject;
        selectedObject = targetObject;

        if (outlinePassRef.current) {
          outlinePassRef.current.selectedObjects = []; // clear the array
          outlinePassRef.current.selectedObjects.push(targetObject);
        }

        const point = intersects[0].point;
        const normal = intersects[0].face ? intersects[0].face.normal.clone().applyQuaternion(targetObject.quaternion) : new THREE.Vector3(0, 1, 0);
        const snappedPosition = new THREE.Vector3();
        snappedPosition.copy(point).add(normal.multiplyScalar(blockSize));
        const targetObjectPosition = new THREE.Vector3();
        targetObjectPosition.copy(targetObject.position).add(normal.multiplyScalar(blockSize));
        snappedPosition.x = roundToNearest(snappedPosition.x - floorGridPosition.x, gridSize) + floorGridPosition.x;
        snappedPosition.y = roundToNearest(snappedPosition.y - floorGridPosition.y, gridSize) + floorGridPosition.y;
        snappedPosition.z = roundToNearest(snappedPosition.z - floorGridPosition.z, gridSize) + floorGridPosition.z;
        const snappedPositionToTargetObject = new THREE.Vector3();
        snappedPositionToTargetObject.subVectors(snappedPosition, targetObjectPosition);
        const distanceToTargetObject = snappedPositionToTargetObject.length();
        if (distanceToTargetObject < blockSize) {
          // Snap to the next nearest empty grid space
          const offsetVector = normal.clone().applyQuaternion(new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 1, 0), Math.PI / 2));
          snappedPosition.add(offsetVector.multiplyScalar(gridSize));
        }

        // Check if the snapped position is outside the forbidden area
        const forbiddenBox = new THREE.Box3(
          new THREE.Vector3(-5, -0.0625, -5),
          new THREE.Vector3(5, 10.0625, 5)
        );

        if (forbiddenBox.containsPoint(snappedPosition)) {
          // The snapped position is outside the forbidden area
          // Set the position of the rollover mesh
          rollOverMesh.position.copy(snappedPosition);
        } else {
          // The snapped position is inside the forbidden area
          // Move the rollover mesh out of sight
          rollOverMesh.position.set(0, -5000, 0);
          return;
        }

        // Update the overlap detection logic here
        isOverlapping = false;
        const overlapThreshold = 0.99;
        rollOverMesh.updateMatrixWorld(); // Update the world matrix to reflect any changes in the mesh's position
        const originPoint = snappedPosition.clone(); // Set originPoint to the current snapped position
        const positionAttr = rollOverMesh.geometry.attributes.position;
        for (let vertexIndex = 0; vertexIndex < positionAttr.count; vertexIndex++) {
          const localVertex = new THREE.Vector3();
          localVertex.fromBufferAttribute(positionAttr as THREE.BufferAttribute, vertexIndex);
          const globalVertex = localVertex.applyMatrix4(rollOverMesh.matrixWorld);
          const directionVector = globalVertex.sub(snappedPosition); // Use snappedPosition here
          const rolloverRaycaster = new THREE.Raycaster(originPoint, directionVector.clone().normalize());
          const collisionResults = rolloverRaycaster.intersectObjects(objectsRef.current.filter(obj => obj !== targetObject && obj.name !== 'floor'));
          if (collisionResults.length > 0 && collisionResults[0].distance < overlapThreshold * directionVector.length()) {
            isOverlapping = true;
            break; // No need to continue checking other vertices if we already found an overlap
          }
        }

        // Set the position of the rollover mesh after overlap detection
        if (isOverlapping) {
          rollOverMesh.position.set(0, -5000, 0);
        } else {
          rollOverMesh.position.copy(snappedPosition);
        }

      } else {

        if (outlinePassRef.current) {
          outlinePassRef.current.selectedObjects = []; // clear the array
        }

        prevTargetObject = null;
        selectedObject = null;
        targetObject = null;

        // Calculate snapped position for rollOverMesh when no object is intersected
        const snappedPosition = new THREE.Vector3();
        snappedPosition.x = roundToNearest(lastPos.x - floorGridPosition.x, gridSize) + floorGridPosition.x;
        snappedPosition.y = roundToNearest(lastPos.y - floorGridPosition.y, gridSize) + floorGridPosition.y;
        snappedPosition.z = roundToNearest(lastPos.z - floorGridPosition.z, gridSize) + floorGridPosition.z;

        // Compare the newly calculated snapped position with the last position
        if (!rollOverMesh.position.equals(snappedPosition)) {
          // Set the position of the rollover mesh
        
          // Check if the snapped position is outside the forbidden area
          const forbiddenBox = new THREE.Box3(
            new THREE.Vector3(-5, -0.0625, -5),
            new THREE.Vector3(5, 10.0625, 5)
          );

          if (forbiddenBox.containsPoint(snappedPosition)) {
            // The snapped position is outside the forbidden area
            // Set the position of the rollover mesh
            rollOverMesh.position.copy(snappedPosition);
          } else {
            // The snapped position is inside the forbidden area
            // Move the rollover mesh out of sight
            rollOverMesh.position.set(0, -5000, 0);
            return;
          }

          // Update the overlap detection logic here
          isOverlapping = false;
          const overlapThreshold = 0.99;
          rollOverMesh.updateMatrixWorld(); // Update the world matrix to reflect any changes in the mesh's position
          const originPoint = snappedPosition.clone(); // Set originPoint to the current snapped position
          const positionAttr = rollOverMesh.geometry.attributes.position;
          for (let vertexIndex = 0; vertexIndex < positionAttr.count; vertexIndex++) {
              const localVertex = new THREE.Vector3();
              localVertex.fromBufferAttribute(positionAttr as THREE.BufferAttribute, vertexIndex);
              const globalVertex = localVertex.applyMatrix4(rollOverMesh.matrixWorld);
              const directionVector = globalVertex.sub(snappedPosition); // Use snappedPosition here
              const rolloverRaycaster = new THREE.Raycaster(originPoint, directionVector.clone().normalize());
              const collisionResults = rolloverRaycaster.intersectObjects(objectsRef.current.filter(obj => obj.name !== 'floor'));
              if (collisionResults.length > 0 && collisionResults[0].distance < overlapThreshold * directionVector.length()) {
                  isOverlapping = true;
                  break; // No need to continue checking other vertices if we already found an overlap
              }
          }

          // Set the position of the rollover mesh after overlap detection
          if (isOverlapping) {
              rollOverMesh.position.set(0, -5000, 0);
          } else {
              rollOverMesh.position.copy(snappedPosition);
          }

        }
      }
    } else {

      if (outlinePassRef.current) {
        outlinePassRef.current.selectedObjects = []; // clear the array
      }

      prevTargetObject = null;
      selectedObject = null;

      lastPosition.set(0, 0, 0);
      (rollOverMesh.material as THREE.MeshBasicMaterial).opacity = 0;
      rollOverMesh.position.set(0, -5000, 0);
      isCursorOverObject = false;
    }
    
  };

  // Event handler
  const handlePointerUp = (event: MouseEvent) => {

    if(isTouchDevice) return;
    if(isMouseDown){setIsMouseDown(false);}

    prevTargetObject = null;

    if (!canvasRef.current) {
      return;
    }
    
    if (isOverlapping) {
      // Do nothing if there's an overlap
      setShowToast(true);
      return;
    }

    const isClickOnOverlay = event.target instanceof Element && event.target.closest('.overlay');
    
    if (!isClickOnOverlay) {
      clearTimeout(pressTimer);
      if (longPressOccurred) {
        longPressOccurred = false;
        return;
      }

      // Check if the cursor moved during the press timer
      const cursorMoved = initialMousePosition !== null && (initialMousePosition.x !== cursorPositionRef.current.x || initialMousePosition.y !== cursorPositionRef.current.y);

      if (!cursorMoved && isCursorOverObject && rollOverRef.current && sceneRef.current) {
        // Clone the geometry of the original object
        const rollOverMesh = rollOverRef.current;

        (rollOverMesh.material as THREE.MeshStandardMaterial).opacity = 1;
        const newGeometry = rollOverMesh.geometry.clone();

        // Clone the materials of the original object
        let newMaterials;
        if (Array.isArray(rollOverMesh.material)) {
          newMaterials = rollOverMesh.material.map(material => material.clone());
        } else {
          newMaterials = rollOverMesh.material.clone();
        }

        // Create a new Mesh object with the cloned geometry and materials
        const newObject = new THREE.Mesh(newGeometry, newMaterials);

        // Set the position and other properties of the new object
        newObject.position.copy(rollOverMesh.position);
        newObject.rotation.copy(rollOverMesh.rotation);
        newObject.scale.copy(rollOverMesh.scale);
        newObject.receiveShadow = true;
        newObject.castShadow = true;
        newObject.userData.isSelectable = true;

        // generateUniqueId() is a function that returns a unique ID
        newObject.userData.id = generateUniqueId(); 

        // Calculate the bounding box of the geometry
        newObject.geometry.computeBoundingBox();

        // Set the shape and color properties of the new object
        newObject.userData.color = selectedItemColorRef.current;
        newObject.userData.shape = selectedItemObjectRef.current;
        //console.log(newObject.userData.color);

        // Add the new object to the scene and update the objectRefs array
        sceneRef.current.add(newObject);
        setObjectRefs(prevObjectRefs => [...prevObjectRefs, newObject]);
        objectsRef.current.push(newObject);
        selectedObject = newObject;
        isCursorOverObject = false;

        // Add the new object to the Firestore database
        const projectDocRef = doc(db, `builds/${currentProjectId}`);
        const objects = objectsRef.current.slice(1).map(obj => ({
          id: obj.userData.id,
          shape: obj.userData.shape,
          color: obj.userData.color,
          position: obj.position.toArray(),
          rotation: obj.rotation.toArray(),
          scale: obj.scale.toArray(),
        }));

        // Add the current timestamp to the update data
        const updateData = {
          objects,
          timestampUpdated: serverTimestamp()
        };

        updateDoc(projectDocRef, updateData)
          .then(() => {
            //console.log('New object added to Firestore!');
          })
          .catch((error) => {
            //console.error('Error adding object to Firestore: ', error);
          });

      }
      initialMousePosition = null;
    }
  };

  const handleTouchStart = (event: TouchEvent) => {
    //event.preventDefault();

    if (!isTouchDevice) {setIsTouchDevice(true)}

    if (event.touches.length === 1) {
      const touch = event.touches[0];
      touchStartLocation = { x: touch.clientX, y: touch.clientY };

      // Perform outlining immediately without waiting for handleMouseMove
      handleTouchPlacement(event);

      setIsLongPress(false);
      longPressTimeoutRef.current = setTimeout(() => {
        setIsLongPress(true);
        //alert("DELETE OBJECT!");

        if (selectedObject) {
          // Create particle burst at object position
          if (sceneRef.current) {
            createParticleBurst(selectedObject.position.clone(), sceneRef.current, particles, cameraRef);
          }
          // Remove object from scene

          sceneRef.current?.remove(selectedObject);
          setObjectRefs((prevObjectRefs) => prevObjectRefs.filter((obj) => obj !== selectedObject));
          
          const objectId = selectedObject.id;

          // Delete the object from the database
          const projectDocRef = doc(db, `builds/${currentProjectId}`);
          const updatedObjects = objectsRef.current
            .slice(1)
            .filter(obj => obj.id !== objectId)
            .map(obj => ({
              id: obj.id,
              shape: obj.userData.shape,
              color: obj.userData.color,
              position: obj.position.toArray(),
              rotation: obj.rotation.toArray(),
              scale: obj.scale.toArray(),
            }));

          // Add the current timestamp to the update data
          const updateData = {
            objects: updatedObjects,
            timestampUpdated: serverTimestamp()
          };

          updateDoc(projectDocRef, updateData)
            .then(() => {
              //console.log('Object deleted from database!');
            })
            .catch((error) => {
              //console.error('Error deleting object from database: ', error);
            });


          selectedObject = null;
        }

      }, 200);
    }
  };

  const handleTouchMove = (event: TouchEvent) => {

    if (event.touches.length !== 1 || isLongPress) {
      clearTimeout(longPressTimeoutRef.current!);
      longPressTimeoutRef.current = null;
    } else {
      const touch = event.touches[0];
      const touchDistanceThreshold = 1; // Tweak this threshold as needed
      const distance = Math.sqrt(
        (touch.clientX - touchStartLocation!.x) ** 2 +
        (touch.clientY - touchStartLocation!.y) ** 2
      );

      if (distance >= touchDistanceThreshold) {
        clearTimeout(longPressTimeoutRef.current!);
        longPressTimeoutRef.current = null;
      }
    }

    cursorPositionRef.current.x = event.touches[0].clientX;
    cursorPositionRef.current.y = event.touches[0].clientY;

    const canvas = canvasRef.current;
    const scene = sceneRef.current;
    const raycaster = new THREE.Raycaster();
    const camera = cameraRef.current; // Get the cameraRef value
    const mouse = mouseRef.current;
    const objects = objectRefs;
    const gridHelper = gridRef.current;
    const rollOverMesh = rollOverRef.current;

    // Add a variable to store the last position of rollOverMesh
    let lastPosition = new THREE.Vector3();

    // Null checks
    if (!canvas || !raycaster || !camera || !gridHelper || !rollOverMesh) return;

    // Get the 2D screen coordinates of the finger
    // For touch events, you may need to handle multiple touches (event.touches) appropriately.
    // This example assumes handling a single touch.
    const rect = canvas.getBoundingClientRect();
    const touch = event.touches[0]; // Assuming handling a single touch

    // Calculate the touch position relative to the canvas
    const touchX = (touch.clientX - rect.left) / rect.width;
    const touchY = (touch.clientY - rect.top) / rect.height;

    // Convert touch coordinates to clip space (-1 to 1)
    const touchClipX = (touchX * 2) - 1;
    const touchClipY = -(touchY * 2) + 1;

    // Create a 2D vector for touch position in clip space
    const touchPos = new THREE.Vector2(touchClipX, touchClipY);

    // Update the picking ray with the camera and touch position
    raycaster.setFromCamera(touchPos, camera);

    // Calculate objects intersecting the picking ray
    const intersects = raycaster.intersectObjects(objectsRef.current);

    // Check if any objects were intersected
    if (intersects.length > 0) {
      const intersect = intersects[0];
      isCursorOverObject = true;
      let targetObject: THREE.Object3D<THREE.Event> | null = intersects[0]?.object ?? null;

      // Move the roll-over mesh to the closest point on the surface of the object if intersected
      if (targetObject.userData.isSelectable) {

        if (outlinePassRef.current) {
          outlinePassRef.current.selectedObjects = []; // clear the array
          outlinePassRef.current.selectedObjects.push(targetObject);
        }

      } else {

        if (outlinePassRef.current) {
          outlinePassRef.current.selectedObjects = []; // clear the array
        }
      }
    } else {
      if (outlinePassRef.current) {
        outlinePassRef.current.selectedObjects = []; // clear the array
      }
    }
  };

  const handleTouchEnd = (event: TouchEvent) => {
    event.preventDefault();

    if (outlinePassRef.current) {
      outlinePassRef.current.selectedObjects = []; // clear the array
    }

    if(!isTouchDevice) return;

    prevTargetObject = null;

    if (!canvasRef.current) {
      return;
    }

    const isClickOnOverlay = event.target instanceof Element && event.target.closest('.overlay');
    
    if (!isClickOnOverlay) {

      clearTimeout(pressTimer);
      if (longPressOccurred) {
        longPressOccurred = false;
        return;
      }

      clearTimeout(longPressTimeoutRef.current!);
      longPressTimeoutRef.current = null;

      if (!isLongPress && event.touches.length === 0) {

        if (isOverlapping) {
          // Do nothing if there's an overlap
          setShowToast(true);
          return;
        }

        const touch = event.changedTouches[0];
        const touchDistanceThreshold = 1; // Tweak this threshold as needed
        const distance = Math.sqrt(
          (touch.clientX - touchStartLocation!.x) ** 2 +
          (touch.clientY - touchStartLocation!.y) ** 2
        );

        if (distance < touchDistanceThreshold) {
          //alert("ADD OBJECT!");


          if (isCursorOverObject && rollOverRef.current && sceneRef.current) {
            
            //alert("ADD OBJECT!");

            // Clone the geometry of the original object
            const rollOverMesh = rollOverRef.current;

            (rollOverMesh.material as THREE.MeshStandardMaterial).opacity = 1;
            const newGeometry = rollOverMesh.geometry.clone();

            // Clone the materials of the original object
            let newMaterials;
            if (Array.isArray(rollOverMesh.material)) {
              newMaterials = rollOverMesh.material.map(material => material.clone());
            } else {
              newMaterials = rollOverMesh.material.clone();
            }

            // Create a new Mesh object with the cloned geometry and materials
            const newObject = new THREE.Mesh(newGeometry, newMaterials);

            // Set the position and other properties of the new object
            newObject.position.copy(rollOverMesh.position);
            newObject.rotation.copy(rollOverMesh.rotation);
            newObject.scale.copy(rollOverMesh.scale);
            newObject.receiveShadow = true;
            newObject.castShadow = true;
            newObject.userData.isSelectable = true;

            // generateUniqueId() is a function that returns a unique ID
            newObject.userData.id = generateUniqueId(); 

            // Calculate the bounding box of the geometry
            newObject.geometry.computeBoundingBox();

            // Set the shape and color properties of the new object
            newObject.userData.color = selectedItemColorRef.current;
            newObject.userData.shape = selectedItemObjectRef.current;
            //console.log(newObject.userData.color);

            // Add the new object to the scene and update the objectRefs array
            sceneRef.current.add(newObject);
            setObjectRefs(prevObjectRefs => [...prevObjectRefs, newObject]);
            objectsRef.current.push(newObject);
            selectedObject = newObject;
            isCursorOverObject = false;
            (rollOverMesh.material as THREE.MeshStandardMaterial).opacity = 0;

            // Add the new object to the Firestore database
            const projectDocRef = doc(db, `builds/${currentProjectId}`);
            const objects = objectsRef.current.slice(1).map(obj => ({
              id: obj.userData.id,
              shape: obj.userData.shape,
              color: obj.userData.color,
              position: obj.position.toArray(),
              rotation: obj.rotation.toArray(),
              scale: obj.scale.toArray(),
            }));

            // Add the current timestamp to the update data
            const updateData = {
              objects,
              timestampUpdated: serverTimestamp()
            };

            updateDoc(projectDocRef, updateData)
              .then(() => {
                //console.log('New object added to Firestore!');
              })
              .catch((error) => {
                //console.error('Error adding object to Firestore: ', error);
              });
          }
        }
      }
    
    }

    // Move the rollover mesh out of sight
    rollOverRef.current?.position.set(0, -5000, 0);
    // Hide the rollover mesh
    (rollOverRef.current?.material as THREE.MeshBasicMaterial).opacity = 0;

    touchStartLocation = null;
    setIsLongPress(false);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.addEventListener("touchstart", handleTouchStart);
      canvas.addEventListener("touchmove", handleTouchMove);
      canvas.addEventListener("touchend", handleTouchEnd);
    }

    return () => {
      if (canvas) {
        canvas.removeEventListener("touchstart", handleTouchStart);
        canvas.removeEventListener("touchmove", handleTouchMove);
        canvas.removeEventListener("touchend", handleTouchEnd);
      }
    };
  }, [handleTouchStart, handleTouchMove, handleTouchEnd]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.addEventListener('mouseup', handlePointerUp);
      return () => {
        canvas.removeEventListener('mouseup', handlePointerUp);
      };
    }
  }, [handlePointerUp]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.addEventListener('mousedown', handlePointerDown);
      return () => {
        canvas.removeEventListener('mousedown', handlePointerDown);
      };
    }
  }, [handlePointerDown]);

  useEffect(() => {
    objectsRef.current = objectRefs;
    objectRefs.forEach(object => {
      object.frustumCulled = true;
    });
  }, [objectRefs, handleMouseMove]);

  // Prevent events on HTML overlays from propagating to the three.js canvas
  useEffect(() => {
    const overlayElements = document.querySelectorAll('.overlay');
    overlayElements.forEach(overlayElement => {
      overlayElement.addEventListener('mousedown', handleOverlayMouseDown);
      overlayElement.addEventListener('mousemove', handleOverlayMouseMove);
      overlayElement.addEventListener('touchstart', handleOverlayMouseDown);
    });
    return () => {
      overlayElements.forEach(overlayElement => {
        overlayElement.removeEventListener('mousedown', handleOverlayMouseDown);
        overlayElement.removeEventListener('mousemove', handleOverlayMouseMove);
        overlayElement.removeEventListener('touchstart', handleOverlayMouseDown);
      });
    };
  }, []);

  const handleOverlayMouseDown: EventListener = (event: Event) => {
    event.stopPropagation();

  };

  const handleOverlayMouseMove: EventListener = (event: Event) => {
    event.stopPropagation();
  };

  // Add mousemove event listener on mount
  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mousedown', handlePointerDown);
    window.addEventListener('mouseup', handlePointerUp);
    // Clean up
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mousedown', handlePointerDown);
      window.removeEventListener('mouseup', handlePointerUp);
    };
  }, [handleMouseMove]);

  // Update camera and controls on render
  useEffect(() => {
    const camera = cameraRef.current;
    //const controls = controlsRef.current;
  });

  // Generate thumbnail image from canvas
  const generateThumbnail = () => {

    const canvas = canvasRef.current;

    if (!canvas) {
      return;
    }

    const thumbnailSize = 400;

    const aspectRatio = canvas.width / canvas.height;

    const thumbnailCanvas = document.createElement('canvas');
    const thumbnailContext = thumbnailCanvas.getContext('2d');

    if (!thumbnailContext) {
      return;
    }

    thumbnailCanvas.width = thumbnailSize;
    thumbnailCanvas.height = thumbnailSize;

    let sourceWidth;
    let sourceHeight;
    let offsetX = 0;
    let offsetY = 0;

    if (aspectRatio > 1) {
      sourceWidth = canvas.height;
      sourceHeight = canvas.height;
      offsetX = (canvas.width - canvas.height) / 2;
    } else {
      sourceWidth = canvas.width;
      sourceHeight = canvas.width;
      offsetY = (canvas.height - canvas.width) / 2;
    }

    thumbnailContext.drawImage(
      canvas,
      offsetX,
      offsetY,
      sourceWidth,
      sourceHeight,
      0,
      0,
      thumbnailSize,
      thumbnailSize
    );

    const dataUrl = thumbnailCanvas.toDataURL('image/png');

    setThumbnailUrl(dataUrl ?? null);
  };

  useEffect(() => {
  if (!currentProjectId || !thumbnailUrl) return;

    // Update the Firestore document with the thumbnail URL
    const projectDocRef = doc(db, `builds/${currentProjectId}`);
    const updateData = {
      thumbnail: thumbnailUrl,
      timestampUpdated: serverTimestamp(),
    };
    updateDoc(projectDocRef, updateData)
      .then(() => {
        // Thumbnail URL saved to Firestore successfully
      })
      .catch((error) => {
        // Error saving thumbnail URL to Firestore
      });
  }, [thumbnailUrl]);

  useEffect(() => {
    if (isLoading) return;
    if (!isLoading) {
      const difference = objectRefs.length - previousObjectCount.current;
      if (Math.abs(difference) >= 12) {
        generateThumbnail();
        previousObjectCount.current = objectRefs.length;
      }
    }
  }, [objectRefs]);

  // Function to update the timestampLive field with the current timestamp
  const updateTimestampLive = async () => {
    if (!currentProjectId) return;
    const projectDocRef = doc(db, 'builds', currentProjectId);
    const timestampLive = new Date(); // Get the current timestamp
    try {
      await updateDoc(projectDocRef, { timestampLive });
    } catch (error) {
      console.error('Error updating timestampLive field:', error);
    }
  };

  // Use useEffect to update timestampLive on initial load
  useEffect(() => {
    updateTimestampLive(); // Update timestampLive when the component first loads

    // Use setInterval to update timestampLive every minute
    const interval = setInterval(updateTimestampLive, 60000); // Update every minute (60,000 milliseconds)
    return () => {
      clearInterval(interval); // Clear the interval when the component unmounts
    };
  }, [currentProjectId]);

  useEffect(() => {
    return () => {
      handleUnload();
    };
  }, [currentProjectId]);

  const handleUnload = async () => {
    if (currentProjectId) {
      try {
        const projectDocRef = doc(getFirestore(), 'builds', currentProjectId);
        await setDoc(projectDocRef, { live: false }, { merge: true });
      } catch (error) {
        console.error('Error updating Firestore document:', error);
      }
    }
  };

  useEffect(() => {
    // Add event listener for beforeunload
    window.addEventListener('beforeunload', handleUnload);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [currentProjectId]);

  // Update the "live" field in Firestore
  const handleLiveStatusChange = async (event: CustomEvent<any>) => {
    const newMode = event.detail.value;
    // Check if there's a valid project ID
    if (!currentProjectId) return;

    try {
      // Get a reference to the document in Firestore
      const projectDocRef: DocumentReference<DocumentData> = doc(db, 'builds', currentProjectId);

      // Update the "live" field using setDoc
      await setDoc(projectDocRef, { live: newMode === 'live' }, { merge: true });

      // Update the state to reflect the current mode
      setMode(newMode);
    } catch (error) {
      //console.error('Error updating Firestore document:', error);
    }
  };

  const handleButtonPress = (direction: THREE.Vector3, key: string) => {
    if (!isKeyPressed[key]) {
      setIsKeyPressed((prevState) => ({ ...prevState, [key]: true }));
      const intervalId = setInterval(() => moveCamera(direction), 16);
      setIntervalIds((prevState) => ({ ...prevState, [key]: intervalId }));
    }
  };

  const handleButtonRelease = (key: string) => {
    if (isKeyPressed[key]) {
      setIsKeyPressed((prevState) => ({ ...prevState, [key]: false }));
      const intervalId = intervalIds[key];
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalIds((prevState) => ({ ...prevState, [key]: undefined }));
      }
    }
  };
  const handleMoveForwardPress = () => {
    handleButtonPress(directionMap.w, 'w');
  };

  const handleMoveForwardRelease = () => {
    handleButtonRelease('w');
  };

  const handleMoveBackwardPress = () => {
    handleButtonPress(directionMap.s, 's');
  };

  const handleMoveBackwardRelease = () => {
    handleButtonRelease('s');
  };

  const handleMoveLeftPress = () => {
    handleButtonPress(directionMap.a, 'a');
  };

  const handleMoveLeftRelease = () => {
    handleButtonRelease('a');
  };

  const handleMoveRightPress = () => {
    handleButtonPress(directionMap.d, 'd');
  };

  const handleMoveRightRelease = () => {
    handleButtonRelease('d');
  };

  const handleMoveForward = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    moveCamera(directionMap.w);
  };

  const handleMoveBackward = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    moveCamera(directionMap.s);
  };

  const handleMoveLeft = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    moveCamera(directionMap.a);
  };

  const handleMoveRight = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    moveCamera(directionMap.d);
  };

  const handleMoveUp = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    moveCamera(directionMap.e);
  };

  const handleMoveDown = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    moveCamera(directionMap.q);
  };

  const [isKeyPressed, setIsKeyPressed] = useState<KeyState>({
    w: false,
    a: false,
    s: false,
    d: false,
    q: false,
    e: false,
    upArrow: false,
    downArrow: false,
  });

  const moveCamera = (direction: THREE.Vector3) => {
    const speed = 0.025; // Adjust the speed as needed
    const controls = controlsRef.current;

    if (!controls) {
      // controls is null, so we can't continue
      return;
    }

    const camera = controls.object;
    const target = controls.target;
    let right; // Declare the right variable here

    switch (direction) {
      case directionMap.e: // Move up
        camera.position.addScaledVector(direction, speed);
        target.addScaledVector(direction, speed);
        break;
      case directionMap.q: // Move down (opposite direction of 'E' key)
        const downDirection = new THREE.Vector3(0, -1, 0); // Opposite direction
        camera.position.addScaledVector(downDirection, speed);
        target.addScaledVector(downDirection, speed);
        break;
      case directionMap.w: // Move forward
        const forwardDirection = new THREE.Vector3();
        camera.getWorldDirection(forwardDirection);
        forwardDirection.setY(0).normalize(); // Lock the Y-component to 0
        camera.position.addScaledVector(forwardDirection, speed);
        target.addScaledVector(forwardDirection, speed);
        break;
      case directionMap.s: // Move backward
        const backwardDirection = new THREE.Vector3();
        camera.getWorldDirection(backwardDirection);
        backwardDirection.setY(0).normalize(); // Lock the Y-component to 0
        camera.position.addScaledVector(backwardDirection, -speed);
        target.addScaledVector(backwardDirection, -speed);
        break;
      case directionMap.a: // Move left
        right = new THREE.Vector3();
        camera.getWorldDirection(right);
        right.cross(camera.up).normalize();
        right.setY(0).normalize();
        camera.position.addScaledVector(right, -speed);
        target.addScaledVector(right, -speed);
        break;
      case directionMap.d: // Move right
        right = new THREE.Vector3();
        camera.getWorldDirection(right);
        right.cross(camera.up).normalize();
        right.setY(0).normalize();
        camera.position.addScaledVector(right, speed);
        target.addScaledVector(right, speed);
        break;
      default:
        break;
    }
  };

  const [intervalIds, setIntervalIds] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const key = event.key.toLowerCase();
      if (!isKeyPressed[key]) {
        // Code to handle key press
        //console.log(`${key} key is pressed.`);
        setIsKeyPressed((prevState) => ({ ...prevState, [key]: true }));

        // Move the camera in the specified direction when a key is pressed
        const direction = directionMap[key];
        if (direction) {
          const intervalId = setInterval(() => moveCamera(direction), 16); // Run the movement every frame (approx. 60 FPS)
          setIntervalIds((prevState) => ({ ...prevState, [key]: intervalId }));
        }
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      const key = event.key.toLowerCase();
      if (isKeyPressed[key]) {
        // Code to handle key release
        //console.log(`${key} key is released.`);
        setIsKeyPressed((prevState) => ({ ...prevState, [key]: false }));

        // Stop camera movement when the key is released
        const intervalId = intervalIds[key];
        if (intervalId) {
          clearInterval(intervalId);
          setIntervalIds((prevState) => ({ ...prevState, [key]: undefined }));
        }
      }
    };

    // Add event listeners when the component mounts
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    // Clean up the event listeners when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [isKeyPressed, intervalIds]);

  useEffect(() => {
    onCameraOrientationChange(cameraOrientation);
  }, [cameraOrientation, onCameraOrientationChange]);

  const capitalizeFirstLetter = (cameraOrientation: string) => {
    if (!cameraOrientation || typeof cameraOrientation !== 'string') {
      // Return an empty string or handle the invalid input as per your requirement
      return '';
    }

    const firstLetter = cameraOrientation.charAt(0).toUpperCase();
    return firstLetter;
  };

  const handlePerspectiveStateChange = (state: 'orbit' | 'firstPerson') => {
    // Update controls based on the selected perspective state
    if (controlsRef.current && cameraRef.current) {
      if (state === "orbit") {
        controlsRef.current.minDistance = 1;
        controlsRef.current.maxDistance = 30;
        controlsRef.current.target.set(0, 0, 0); // Set the target position to the center of the scene
        controlsRef.current.dampingFactor = .05;
        controlsRef.current.rotateSpeed = 1;
        controlsRef.current.update();

        // Reset the camera zoom level to 14
        const zoomLevel = 14;
        const direction = cameraRef.current.position.clone().normalize();
        const newCameraPosition = direction.multiplyScalar(zoomLevel);
        cameraRef.current.position.copy(newCameraPosition);

        // Reset camera rotation
        cameraRef.current.rotation.set(0, 0, 0);

        setShowButtons(false);

      } else if (state === "firstPerson") {
        // Set minDistance and maxDistance to a small value to allow a minimal zoom level
        controlsRef.current.minDistance = 0.1;
        controlsRef.current.maxDistance = 0.1;
        controlsRef.current.dampingFactor = .3;
        controlsRef.current.rotateSpeed = .6;

        // Set the target position to a point slightly above the initial camera position
        const forwardDirection = new THREE.Vector3(0, -1, -6); // Adjust this if necessary for the desired forward direction
        const forwardPosition = initialTargetPositionRef.current.clone().add(forwardDirection);
        controlsRef.current.target.copy(forwardPosition);

        // Reset camera position to the initial state
        cameraRef.current.position.copy(initialTargetPositionRef.current);

        // Reset camera rotation
        cameraRef.current.rotation.set(0, 0, 0);

        controlsRef.current.update();

        setShowButtons(true);

      }
    }

    // Update the state value directly with the new state
    setPerspectiveState(state);
  };

  return (
    <>
      <div className="live-switch-overlay">
        <div className="toggle-container">
          <button
            className={`toggleButton ${mode === "offline" ? "active offline" : ""}`}
            onClick={() => handleLiveStatusChange({ detail: { value: "offline" } } as CustomEvent<any>)}
          >
            Offline
          </button>
          <button
            className={`toggleButton ${mode === "live" ? "active live" : ""}`}
            onClick={() => handleLiveStatusChange({ detail: { value: "live" } } as CustomEvent<any>)}
          >
            Live
          </button>
        </div>
      </div>
      <div className="perspective-toggle-overlay">

        <div className="toggle-container">
          <button
            className={`perspective-toggleButton ${perspectiveState === "orbit" ? "active orbit" : ""}`}
            onClick={() => handlePerspectiveStateChange("orbit")}
          >
            <IonIcon icon={expandOutline}></IonIcon>
          </button>
          <button
            className={`perspective-toggleButton ${perspectiveState === "firstPerson" ? "active firstPerson" : ""}`}
            onClick={() => handlePerspectiveStateChange("firstPerson")}
          >
            <IonIcon icon={eyeOutline}></IonIcon>
          </button>
        </div>
      </div>
      <div className={`overlay vertical-movement-controls ${showButtons ? 'active' : ''}`}>
        <div
          className={`orientation-indicator ${showButtons ? 'active' : ''}`}
        >
        
          <>
            <div className="top-buttons">
              <IonButton
                onPointerDown={() => handleButtonPress(directionMap.e, 'e')}
                onPointerUp={() => handleButtonRelease('e')}
                className="circle-button"
                size="small"
              >
                <IonIcon icon={chevronUp}></IonIcon>
              </IonButton>
            </div>
            <div className="bottom-buttons">
              <IonButton
                onPointerDown={() => handleButtonPress(directionMap.q, 'q')}
                onPointerUp={() => handleButtonRelease('q')}
                className="circle-button"
                size="small"
              >
                <IonIcon icon={chevronDown}></IonIcon>
              </IonButton>
            </div>
          </>
        
        </div>
      </div>
      <div className={`overlay movement-controls ${showButtons ? 'active' : ''}`}>
        <div
          className={`orientation-indicator ${showButtons ? 'active' : ''}`}
        >
          {capitalizeFirstLetter(cameraOrientation)}
        </div>
      {showButtons && (
        <>
          <div className="top-buttons">
            <IonButton
              onPointerDown={handleMoveForwardPress}
              onPointerUp={handleMoveForwardRelease}
              className="circle-button"
              size="small"
            >
              <IonIcon icon={chevronUp}></IonIcon>
            </IonButton>
          </div>
          <div className="left-buttons">
            <IonButton
              onPointerDown={handleMoveLeftPress}
              onPointerUp={handleMoveLeftRelease}
              className="circle-button"
              size="small"
            >
              <IonIcon icon={chevronBack}></IonIcon>
            </IonButton>
          </div>
        </>
      )}
      <div className={`main-button`}>
        {/* <IonIcon icon={showButtons ? add : moveOutline}></IonIcon> */}
      </div>
      {showButtons && (
        <>
          <div className="bottom-buttons">
            <IonButton
              onPointerDown={handleMoveBackwardPress}
              onPointerUp={handleMoveBackwardRelease}
              className="circle-button"
              size="small"
            >
              <IonIcon icon={chevronDown}></IonIcon>
            </IonButton>
          </div>
          <div className="right-buttons">
            <IonButton
              onPointerDown={handleMoveRightPress}
              onPointerUp={handleMoveRightRelease}
              className="circle-button"
              size="small"
            >
              <IonIcon icon={chevronForward}></IonIcon>
            </IonButton>
          </div>
        </>
      )}

      </div>

      {isLoading && (
        <>
          <div className="editor-loading-overlay">
            <div className="editor-loading-container">
                <div className="loadingSpinner ion-text-center">
                  <IonSpinner name="circular" color="primary" />
                </div>
            </div>
          </div>
        </>
      )}

      <canvas ref={canvasRef} width={width} height={height} />

      <IonToast
        isOpen={showToast} // show toast when state is true
        message="Object cannot be placed here."
        duration={1500}
        onDidDismiss={() => setShowToast(false)} // reset state when toast is dismissed
        icon={banOutline}
        position='top'
        color='medium'
        class="auto-width-toast"
      />

    </>
  );

};

// Wrap the Scene component with React.memo
const MemoizedScene = React.memo(EditorCanvas);

export default EditorCanvas;