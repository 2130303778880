// React Components
import React, { useState, useRef, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { useParams, Link, useLocation } from 'react-router-dom';

import {
  IonPage,
  IonContent,
  IonText,
  IonItem,
  IonCard,
  IonSpinner,
} from '@ionic/react';
import {
  //
} from 'ionicons/icons';

// Firebase Services
import firebase from 'firebase/compat/app';
import { auth } from '../../services/firebaseAuth';
import {
  getFirestore,
  collection,
  query,
  orderBy,
  limit,
  onSnapshot,
} from 'firebase/firestore';

// Libraries
//import * as linkify from 'linkifyjs';
//import linkifyHtml from 'linkify-html';

// Custom Utilities & Services
import { getCachedUser, cacheUser } from '../../services/userCache';
import UserAvatar from '../../components/UserAvatar';
import {
  getFormattedCreatedAt,
  getContrastingTextColor,
  isValidUrl,
  getWebsiteDomain,
  isSingleEmoji,
  getBaseDomain,
  convertUsernameMentionsToLinks,
  shortenNumber,
} from '../../utils/UtilityFunctions';
import SharePopover from '../../components/SharePopover';

// Custom Components and Style Sheets
import HeaderToolbar from '../../components/HeaderToolbar';
import SideMenu from '../../components/SideMenu';
import './DiscoverScreen.css';

type User = {
  username: string;
  displayName: string;
  followers: number;
  profileColor: string;
  initials: string;
  pronouns: string;
  bio: string;
};

type Props = RouteComponentProps;

const DiscoverScreen: React.FC<Props> = ({ history }) => {
  
  const db = getFirestore();

  const [topUsers, setTopUsers] = useState<User[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchTopUsers = async () => {

      setIsLoading(true);

      const usersRef = collection(db, 'users');
      const usersQuery = query(usersRef, orderBy('followers', 'desc'), limit(10));
      const unsubscribe = onSnapshot(usersQuery, (snapshot) => {
        const usersData = snapshot.docs.map((doc) => doc.data() as User);
        setTopUsers(usersData);
      });

      setIsLoading(false);

      return () => unsubscribe();
    };

    fetchTopUsers();
  }, [db]);

  return (
    <>
      <SideMenu />
      <IonPage id="main-content">
        <HeaderToolbar />
        <IonContent>
          <div className="limitedWidth discover-screen">

            {isLoading && (
              <div className="loadingSpinner ion-text-center">
                <IonSpinner name="circular" color="primary" />
              </div>
            )}

            <div className="top-users">
              <IonText>
                <h2>
                  Discover accounts to follow
                </h2>
              </IonText>
              {topUsers.map((user) => (
                <IonItem
                  key={user.username}
                  routerLink={`/${user.username}`}
                  className="top-user"
                  detail={true}
                  lines="none"
                >
                  <IonCard className="postItem">

                    <div className="profileContainer">
                      <div className="profileHeader">
                        <UserAvatar profileColor={user.profileColor} initials={user.initials} />
                        <div className="profileText">
                          <div>
                            <h1>
                              {user.displayName}{' '}
                              {user.pronouns !== "prefer_not_to_say" && (
                                <IonText color="medium" style={{ fontSize: "16px" }}>
                                  {user.pronouns}
                                </IonText>
                              )}
                            </h1>
                            <div className="username">
                              <span>
                                <IonText color="medium">@{user.username}</IonText>
                              </span>
                            </div>
                            <div className="bio">
                              <span>{user.bio}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </IonCard>
                </IonItem>
              ))}
            </div>
          </div>

        </IonContent>
      </IonPage>
    </>
      
  );
};

export default withRouter(DiscoverScreen);
