// React Components
import React, { useState, useRef, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { useParams, Link, useLocation } from 'react-router-dom';

import {
  IonPage,
  IonContent,
  IonItem,
  IonCard,
  IonCardContent,
  IonText,
  IonRouterLink,
  IonButton,
  IonIcon,
  IonPopover,
  IonList,
  IonLabel,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonToast,
  IonSpinner
} from '@ionic/react';
import {
  ellipsisHorizontal,
  heart,
  heartOutline,
  chatbubbleOutline,
  shareOutline,
  personAddOutline,
  personRemoveOutline,
} from 'ionicons/icons';

// Firebase Services
import firebase from 'firebase/compat/app';
import { auth } from '../../services/firebaseAuth';
import {
  getFirestore,
  DocumentSnapshot,
  DocumentData,
  getDoc,
  getDocs,
  query,
  collection,
  where,
  doc,
  limit,
  orderBy,
  startAfter,
  writeBatch,
  increment,
  addDoc,
} from 'firebase/firestore';

// Libraries
import * as linkify from 'linkifyjs';
import linkifyHtml from 'linkify-html';

// Custom Utilities & Services
import { getCachedUser, cacheUser } from '../../services/userCache';
import UserAvatar from '../../components/UserAvatar';
import {
  getFormattedCreatedAt,
  getContrastingTextColor,
  isValidUrl,
  getWebsiteDomain,
  isSingleEmoji,
  getBaseDomain,
  convertUsernameMentionsToLinks,
  shortenNumber,
} from '../../utils/UtilityFunctions';
import SharePopover from '../../components/SharePopover';

// Custom Components and Style Sheets
import HeaderToolbar from '../../components/HeaderToolbar';
import SideMenu from '../../components/SideMenu';
import './HomeScreen.css';

interface Post {
  id: string;
  rootID: string;
  parentID: string;
  userID: string;
  title: string;
  text: string;
  image: string;
  blueprint: any;
  timestamp: any;
  likeCount: number;
  replyCount: number;
  likes: Like[];
  user?: User;
  isFollowed: boolean;
  likedByCurrentUser: boolean; // Add the likedByCurrentUser property
}

type Like = {
  userID: string;
  postID: string;
  timestamp: any;
};

type User = {
  displayName: string;
  username: string;
  initials: string;
  profileColor: string;
};

const HomeScreen: React.FC = () => {
  const db = getFirestore();
  const currentUserID = auth.currentUser?.uid;

  const [posts, setPosts] = useState<Post[]>([]);
  const [lastDocument, setLastDocument] = useState<DocumentSnapshot<DocumentData> | null>(null);
  const [hasMorePosts, setHasMorePosts] = useState(true);
  const [isInfiniteScrollEnabled, setIsInfiniteScrollEnabled] = useState<boolean>(true);
  const [pageSize, setPageSize] = useState<number>(5);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [areUserPostsLoaded, setAreUserPostsLoaded] = useState<boolean>(false);
  const [areAllPostsLoaded, setAreAllPostsLoaded] = useState<boolean>(false);
  const [isFetchingUserInformation, setIsFetchingUserInformation] = useState<boolean>(false);

  const [followedUserIDs, setFollowedUserIDs] = useState<string[]>([]);
  const [deleteAlert, setDeleteAlert] = useState<string | undefined>(undefined);
  const [postToDelete, setPostToDelete] = useState<Comment | null>(null);

  const copyConfirmationToastRef = useRef<HTMLIonToastElement>(null);
  const deletePostConfirmationToastRef = useRef<HTMLIonToastElement>(null);
  const followConfirmationToastRef = useRef<HTMLIonToastElement>(null);
  const unfollowConfirmationToastRef = useRef<HTMLIonToastElement>(null);

  const [showSignInModal, setShowSignInModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!currentUserID) {
          // User is not signed in, return early
          return;
        }

        setIsLoading(true);

        // Fetch the users the signed-in user is following
        const followsQuerySnapshot = await getDocs(
          query(collection(db, 'follows'), where('followerID', '==', currentUserID))
        );

        // Get an array of following user IDs
        const followingUserIDs = followsQuerySnapshot.docs.map((doc) => doc.data().followingID);

        // Fetch posts from the following users
        const userPostsQuery = query(
          collection(db, 'posts'),
          where('userID', 'in', followingUserIDs),
          where('parentID', '==', ''), // Filter out posts with non-empty parentID
          orderBy('timestamp', 'desc'),
          limit(pageSize)
        );

        const userPostsQuerySnapshot = await getDocs(userPostsQuery);

        const posts = await Promise.all(
          userPostsQuerySnapshot.docs.map(async (doc) => {
            const postData = doc.data();
            const postID = doc.id;

            return {
              id: postID,
              rootID: postData.rootID,
              parentID: postData.parentID,
              userID: postData.userID,
              title: postData.title,
              text: postData.text,
              image: postData.image,
              blueprint: postData.blueprint,
              timestamp: postData.timestamp,
              likeCount: postData.likeCount,
              replyCount: postData.replyCount,
            };
          })
        );

        const likedByCurrentUserQuerySnapshot = await getDocs(
          query(
            collection(db, 'likes'),
            where('userID', '==', currentUserID),
            where('postID', 'in', posts.map((post) => post.id))
          )
        );

        const likedPostIDs = likedByCurrentUserQuerySnapshot.docs.map((doc) => doc.data().postID);

        const followsQuery = query(
          collection(db, 'follows'),
          where('followerID', '==', currentUserID),
          where('followingID', 'in', posts.map((post) => post.userID))
        );
        const followsSnapshot = await getDocs(followsQuery);
        const followedUserIDs = followsSnapshot.docs.map((doc) => doc.data().followingID);
        setFollowedUserIDs(followedUserIDs);

        const updatedPosts = posts.map((post) => ({
          ...post,
          likedByCurrentUser: likedPostIDs.includes(post.id),
          isFollowed: followedUserIDs.includes(post.userID),
        }));

        setPosts(updatedPosts.map((post) => ({ ...post, likes: [] })));

        setAreUserPostsLoaded(true);
      } catch (error) {
        //console.log('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

  }, [currentUserID]);

  useEffect(() => {
    const fetchUserInformation = async () => {
      setIsFetchingUserInformation(true);
      const usersRef = collection(db, 'users');

      const fetchUserRecursive = async (post: Post): Promise<Post> => {
        if (post.userID === 'Deleted') {
          // Set default values for deleted users
          const userData: User = {
            displayName: 'Deleted',
            username: 'deleted',
            initials: '',
            profileColor: '#7a93a0',
          };

          return {
            ...post,
            user: userData,
          };
        }

        const userDocRef = doc(usersRef, post.userID);
        const userDoc = await getDoc(userDocRef);

        if (!userDoc.exists()) {
          // Handle the case where the user document doesn't exist (e.g., deleted user)
          const userData: User = {
            displayName: 'Deleted',
            username: 'deleted',
            initials: '',
            profileColor: '#888888',
          };

          return {
            ...post,
            user: userData,
          };
        }

        const userData = userDoc.data() as User;
        const postWithUser: Post = {
          ...post,
          user: userData,
        };
        return postWithUser;
      };

      const postsWithUserInformation = await Promise.all(posts.map(fetchUserRecursive));

      setPosts(postsWithUserInformation);
      setIsFetchingUserInformation(false);
    };

    if (posts.length > 0 && !isFetchingUserInformation) {
      fetchUserInformation();
    }
  }, [posts]);

  const loadMorePosts = async () => {
    try {
      if (!currentUserID || areAllPostsLoaded) {
        return;
      }

      const lastPost = posts[posts.length - 1];
      const startAfterTimestamp = lastPost.timestamp;

      const followsQuerySnapshot = await getDocs(
        query(collection(db, 'follows'), where('followerID', '==', currentUserID))
      );
      const followingIDs = followsQuerySnapshot.docs.map((doc) => doc.data().followingID);
      const followedUserIDs = followsQuerySnapshot.docs.map((doc) => doc.data().followingID);

      let userPostsQuery = query(
        collection(db, 'posts'),
        where('userID', 'in', followingIDs),
        where('parentID', '==', ''),
        orderBy('timestamp', 'desc'),
        startAfter(startAfterTimestamp),
        limit(pageSize)
      );

      const userPostsQuerySnapshot = await getDocs(userPostsQuery);

      if (!userPostsQuerySnapshot.empty) {
        const newPosts = await Promise.all(
          userPostsQuerySnapshot.docs.map(async (doc) => {
            const postData = doc.data();

            const likedByCurrentUserQuerySnapshot = await getDocs(
              query(collection(db, 'likes'), where('userID', '==', currentUserID), where('postID', '==', doc.id))
            );
            const likedByCurrentUser = !likedByCurrentUserQuerySnapshot.empty;

            return {
              id: doc.id,
              rootID: postData.rootID,
              parentID: postData.parentID,
              userID: postData.userID,
              title: postData.title,
              text: postData.text,
              image: postData.image,
              blueprint: postData.blueprint,
              timestamp: postData.timestamp,
              likeCount: postData.likeCount,
              replyCount: postData.replyCount,
              likedByCurrentUser: likedByCurrentUser,
              isFollowed: followedUserIDs.includes(postData.userID), // Update the isFollowed property
            };
          })
        );

        setPosts((prevPosts) => [...prevPosts, ...newPosts.map((post) => ({ ...post, likes: [] }))]);

      } else {
        setAreAllPostsLoaded(true);
      }
    } catch (error) {
      // Handle the error
    }
  };

  const toggleLike = async (postId: string) => {
    if (!currentUserID) {
      return; // Exit the function if currentUserID is undefined
    }

    const likeCollectionRef = collection(db, 'likes');
    const likeQuerySnapshot = await getDocs(likeCollectionRef);
    const likeDocs = likeQuerySnapshot.docs;
    const currentUserLikeDoc = likeDocs.find(
      (doc) => doc.data().userID === currentUserID && doc.data().postID === postId
    );
    const isLiked = !!currentUserLikeDoc;

    const postRef = doc(collection(db, 'posts'), postId);
    const postDoc = await getDoc(postRef);
    const post = postDoc.data() as Post;

    const batch = writeBatch(db);

    if (isLiked) {
      batch.delete(currentUserLikeDoc.ref);
      batch.update(postRef, { likeCount: post.likeCount - 1 });
    } else {
      const newLike: Like = {
        userID: currentUserID,
        postID: postId,
        timestamp: new Date(),
      };
      const newLikeRef = doc(collection(db, 'likes'));
      batch.set(newLikeRef, newLike);
      batch.update(postRef, { likeCount: post.likeCount + 1 });
    }

    await batch.commit();

    setPosts((prevPosts) => {
      const updatedPosts = prevPosts.map((post) => {
        if (post.id === postId) {
          return {
            ...post,
            likeCount: isLiked ? post.likeCount - 1 : post.likeCount + 1,
            likedByCurrentUser: !isLiked,
            likes: isLiked
              ? post.likes.filter((like) => like.userID !== currentUserID)
              : [...post.likes, { userID: currentUserID, postID: postId, timestamp: new Date() }],
          };
        }
        return post;
      });
      return updatedPosts;
    });
  };

  const toggleFollow = async (userID: string, username: string) => {
    if (!currentUserID) {
      return; // Exit the function if currentUserID is undefined
    }

    const followsCollectionRef = collection(db, 'follows');
    const followQuerySnapshot = await getDocs(followsCollectionRef);
    const followDocs = followQuerySnapshot.docs;
    const currentUserFollowDoc = followDocs.find(
      (doc) => doc.data().followerID === currentUserID && doc.data().followingID === userID
    );
    const isFollowed = !!currentUserFollowDoc;

    const batch = writeBatch(db);

    if (isFollowed) {
      // Decrease the 'following' count for the signed-in user
      const currentUserRef = doc(collection(db, 'users'), currentUserID);
      batch.update(currentUserRef, {
        following: increment(-1),
      });

      // Decrease the 'followers' count for the user being unfollowed
      const userRef = doc(collection(db, 'users'), userID);
      batch.update(userRef, {
        followers: increment(-1),
      });

      batch.delete(currentUserFollowDoc.ref);
      unfollowConfirmationToastRef.current?.present();
    } else {
      // Increase the 'following' count for the signed-in user
      const currentUserRef = doc(collection(db, 'users'), currentUserID);
      batch.update(currentUserRef, {
        following: increment(1),
      });

      // Increase the 'followers' count for the user being followed
      const userRef = doc(collection(db, 'users'), userID);
      batch.update(userRef, {
        followers: increment(1),
        notifications: increment(1),
      });

      const newFollow = {
        followerID: currentUserID,
        followingID: userID,
        timestamp: new Date(),
      };
      const newFollowRef = doc(followsCollectionRef);
      batch.set(newFollowRef, newFollow);
      followConfirmationToastRef.current?.present();

      // Add a document to the 'notifications' collection
      const notificationsCollectionRef = collection(db, 'notifications');

      // Check if an existing notification document already exists
      const existingNotificationQuerySnapshot = await getDocs(
        query(
          notificationsCollectionRef,
          where('userID', '==', userID),
          where('followerID', '==', currentUserID)
        )
      );
      const existingNotificationDocs = existingNotificationQuerySnapshot.docs;

      if (existingNotificationDocs.length === 0) {
        const newNotification = {
          userID: userID,
          timestamp: new Date(),
          type: 'follow',
          followerID: currentUserID,
          unread: true,
        };
        const newNotificationRef = await addDoc(notificationsCollectionRef, newNotification);
      }
    }

    await batch.commit();

    setPosts((prevPosts) => {
      const updatedPosts = prevPosts.map((post) => {
        if (post.user && post.user.username === username) {
          return {
            ...post,
            isFollowed: !isFollowed,
          };
        }
        return post;
      });
      return updatedPosts;
    });
  };


  return (
    <>
      <SideMenu />
      <IonPage id="main-content">
        <HeaderToolbar />
        <IonContent>
          <div className="limitedWidth home-screen">
            {isLoading && (
              <div className="loadingSpinner ion-text-center">
                <IonSpinner name="circular" color="primary" />
              </div>
            )}

            {!isLoading && posts.length === 0 ? (
              <div className="no-posts">
                <img src="assets/images/empty-feed.svg" alt="illustration of an empty box with magical stars and particles floating above" height="200px" width="400px" />
                <p><b>Your feed is empty</b><br /><span><a href="/discover">Find others</a> to follow to add to your feed</span></p>
              </div>
            ) : (

            posts.map((post) => {
              // Convert URLs and @username mentions to clickable links with target="_blank"
              const linkifiedText = linkifyHtml(post.text, {
                defaultProtocol: "https",
                formatHref: function (href, type) {
                  if (type === "email") {
                    return `mailto:${href}`;
                  }
                  return href;
                },
                attributes: {
                  onclick: "event.stopPropagation();",
                  target: "_blank", // Open links in new tabs
                },
                className: {
                  url: "link",
                  email: "email-link",
                  hashtag: "hashtag-link",
                  mention: "mention-link", // CSS class for @username mentions
                },
              });

              // Truncate the post text if it exceeds a certain length
              const truncatedText = linkifiedText.length > 350 ? `${linkifiedText.slice(0, 350)}...` : linkifiedText;

              // Calculate the number of lines in the post text
              const lines = truncatedText.split("<br>").length;
              const shouldShowMore = lines > 5 || truncatedText.length > 350;

              return (
                <>
                  <a className="post-item-link" href={`/${post.user?.username || 'deleted'}/post/${post.id}`}>
                    <IonItem
                      button
                      //routerLink={`/${post.user?.username || 'deleted'}/post/${post.id}`}
                      //key={post.id}
                      detail={false}
                      lines="none"
                      className={` feed-post ${post.userID === 'Deleted' ? 'deleted-post' : ''}`}
                    >
                      <IonCard className="postItem">
                        <IonCardContent className="singlePostContent">
                          <div className="postCredits">
                            <UserAvatar
                              profileColor={post.user?.profileColor}
                              initials={post.user?.initials}
                            />
                            <div className="postText">
                              <p>
                                <IonText style={{ fontWeight: "bold" }}>
                                  {post.user?.displayName}
                                </IonText>{" "}
                                <IonText color="medium">
                                  <a
                                    href={`/${post.user?.username}`}
                                    style={{ color: "inherit", textDecoration: "none" }}
                                    className="hoverUnderline"
                                    onClick={(event) => {
                                      if (event.button === 1) { // Middle click
                                        event.preventDefault();
                                        event.stopPropagation();
                                        window.open(`/${post.user?.username || 'deleted'}`, '_blank');
                                      } else {
                                        event.stopPropagation();
                                      }
                                    }}
                                  >
                                    @{post.user?.username}
                                  </a>
                                </IonText>
                              </p>
                              <p>
                                <a
                                  href={`/${post.user?.username || 'deleted'}/post/${post.id}`}
                                  style={{ color: "inherit", textDecoration: "none" }}
                                  className="hoverUnderline"
                                  onClick={(event) => {
                                    if (event.button === 1) {
                                      // Middle click
                                      event.preventDefault();
                                      event.stopPropagation();
                                      window.open(`/${post.user?.username || 'deleted'}/post/${post.id}`, '_blank');
                                    } else {
                                      event.stopPropagation();
                                    }
                                  }}
                                >
                                  {getFormattedCreatedAt(post.timestamp, "justNow")}
                                </a>
                              </p>
                            </div>
                            <div className="ellipsisButtonContainer">
                              <IonButton
                                fill="clear"
                                size="small"
                                className="ellipsisButton"
                                id={`option-popover-${post.id}`}
                                onClick={(event) => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                }}
                              >
                                <IonIcon icon={ellipsisHorizontal} color="medium" />
                              </IonButton>
                            </div>
                          </div>
                         {post.title &&
                           <div className="postTitle">
                              <h2>
                                {post.title}
                              </h2>
                            </div>
                          }
                          {post.image &&
                            <>
                              <div className="postImage">
                                <img src={post.image} alt="Build Image" />
                                {post.blueprint &&
                                <div className="overlayLabel">View in 3D</div>
                              }
                              </div>
                            </>
                          }
                          <div className="postMessage">
                            <p dangerouslySetInnerHTML={{ __html: truncatedText }}></p>
                            {shouldShowMore && <p className="showMore">Show more</p>}
                          </div>
                          <div className="postDetails">
                            <div className="buttonsLeft">
                              <div className="buttonContainer">
                                <IonButton
                                  fill="clear"
                                  id={`likeButton_${post.id}`}
                                  className={`${post.likedByCurrentUser ? 'liked' : ''}`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    toggleLike(post.id)
                                  }}
                                >
                                  <IonIcon
                                    slot="icon-only"
                                    icon={post.likedByCurrentUser ? heart : heartOutline}
                                    color={post.likedByCurrentUser ? 'danger' : 'medium'}
                                  />
                                </IonButton>
                                <span>{shortenNumber(post.likeCount)}</span>
                              </div>
                              <div className="buttonContainer">
                                <IonButton
                                  fill="clear"
                                >
                                  <IonIcon slot="icon-only" icon={chatbubbleOutline} color="medium" />
                                </IonButton>
                                <span>{shortenNumber(post.replyCount)}</span>
                              </div>
                            </div>
                            <div className="buttonsRight">
                              <div className="buttonContainer">
                                <IonButton
                                  fill="clear"
                                  id={`share-popover-${post.id}`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                  }}
                                >
                                  <IonIcon slot="icon-only" icon={shareOutline} color="medium" />
                                </IonButton>
                              </div>
                            </div>
                            
                            <IonPopover class="ion-padding" className="postDeletePopover" trigger={`option-popover-${post.id}`} dismissOnSelect={true}>
                              <IonContent>
                                <IonList lines="none">
                                  {post.userID !== currentUserID && (
                                    <IonItem
                                      button={true}
                                      detail={false}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        toggleFollow(post.userID, post.user?.username || '');
                                      }}
                                    >
                                      <IonIcon
                                        slot="start"
                                        icon={post.isFollowed ? personRemoveOutline : personAddOutline}
                                        color="primary"
                                      />
                                      <IonLabel>
                                        {post.isFollowed ? 'Unfollow' : 'Follow'} @{post.user?.username}
                                      </IonLabel>
                                    </IonItem>
                                  )}
                                </IonList>
                              </IonContent>
                            </IonPopover>
                            <SharePopover postID={post.id} username={post.user?.username ?? ''} copyConfirmationToastRef={copyConfirmationToastRef} />
                          </div>
                        </IonCardContent>
                      </IonCard>
                    </IonItem>
                  </a>
                </>
              );
            })
          )}
            <IonInfiniteScroll
              threshold="100px"
              disabled={!isInfiniteScrollEnabled || areAllPostsLoaded}
              onIonInfinite={async () => {
                await loadMorePosts(); // Wait for loadMorePosts to complete
                const infiniteScrollElement = document.querySelector('ion-infinite-scroll');
                if (infiniteScrollElement) {
                  infiniteScrollElement.complete();
                }
              }}
            >
              <IonInfiniteScrollContent loadingSpinner="circular"></IonInfiniteScrollContent>
            </IonInfiniteScroll>
          </div>
        </IonContent>
        <IonToast
          ref={copyConfirmationToastRef}
          message="Link copied to clipboard!"
          color="medium"
          position="top"
          duration={3000}
          class="auto-width-toast"
        />
        <IonToast
          ref={followConfirmationToastRef}
          message="Now following!"
          color="medium"
          position="top"
          duration={3000}
          class="auto-width-toast"
        />
        <IonToast
          ref={unfollowConfirmationToastRef}
          message="No longer following."
          color="medium"
          position="top"
          duration={3000}
          class="auto-width-toast"
        />
      </IonPage>
    </>
  );
};

export default HomeScreen;
