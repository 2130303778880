// React Components
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { useParams, Link, useLocation } from 'react-router-dom';
import {
  IonPage,
  IonContent,
  IonSpinner,
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonText,
  IonList,
  IonItem,
  IonInput,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonIcon,
  IonCard,
  IonCardContent,
  IonSegment,
  IonSegmentButton,
  IonToast,
  IonAlert,
  IonPopover,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRouterLink,
  IonChip
} from '@ionic/react';
import {
  personCircleOutline,
  informationCircleOutline,
  calendarOutline,
  shareSocialOutline,
  linkOutline,
  chatbubbleOutline,
  timeOutline,
  heart,
  heartOutline,
  addCircleOutline,
  repeatOutline,
  shareOutline,
  copyOutline,
  bookmarkOutline,
  ellipsisVertical,
  ellipsisHorizontal,
  trashOutline,
  personAddOutline, 
  personRemoveOutline,
  happy,
  happyOutline,
  chatbubblesOutline,
  bulbOutline,
  cafeOutline,
  trailSignOutline
} from 'ionicons/icons';

// Firebase Services
import firebase from 'firebase/compat/app';
import { auth } from '../../services/firebaseAuth';
import {
  getFirestore,
  DocumentSnapshot,
  onSnapshot,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  addDoc,
  deleteDoc,
  query,
  collection,
  where,
  limit,
  orderBy,
  startAfter,
  FieldValue,
  writeBatch,
  increment,
  DocumentData,
} from 'firebase/firestore';

// Libraries
import * as linkify from 'linkifyjs';
import linkifyHtml from 'linkify-html';

// Custom Utilities & Services
import { getCachedUser, cacheUser } from "../../services/userCache";
import UserAvatar from "../../components/UserAvatar";
import {
  getFormattedCreatedAt,
  getContrastingTextColor,
  isValidUrl,
  getWebsiteDomain,
  isSingleEmoji,
  getBaseDomain,
  convertUsernameMentionsToLinks,
  shortenNumber,
  getFormattedLastUpdated
} from '../../utils/UtilityFunctions';
import SharePopover from "../../components/SharePopover";

import SignInModal from '../../components/SignInModal';

// Header and CSS
import HeaderToolbar from '../../components/HeaderToolbar';
import './ProfileScreen.css';

interface Post {
  id: string;
  rootID: string;
  parentID: string;
  userID: string;
  title: string;
  text: string;
  image: string;
  blueprint: any;
  timestamp: any;
  likeCount: number;
  replyCount: number;
  likedByCurrentUser: boolean;
}

type Props = RouteComponentProps<{username: string}>;

const ProfileScreen: React.FC<Props> = ({ match }) => {

  const location = useLocation();
  const currentURL = location.pathname;

  const [userProfile, setUserProfile] = useState<any>(null);
  const [userProfileId, setUserProfileId] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isCurrentUser, setIsCurrentUser] = useState<boolean>(false);
  const [isFollowing, setIsFollowing] = useState<boolean>(false);

  const [signedInUserId, setSignedInUserId] = useState<string | null>(null);
  const [signedInUserUsername, setSignedInUserUsername] = useState<string | null>(null);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const copyConfirmationToastRef = useRef<HTMLIonToastElement>(null);

  const [showSignInModal, setShowSignInModal] = useState(false);

  const [isInfiniteScrollEnabled, setIsInfiniteScrollEnabled] = useState<boolean>(true);
  const [isInfiniteScrollComplete, setIsInfiniteScrollComplete] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [lastDocument, setLastDocument] = useState<DocumentSnapshot<Post> | null>(null);

  const [userPosts, setUserPosts] = useState<Post[]>([]);
  const [areUserPostsLoaded, setAreUserPostsLoaded] = useState<boolean>(false);
  const [areAllPostsLoaded, setAreAllPostsLoaded] = useState<boolean>(false);

  const [selectedPostId, setSelectedPostId] = useState<string | null>(null);
  const [postToDelete, setPostToDelete] = useState<Post | null>(null);
  const [deleteAlert, setDeleteAlert] = useState<string | undefined>(undefined);
  const deletePostConfirmationToastRef = useRef<HTMLIonToastElement>(null);

  const [initialDisplayName, setInitialDisplayName] = useState<string>('');
  const [initialPronouns, setInitialPronouns] = useState<string>('');
  const [initialInitials, setInitialInitials] = useState<string>('');
  const [initialProfileColor, setInitialProfileColor] = useState<string>('');
  const [initialBio, setInitialBio] = useState<string>('');
  const [initialWebsite, setInitialWebsite] = useState<string>('');

  const [updatedDisplayName, setUpdatedDisplayName] = useState<string>('');
  const [updatedPronouns, setUpdatedPronouns] = useState<string>('');
  const [updatedInitials, setUpdatedInitials] = useState<string>('');
  const [updatedProfileColor, setUpdatedProfileColor] = useState<string>('');
  const [updatedBio, setUpdatedBio] = useState<string>('');
  const [updatedWebsite, setUpdatedWebsite] = useState<string>('');

  // Define memoized variables to cache profile data
  const userProfileMemo = useMemo(() => userProfile, [userProfile]);
  const userProfileIdMemo = useMemo(() => userProfileId, [userProfileId]);
  const userPostsMemo = useMemo(() => userPosts, [userPosts]);
  const areUserPostsLoadedMemo = useMemo(() => areUserPostsLoaded, [areUserPostsLoaded]);
  const areAllPostsLoadedMemo = useMemo(() => areAllPostsLoaded, [areAllPostsLoaded]);

  const db = getFirestore();

  useEffect(() => {
    const { username } = match.params;

    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        const { uid } = user;
        setSignedInUserId(uid);

        // Check if user data is available in cache
        const cachedUser = getCachedUser();
        if (cachedUser) {
          // Use cached user data for signedInUserRef
          const userData = cachedUser;

          setInitialDisplayName(userData.displayName || '');
          setInitialPronouns(userData.pronouns || '');
          setInitialInitials(userData.initials || '');
          setInitialProfileColor(userData.profileColor || '');
          setInitialBio(userData.bio || '');
          setInitialWebsite(userData.website || '');

          setSignedInUserUsername(userData.username);

          setUpdatedDisplayName(userData.displayName || '');
          setUpdatedPronouns(userData.pronouns || '');
          setUpdatedInitials(userData.initials || '');
          setUpdatedProfileColor(userData.profileColor || '');
          setUpdatedBio(userData.bio || '');
          setUpdatedWebsite(userData.website || '');
        } else {
          const signedInUserRef = doc(db, 'users', uid);

          onSnapshot(signedInUserRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
              const userData = docSnapshot.data();

              setInitialDisplayName(userData.displayName || '');
              setInitialPronouns(userData.pronouns || '');
              setInitialInitials(userData.initials || '');
              setInitialProfileColor(userData.profileColor || '');
              setInitialBio(userData.bio || '');
              setInitialWebsite(userData.website || '');

              setSignedInUserUsername(userData.username);

              setUpdatedDisplayName(userData.displayName || '');
              setUpdatedPronouns(userData.pronouns || '');
              setUpdatedInitials(userData.initials || '');
              setUpdatedProfileColor(userData.profileColor || '');
              setUpdatedBio(userData.bio || '');
              setUpdatedWebsite(userData.website || '');

              // Cache the user data
              cacheUser(userData);
            }
          });
        }
      }
    });

    const fetchData = async () => {
      try {
        // Fetch user data
        //console.log('Fetching user data...');
        const userQuerySnapshot = await getDocs(
          query(collection(db, 'users'), where('username', '==', username), limit(1))
        );
        if (!userQuerySnapshot.empty) {
          const userProfileDoc = userQuerySnapshot.docs[0];
          const userProfileId = userProfileDoc.id;
          const userProfileData = userProfileDoc.data();

          setUserProfile(userProfileData);
          setUserProfileId(userProfileId);
          //setSignedInUserId(userProfileId);

          const followsQuerySnapshot = await getDocs(
            query(
              collection(db, 'follows'),
              where('followerID', '==', signedInUserId),
              where('followingID', '==', userProfileId)
            )
          );

          setIsFollowing(!followsQuerySnapshot.empty);

          let userPostsQuery = query(
            collection(db, 'posts'),
            where('userID', '==', userProfileId),
            where('parentID', '==', ''), // Filter out posts with non-empty parentID
            orderBy('timestamp', 'desc'),
            limit(pageSize)
          );

          if (lastDocument) {
            const lastDocumentData = lastDocument.data();
            if (lastDocumentData) {
              const lastTimestamp = lastDocumentData.timestamp;
              userPostsQuery = query(
                collection(db, 'posts'),
                where('userID', '==', userProfileId),
                where('parentID', '==', ''), // Filter out posts with non-empty parentID
                orderBy('timestamp', 'desc'),
                startAfter(lastTimestamp),
                limit(pageSize)
              );
            }
          }

          const userPostsQuerySnapshot = await getDocs(userPostsQuery);

          const posts = await Promise.all(
            userPostsQuerySnapshot.docs.map(async (doc) => {
              const postData = doc.data();
              const postID = doc.id;

              return {
                id: postID,
                rootID: postData.rootID,
                parentID: postData.parentID,
                userID: postData.userID,
                title: postData.title,
                text: postData.text,
                image: postData.image,
                blueprint: postData.blueprint,
                timestamp: postData.timestamp,
                likeCount: postData.likeCount,
                replyCount: postData.replyCount,
              };
            })
          );

          const likedByCurrentUserQuerySnapshot = await getDocs(
            query(
              collection(db, 'likes'),
              where('userID', '==', signedInUserId),
              where('postID', 'in', posts.map(post => post.id))
            )
          );

          const likedPostIDs = likedByCurrentUserQuerySnapshot.docs.map(doc => doc.data().postID);

          const updatedPosts = posts.map(post => ({
            ...post,
            likedByCurrentUser: likedPostIDs.includes(post.id),
          }));

          setUserPosts(updatedPosts);
          setAreUserPostsLoaded(true);

        } else {
          setUserProfile(null);
          setUserPosts([]);
          setAreUserPostsLoaded(true);
        }
      } catch (error) {
        //console.log('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      unsubscribeAuth();
    };
  }, [match.params, signedInUserId]);

  const loadMorePosts = async () => {
    try {
      if (!signedInUserId || areAllPostsLoaded) {
        return;
      }

      const lastPost = userPosts[userPosts.length - 1];
      const startAfterTimestamp = lastPost.timestamp;

      let userPostsQuery = query(
        collection(db, 'posts'),
        where('userID', '==', userProfileId),
        where('parentID', '==', ''),
        orderBy('timestamp', 'desc'),
        startAfter(startAfterTimestamp),
        limit(pageSize)
      );

      const userPostsQuerySnapshot = await getDocs(userPostsQuery);

      if (!userPostsQuerySnapshot.empty) {
        const newPosts = await Promise.all(
          userPostsQuerySnapshot.docs.map(async (doc) => {
            const postData = doc.data();

            const likedByCurrentUserQuerySnapshot = await getDocs(
              query(collection(db, 'likes'), where('userID', '==', signedInUserId), where('postID', '==', doc.id))
            );
            const likedByCurrentUser = !likedByCurrentUserQuerySnapshot.empty;

            return {
              id: doc.id,
              rootID: postData.rootID,
              parentID: postData.parentID,
              userID: postData.userID,
              title: postData.title,
              text: postData.text,
              image: postData.image,
              blueprint: postData.blueprint,
              timestamp: postData.timestamp,
              likeCount: postData.likeCount,
              replyCount: postData.replyCount,
              likedByCurrentUser: likedByCurrentUser,
            };
          })
        );

        setUserPosts((prevPosts) => [...prevPosts, ...newPosts]);
      } else {
        //console.log('All posts have been loaded');
        setAreAllPostsLoaded(true);
      }
    } catch (error) {
      //console.error('Error loading more posts:', error);
    }
  };

  const toggleFollow = async () => {
    try {
      if (isFollowing) {
        // User is already following, so unfollow
        const followsQuerySnapshot = await getDocs(
          query(
            collection(db, 'follows'),
            where('followingID', '==', userProfileId),
            where('followerID', '==', signedInUserId)
          )
        );
        if (!followsQuerySnapshot.empty) {
          const followDoc = followsQuerySnapshot.docs[0];

          const batch = writeBatch(db);

          // Decrease the 'following' count for the signed-in user
          const usersCollectionRef = collection(db, 'users');
          const currentUserRef = doc<DocumentData>(usersCollectionRef, signedInUserId?.toString());
          batch.update(currentUserRef, {
            following: increment(-1),
          });

          // Decrease the 'followers' count for the user being unfollowed
          const userRef = doc(collection(db, 'users'), userProfileId);
          batch.update(userRef, {
            followers: increment(-1),
          });

          batch.delete(followDoc.ref);
          await batch.commit();

          setIsFollowing(false);
        }
      } else {
        // User is not following, so follow
        const followsCollectionRef = collection(db, 'follows');

        // Check if an existing follow document already exists
        const existingFollowQuerySnapshot = await getDocs(
          query(
            followsCollectionRef,
            where('followingID', '==', userProfileId),
            where('followerID', '==', signedInUserId)
          )
        );
        const existingFollowDocs = existingFollowQuerySnapshot.docs;

        if (existingFollowDocs.length === 0) {
          const newFollowDocRef = await addDoc(followsCollectionRef, {
            followerID: signedInUserId,
            followingID: userProfileId,
            timestamp: new Date(),
          });

          const batch = writeBatch(db);

          // Increase the 'following' count for the signed-in user
          const usersCollectionRef = collection(db, 'users');
          const currentUserRef = doc<DocumentData>(usersCollectionRef, signedInUserId?.toString());
          batch.update(currentUserRef, {
            following: increment(1),
          });

          // Increase the 'followers' count for the user being followed
          const userRef = doc(collection(db, 'users'), userProfileId);
          batch.update(userRef, {
            followers: increment(1),
            notifications: increment(1),
          });

          // Add a new notification document
          const notificationsCollectionRef = collection(db, 'notifications');
          const newNotification = {
            userID: userProfileId,
            timestamp: new Date(),
            type: 'follow',
            followerID: signedInUserId,
            unread: true,
          };
          const newNotificationRef = await addDoc(notificationsCollectionRef, newNotification);
          console.log('New notification added with ID: ', newNotificationRef.id);

          await batch.commit();

          setIsFollowing(true);
        }
      }
    } catch (error) {
      // ...
    }
  };


  const toggleLike = async (postId: string) => {
    try {
      const postRef = doc(db, 'posts', postId);
      const likesRef = collection(db, 'likes');
      const likeQuery = query(
        likesRef,
        where('postID', '==', postId),
        where('userID', '==', signedInUserId)
      );

      const likeSnapshot = await getDocs(likeQuery);
      const postSnapshot = await getDoc(postRef);

      const postDoc = postSnapshot.data() as Post;
      const likeDoc = likeSnapshot.docs[0];

      const batch = writeBatch(db);

      if (likeDoc) {
        // User unlikes the post
        batch.delete(likeDoc.ref);
        batch.update(postRef, {
          likedByCurrentUser: false,
          likeCount: postDoc.likeCount - 1,
        });

        // Update UI immediately
        postDoc.likedByCurrentUser = false;
        postDoc.likeCount -= 1;
      } else {
        // User likes the post
        const newLikeDocRef = await addDoc(likesRef, {
          userID: signedInUserId,
          timestamp: new Date(),
          postID: postId,
        });

        batch.update(postRef, {
          likedByCurrentUser: true,
          likeCount: postDoc.likeCount + 1,
        });

        // Update UI immediately
        postDoc.likedByCurrentUser = true;
        postDoc.likeCount += 1;
      }

      await batch.commit();

      // Update UI immediately
      const likeButton = document.getElementById(`likeButton_${postId}`);
      const likeCountLabel = document.getElementById(`likeCount_${postId}`);

      if (likeButton && likeCountLabel) {
        if (likeDoc) {
          likeButton.classList.remove('liked');
          likeCountLabel.textContent = String(postDoc.likeCount);
        } else {
          likeButton.classList.add('liked');
          likeCountLabel.textContent = String(postDoc.likeCount);
        }
      }

      // Update the likedByCurrentUser property in the userPosts array
      setUserPosts((prevPosts) =>
        prevPosts.map((prevPost) => {
          if (prevPost.id === postId) {
            return {
              ...prevPost,
              likedByCurrentUser: postDoc.likedByCurrentUser,
              likeCount: postDoc.likeCount,
            };
          }
          return prevPost;
        })
      );
    } catch (error) {
      //console.error('Error toggling like:', error);
    }
  };

  const handleUpdateProfile = async () => {
    if (signedInUserUsername) {
      const authUser = auth.currentUser;
      const uid = authUser?.uid;
      if (uid) {
        const userRef = doc(db, 'users', uid);
        let websiteUrl = updatedWebsite;
        if (websiteUrl && !websiteUrl.startsWith('http://') && !websiteUrl.startsWith('https://')) {
          websiteUrl = 'http://' + websiteUrl;
        }
        if (websiteUrl && !isValidUrl(websiteUrl)) {
          // Show error message for invalid URL
          return;
        }
        await updateDoc(userRef, {
          displayName: updatedDisplayName,
          pronouns: updatedPronouns,
          initials: updatedInitials,
          profileColor: updatedProfileColor,
          bio: updatedBio,
          website: websiteUrl
        });
        setUserProfile((prevProfile: any) => ({
          ...prevProfile,
          displayName: updatedDisplayName,
          pronouns: updatedPronouns,
          initials: updatedInitials,
          profileColor: updatedProfileColor,
          bio: updatedBio,
          website: websiteUrl
        }));
        setIsModalOpen(false);
      }
    }
  };

  // Function to open the sign-in modal
  const openSignInModal = () => {
    setShowSignInModal(true);
  };

  // Function to close the sign-in modal
  const closeSignInModal = () => {
    setShowSignInModal(false);
  };

  const handleModalDismiss = () => {
    setUpdatedDisplayName(initialDisplayName);
    setUpdatedPronouns(initialPronouns);
    setUpdatedInitials(initialInitials);
    setUpdatedProfileColor(initialProfileColor);
    setUpdatedBio(initialBio);
    setUpdatedWebsite(initialWebsite);
    setIsModalOpen(false);
  };

  const openDeleteAlert = (post: Post) => {
    setPostToDelete(post);
    setDeleteAlert(`post-delete-alert-${post.id}`);
  };

  const closeDeleteAlert = () => {
    setPostToDelete(null);
    setDeleteAlert(undefined);
  };

  const handleDeleteConfirm = async () => {
    if (postToDelete) {
      try {
        const postRef = doc(db, 'posts', postToDelete.id);
        
        // Update the comment document to clear the userID, text, blueprint, and image fields
        await updateDoc(postRef, {
          userID: 'Deleted',
          text: 'Deleted',
          blueprint: [],
          image: '',
        });
        
        deletePostConfirmationToastRef.current?.present();

        //console.log('Comment deleted successfully');
        
      } catch (error) {
        //console.error('Error deleting comment:', error);
      }
    }
  };

  return (
    <IonPage>
      <HeaderToolbar showBackButton />
      <IonContent>
        <div className="limitedWidth profile-page">
          {isLoading ? ( // Display a loading spinner while the profile information is being fetched.
            <div className="loadingSpinner ion-text-center">
              <IonSpinner name="circular" color="primary" />
            </div>
          ) : userProfileMemo ? (
            <>
              <IonModal isOpen={isModalOpen} onDidDismiss={handleModalDismiss}>
                <IonHeader>
                  <IonToolbar color="medium" mode="ios">
                    <IonButtons slot="start">
                      <IonButton onClick={handleModalDismiss}>Cancel</IonButton>
                    </IonButtons>
                    <IonTitle>Edit profile</IonTitle>
                    <IonButtons slot="end">
                      <IonButton onClick={handleUpdateProfile} color="primary" style={{ fontWeight: 'bold' }}>Done</IonButton>
                    </IonButtons>
                  </IonToolbar>
                </IonHeader>
                <IonContent>
                  <div className="profilePicture" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <UserAvatar profileColor={updatedProfileColor} initials={updatedInitials} />
                  </div>
                  <div className="username">
                    <span>
                      <IonText color="medium">@{userProfile.username}</IonText>
                    </span>
                  </div>
                  <IonList>
                    <IonItem>
                      <IonInput
                        value={updatedDisplayName}
                        onIonChange={(e) => {
                          const value = e.detail.value as string;
                          if (value.length <= 40) { // Limiting to 40 characters
                            setUpdatedDisplayName(value);
                          }
                        }}
                        label="Display name"
                        labelPlacement="floating"
                        maxlength={40}
                      ></IonInput>
                    </IonItem>
                    <IonItem>
                      <IonLabel>Pronouns</IonLabel>
                      <IonSelect
                        value={updatedPronouns}
                        onIonChange={(e) => setUpdatedPronouns(e.detail.value)}
                        placeholder="Select pronouns"
                        interface="action-sheet"
                        interfaceOptions={{
                          cssClass: 'pronoun-select'
                        }}
                        aria-label="Pronouns"
                      >
                        <IonSelectOption value="they/them">They/Them</IonSelectOption>
                        <IonSelectOption value="she/her">She/Her</IonSelectOption>
                        <IonSelectOption value="she/they">She/They</IonSelectOption>
                        <IonSelectOption value="he/him">He/Him</IonSelectOption>
                        <IonSelectOption value="he/they">He/They</IonSelectOption>
                        <IonSelectOption value="xe/xem">Xe/Xem</IonSelectOption>
                        <IonSelectOption value="ze/hir">Ze/Hir</IonSelectOption>
                        <IonSelectOption value="ey/em">Ey/Em</IonSelectOption>
                        <IonSelectOption value="per/pers">Per/Pers</IonSelectOption>
                        <IonSelectOption value="ve/ver">Ve/Ver</IonSelectOption>
                        <IonSelectOption value="ne/nem">Ne/Nem</IonSelectOption>
                        <IonSelectOption value="ey/eir">Ey/Eir</IonSelectOption>
                        <IonSelectOption value="sie/hir">Sie/Hir</IonSelectOption>
                        <IonSelectOption value="eyir/eyirs">Eyir/Eyirs</IonSelectOption>
                        <IonSelectOption value="thon/thons">Thon/Thons</IonSelectOption>
                        <IonSelectOption value="fae/faer">Fae/Faer</IonSelectOption>
                        <IonSelectOption value="kit/kitself">Kit/Kitself</IonSelectOption>
                        <IonSelectOption value="ve/vis">Ve/Vis</IonSelectOption>
                        <IonSelectOption value="ne/nis">Ne/Nis</IonSelectOption>
                        <IonSelectOption value="ae/aer">Ae/Aer</IonSelectOption>
                        <IonSelectOption value="ey/emself">Ey/Emself</IonSelectOption>
                        <IonSelectOption value="prefer_not_to_say">Prefer not to say</IonSelectOption>
                      </IonSelect>
                    </IonItem>
                    <IonItem>
                      <IonInput
                        value={updatedInitials}
                        onIonChange={(e) => {
                          const value = e.detail.value as string;
                          if (value.length <= 2 || isSingleEmoji(value)) {
                            setUpdatedInitials(value);
                          }
                        }}
                        label="Initials"
                        labelPlacement="floating"
                        maxlength={2}
                      ></IonInput>
                    </IonItem>
                    <IonItem>
                      <IonLabel>Color</IonLabel>
                      <IonSelect
                        value={updatedProfileColor}
                        onIonChange={(e) => setUpdatedProfileColor(e.detail.value)}
                        placeholder="Select color"
                        interface="action-sheet"
                        interfaceOptions={{
                          cssClass: 'color-select'
                        }}
                        aria-label="Color"
                      >
                        <IonSelectOption value="#d8331d">Fyrr</IonSelectOption>
                        <IonSelectOption value="#ff9429">Juus</IonSelectOption>
                        <IonSelectOption value="#f7bb11">Leemun</IonSelectOption>
                        <IonSelectOption value="#7dc437">Mossi</IonSelectOption>
                        <IonSelectOption value="#3be2b2">Seewuudr</IonSelectOption>
                        <IonSelectOption value="#31caff">Skie</IonSelectOption>
                        <IonSelectOption value="#0e5ddb">Saffyr</IonSelectOption>
                        <IonSelectOption value="#6719cc">Royyl</IonSelectOption>
                        <IonSelectOption value="#e22388">Berri</IonSelectOption>
                        <IonSelectOption value="#fb73ff">Bublgum</IonSelectOption>
                        <IonSelectOption value="#eaa77a">Saand</IonSelectOption>
                        <IonSelectOption value="#995639">Chokklit</IonSelectOption>
                        <IonSelectOption value="#562613">Caffe</IonSelectOption>
                        <IonSelectOption value="#ffffff">Snoe</IonSelectOption>
                        <IonSelectOption value="#000000">Karbun</IonSelectOption>
                        <IonSelectOption value="#c8dee8">Frosti</IonSelectOption>
                        <IonSelectOption value="#888a8e">Pebbl</IonSelectOption>
                        <IonSelectOption value="#282f3a">Slayt</IonSelectOption>
                      </IonSelect>
                    </IonItem>
                    <IonItem>
                      <IonTextarea
                        value={updatedBio}
                        onIonChange={(e) => {
                          const value = e.detail.value as string;
                          if (value.length <= 100) { // Limiting to 100 characters
                            setUpdatedBio(value);
                          }
                        }}
                        label="Bio"
                        labelPlacement="floating"
                        maxlength={100} // Add maxlength attribute
                      ></IonTextarea>
                    </IonItem>
                    <IonItem>
                      <IonInput
                        value={updatedWebsite}
                        onIonChange={(e) => {
                          const value = e.detail.value as string;
                          setUpdatedWebsite(value);
                        }}
                        label="Website URL"
                        labelPlacement="floating"
                        type="text" // Set the input type to "text" to allow URLs without the protocol prefix
                        maxlength={100}
                      ></IonInput>
                    </IonItem>
                    {updatedWebsite && !isValidUrl(updatedWebsite) && (
                      <IonText color="danger">Please enter a valid website URL with a domain extension (e.g., example.com).</IonText>
                    )}
                  </IonList>
                </IonContent>
              </IonModal>
              <div className="profileContainer">
                <div className="profileHeader">
                  <UserAvatar profileColor={userProfile.profileColor} initials={userProfile.initials} />
                  <div className="profileText">
                    <div>
                      <h1>
                        {userProfile.displayName}{' '}
                        {userProfile.pronouns !== "prefer_not_to_say" && (
                          <IonText color="medium" style={{ fontSize: "16px" }}>
                            {userProfile.pronouns}
                          </IonText>
                        )}
                      </h1>
                      <div className="username">
                        <span>
                          <IonText color="medium">@{userProfile.username}</IonText>
                        </span>
                      </div>
                      <div className="bio">
                        <span>{userProfile.bio}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="userDetails">
                    <div>
                      {userProfile.website && (
                        <>
                          <span className="iconWrapper">
                            <IonIcon icon={linkOutline} className="icon" color="medium" />
                          </span>
                          <a
                            href={userProfile.website}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="iconText"
                          >
                            {getWebsiteDomain(userProfile.website)}
                          </a>
                        </>
                      )}
                      <span className="iconWrapper">
                        <IonIcon icon={calendarOutline} className="icon" color="medium" />
                      </span>
                      <span className="iconText createdAtText">
                        <IonText color="medium">
                          {`Joined ${getFormattedCreatedAt(userProfile.createdAt)}`}
                        </IonText>
                      </span>
                    </div>

                    {userProfile && signedInUserUsername && userProfile.username === signedInUserUsername && (
                      <>
                        <div>
                          <span className="iconWrapper">
                            <IonText color="medium">
                              {`${userProfile.following} following, ${userProfile.followers} followers`}
                            </IonText>
                          </span>
                        </div>
                      </>
                    )}

                  </div>
                  <div className="profileButtonContainer">
                    {userProfile && signedInUserUsername && userProfile.username !== signedInUserUsername && (
                      <IonButton
                        className={`followProfileButton${isFollowing ? ' unfollow' : ''}`}
                        color="primary"
                        size="small"
                        onClick={toggleFollow}
                        fill={isFollowing ? "outline" : "solid"} // Add the fill attribute based on the follow state
                      >
                        {isFollowing ? 'Unfollow' : 'Follow'}
                      </IonButton>
                    )}
                    {userProfile && signedInUserUsername && userProfile.username === signedInUserUsername && (
                      <IonButton className="editProfileButton" color="medium" size="small" onClick={() => setIsModalOpen(true)}>
                        Edit profile
                      </IonButton>
                    )}
                  </div>
              </div>
              <div>
                <p style={{borderBottom:"1px solid rgb(35, 50, 65)", textAlign:"center", padding:"10px 0px", margin:"0"}}>All posts</p>
              </div>

              {areUserPostsLoadedMemo ? ( // Only render the posts section when user posts are loaded
                <div className="userPosts">
                  {userPosts.length === 0 ? (
                    <p style={{ fontSize: "24px" }}>
                      {userProfile.displayName} hasn't posted anything yet, AND THAT'S OKAY
                    </p>
                  ) : (
                    userPosts
                      .slice()
                      .sort((a, b) => b.timestamp - a.timestamp)
                      .map((post) => {
                        
                        // Get the current URL from the browser
                        const currentURL = window.location.href;

                        // Get the base domain of the current URL
                        const baseDomain = getBaseDomain(currentURL);

                        // Convert URLs and @username mentions to clickable links with target="_blank"
                        const linkifiedText = linkifyHtml(post.text, {
                          defaultProtocol: "https",
                          formatHref: function (href: string, type: string) {
                            if (type === "email") {
                              return `mailto:${href}`;
                            }
                            return href;
                          },
                          attributes: {
                            onclick: "event.stopPropagation();",
                            target: "_blank" // Open links in new tabs
                          },
                          className: {
                            url: "link",
                            email: "email-link",
                            hashtag: "hashtag-link",
                            mention: "mention-link" // CSS class for @username mentions
                          }
                        });

                        // Convert @username mentions to routerLinks using the base domain
                        const convertedText = convertUsernameMentionsToLinks(linkifiedText, baseDomain);

                        // Calculate the number of lines in the post text
                        const lines = convertedText.split("<br>").length;
                        const shouldShowMore = lines > 5 || convertedText.length > 350;

                        // Cut off the post text if it exceeds 5 lines or 350 characters
                        const truncatedText = shouldShowMore
                          ? (lines > 5
                            ? convertedText.split("<br>").slice(0, 5).join("<br>").slice(0, 350)
                            : convertedText.slice(0, 350)) + "..."
                          : convertedText;

                        return (
                          <>
                            <a className="post-item-link" href={`/${userProfile?.username}/post/${post.id}`}>
                              <IonItem button className="menu-item" detail={false} lines="none">
                                <IonCard className="postItem">
                                  <IonCardContent>
                                    <div className="postContent">
                                      <UserAvatar profileColor={userProfile.profileColor} initials={userProfile.initials} />
                                      <div className="postText">
                                        <p>
                                          <IonText style={{ fontWeight: "bold" }}>{userProfile.displayName}</IonText>{" "}
                                          <IonText color="medium">@{userProfile.username}</IonText>
                                        </p>
                                        <p>
                                          <a
                                            href={`/${userProfile?.username}/post/${post.id}`}
                                            style={{ color: "inherit", textDecoration: "none" }}
                                            onClick={(event) => {
                                              if (event.button === 1) {
                                                // Middle click
                                                event.preventDefault();
                                                event.stopPropagation();
                                                window.open(`/${userProfile?.username}/post/${post.id}`, '_blank');
                                              } else {
                                                event.stopPropagation();
                                              }
                                            }}
                                            className="postTimestamp hoverUnderline"
                                          >
                                            {getFormattedCreatedAt(post.timestamp, "justNow")}
                                          </a>
                                        </p>
                                      </div>
                                      <div className="ellipsisButtonContainer">
                                        <IonButton
                                          fill="clear"
                                          size="small"
                                          className="ellipsisButton"
                                          id={`options-popover-${post.id}`}
                                          onClick={(event) => {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            setSelectedPostId(post.id);
                                          }}
                                        >
                                          <IonIcon icon={ellipsisHorizontal} color="medium" />
                                        </IonButton>
                                      </div>
                                    </div>
                                   {post.title &&
                                     <div className="postTitle">
                                        <h2>
                                          {/*<IonChip color="secondary" outline={true}>
                                            <IonIcon icon={cafeOutline}></IonIcon>
                                            <IonLabel>Topic</IonLabel>
                                          </IonChip>*/}
                                          {post.title}
                                        </h2>
                                      </div>
                                    }
                                    {post.image && 
                                      <>
                                        <div className="postImage">
                                          <img src={post.image} alt="Build Image" />
                                          {post.blueprint &&
                                          <div className="overlayLabel">View in 3D</div>
                                          }
                                        </div>
                                      </>
                                    }
                                    <div className="postMessage">


                                        <p dangerouslySetInnerHTML={{ __html: truncatedText }}></p>
                                      {shouldShowMore && (
                                        <p className="showMore">Show more</p>
                                      )}
                                    </div>
                                    <div className="postDetails">
                                      <div className="buttonsLeft">
                                        <div className="buttonContainer">
                                          <IonButton
                                            fill="clear"
                                            id={`likeButton_${post.id}`}
                                            className={`${post.likedByCurrentUser ? 'liked' : ''}`}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              if (signedInUserId) {
                                                toggleLike(post.id);
                                              } else {
                                                openSignInModal();
                                              }
                                            }}
                                          >
                                            <IonIcon
                                              slot="icon-only"
                                              icon={post.likedByCurrentUser ? heart : heartOutline}
                                              color={post.likedByCurrentUser ? 'danger' : 'medium'}
                                            />
                                          </IonButton>
                                          <span>{shortenNumber(post.likeCount)}</span>
                                        </div>
                                        <div className="buttonContainer">
                                          <IonButton
                                            fill="clear"
                                            onClick={(e) => {
                                              if (signedInUserId) {
                                                // Handle comment button click
                                              } else {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                openSignInModal();
                                              }
                                            }}
                                          >
                                            <IonIcon slot="icon-only" icon={chatbubbleOutline} color="medium" />
                                          </IonButton>
                                          <span>{shortenNumber(post.replyCount)}</span>
                                        </div>
                                      </div>
                                      <div className="buttonsRight">
                                        <div className="buttonContainer">
                                          <IonButton
                                            fill="clear"
                                            id={`share-popover-${post.id}`}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              setSelectedPostId(post.id);
                                            }}
                                          >
                                            <IonIcon slot="icon-only" icon={shareOutline} color="medium" />
                                          </IonButton>
                                        </div>
                                      </div>
                                      <IonPopover class="ion-padding" className="postDeletePopover" trigger={`options-popover-${post.id}`} dismissOnSelect={true}>
                                        <IonContent>
                                          <IonList lines="none">
                                            {userProfile.username === signedInUserUsername && (
                                              <IonItem
                                                button={true}
                                                detail={false}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                  openDeleteAlert(post);
                                                }}
                                              >
                                                <IonIcon slot="start" icon={trashOutline} color="primary" />
                                                <IonLabel>Delete post</IonLabel>
                                              </IonItem>
                                            )}
                                            {userProfile.username !== signedInUserUsername && (
                                              <IonItem
                                                button={true}
                                                detail={false}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                  if (signedInUserId) {
                                                    toggleFollow();
                                                  } else {
                                                    openSignInModal();
                                                  }
                                                }}
                                              >
                                                <IonIcon
                                                  slot="start"
                                                  icon={isFollowing ? personRemoveOutline : personAddOutline} // Use different icons based on the follow state
                                                  color="primary"
                                                />
                                                <IonLabel>{isFollowing ? 'Unfollow' : 'Follow'} @{userProfile.username}</IonLabel>
                                              </IonItem>
                                            )}
                                          </IonList>
                                        </IonContent>
                                      </IonPopover>

                                      <SharePopover postID={post.id} username={userProfile?.username} copyConfirmationToastRef={copyConfirmationToastRef} />

                                    </div>
                                  </IonCardContent>
                                </IonCard>
                              </IonItem>
                            </a>
                          </>
                        );
                      })
  
                  )}

                  <IonAlert
                  isOpen={deleteAlert === `post-delete-alert-${postToDelete?.id}`}
                  onDidDismiss={closeDeleteAlert}
                  header="Delete post"
                  message="Are you sure you want to delete this post?"
                  buttons={[
                    {
                      text: 'Cancel',
                      role: 'cancel',
                      handler: () => {
                        closeDeleteAlert();
                      },
                    },
                    {
                      text: 'Delete',
                      handler: () => {
                        handleDeleteConfirm();
                      },
                    },
                  ]}
                />

                </div>
            ) : (

              <div className="no-posts-text">
                <img src="assets/images/no-posts.svg" alt="illustration of a person holding out an empty hand with magical particle floating above their palm" height="200px" width="400px" />
                <p><b>You haven't posted yet</b><br /><span>Click the big yellow + button below to share something</span></p>
              </div>

            )}
            </>
          ) : (
            <>
              <h1>This page isn't available.</h1>
              <p>Sorry, it looks like the page you're trying to view either never existed or has been deleted.</p>
              <p><Link to="/home">Go to homepage</Link></p>
            </>
          )}
        </div>
        <IonInfiniteScroll
          threshold="100px"
          disabled={!isInfiniteScrollEnabled || areAllPostsLoaded}
          onIonInfinite={async () => {
            await loadMorePosts(); // Wait for loadMorePosts to complete
            const infiniteScrollElement = document.querySelector('ion-infinite-scroll');
            if (infiniteScrollElement) {
              infiniteScrollElement.complete();
            }
          }}
        >
          <IonInfiniteScrollContent loadingSpinner="circular"></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
      <SignInModal isOpen={showSignInModal} onClose={closeSignInModal} />
      <IonToast
        ref={copyConfirmationToastRef}
        message="Link copied to clipboard!"
        color="medium"
        position="top"
        duration={3000}
        class="auto-width-toast"
      />
      <IonToast
        ref={deletePostConfirmationToastRef}
        message="Comment deleted."
        color="medium"
        position="top"
        duration={3000}
        class="auto-width-toast"
      />
    </IonPage>
  );
};
export default withRouter(ProfileScreen);