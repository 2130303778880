import React from 'react';
import { IonPopover, IonContent, IonList, IonItem, IonIcon, IonLabel } from '@ionic/react';
import { copyOutline, shareSocialOutline } from 'ionicons/icons';

interface PostPopoverProps {
  postID: string;
  username: string;
  copyConfirmationToastRef: React.RefObject<any>;
}

const SharePopover: React.FC<PostPopoverProps> = ({ postID, username, copyConfirmationToastRef }) => {
  const handleCopyLink = (e: React.MouseEvent<HTMLIonItemElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    const postLink = window.location.origin + `/${username}/post/${postID}`;
    navigator.clipboard.writeText(postLink);
    copyConfirmationToastRef.current?.present();
  };

  const handleSharePost = (e: React.MouseEvent<HTMLIonItemElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    const postLink = window.location.origin + `/${username}/post/${postID}`;
    if (navigator.share) {
      const shareText = `Check out this Blokkiti post by @${username}:`;
      navigator.share({
        title: 'Share Post',
        text: shareText,
        url: postLink,
      })
        .then(() => {
          // Sharing successful
        })
        .catch((error) => {
          if (error.name === 'AbortError') {
            // Sharing was canceled
          } else {
            //console.error('Error sharing:', error);
          }
        });
    }
  };

  return (
    <IonPopover className="postSharePopover" trigger={`share-popover-${postID}`} dismissOnSelect={true}>
      <IonContent>
        <IonList lines="none">
          <IonItem button={true} detail={false} onClick={handleCopyLink}>
            <IonIcon slot="start" icon={copyOutline} color="primary" />
            <IonLabel>Copy link to post</IonLabel>
          </IonItem>
          <IonItem button={true} detail={false} onClick={handleSharePost}>
            <IonIcon slot="start" icon={shareSocialOutline} color="primary" />
            <IonLabel>Share post via...</IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPopover>
  );
};

export default SharePopover;
