// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCSTUrs1duDO0_oqYnHePPZ642RjMXiRUg",
  authDomain: "blokkiti.firebaseapp.com",
  projectId: "blokkiti",
  storageBucket: "blokkiti.appspot.com",
  messagingSenderId: "456166673053",
  appId: "1:456166673053:web:cf6b6d770351a40419e274",
  measurementId: "G-TM117SSRV4"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);