import { IonPage, IonContent, IonButton } from '@ionic/react';
import { RouteComponentProps, withRouter } from 'react-router';
import React from 'react';
import HeaderToolbar from '../components/HeaderToolbar';
import SideMenu from '../components/SideMenu';

type Props = RouteComponentProps;

const PrivacyPolicy: React.FC<Props> = ({ history }) => {
  return (
    <>
      <SideMenu/>
      <IonPage id="main-content">
        <HeaderToolbar />
        <IonContent className="ion-padding">
          <div className="limitedWidth">
              <h1>Privacy Policy for Blokkiti</h1>

              <p>Effective Date: July 17, 2023</p>

              <p>Runner LLC ("we," "us," or "our") operates the website Blokkiti (https://blokkiti.com) ("Website"). This Privacy Policy outlines our practices regarding the collection, use, and disclosure of personal information when you use our Website.</p>

              <p>By accessing or using Blokkiti, you agree to the terms and conditions of this Privacy Policy. If you do not agree with the practices described in this policy, please do not use our Website.</p>

              <h2>1. Information We Collect</h2>

              <p><strong>1.1 Personal Information:</strong> When you sign up and create an account on Blokkiti, we may collect certain personal information, including but not limited to your name, email address, and password. We require this information to provide you with access to our services and to enhance your user experience.</p>

              <p><strong>1.2 User-Generated Content:</strong> As a user of Blokkiti, you may create and share content on the Website, such as posts, comments, and other information. Please be aware that any information you voluntarily disclose through these means becomes publicly available and may be viewed, collected, and used by others.</p>

              <p><strong>1.3 Usage Data:</strong> We collect information about your interaction with the Website, such as your IP address, browser type, device information, operating system, and browsing patterns. This data helps us analyze and improve the performance and usability of Blokkiti.</p>

              <h2>2. Use of Information</h2>

              <p><strong>2.1 Provision of Services:</strong> We use your personal information to provide you with access to Blokkiti's features and functionalities, including account management, content creation, and user interaction. We may also use your information to respond to your inquiries, provide customer support, and improve our services.</p>

              <p><strong>2.2 Communication:</strong> We may use your email address to send you administrative or promotional communications related to your use of Blokkiti. You have the option to opt-out of receiving promotional emails by following the unsubscribe instructions included in those emails.</p>

              <p><strong>2.3 Aggregated Data:</strong> We may aggregate and anonymize your personal information to generate statistical and analytical insights for our internal purposes. Aggregated data does not identify any individual and is used to improve our services and better understand user preferences.</p>

              <h2>3. Data Sharing and Disclosure</h2>

              <p><strong>3.1 Third-Party Service Providers:</strong> We may engage trusted third-party service providers to assist us in operating Blokkiti and delivering services to you. These service providers may have access to your personal information but are contractually obligated to handle it securely and only for the purposes specified by us.</p>

              <p><strong>3.2 Legal Compliance:</strong> We may disclose your personal information if required by law, legal process, or government request. We may also disclose your information to protect our rights, privacy, safety, or property, as well as those of our users or the public.</p>

              <h2>4. Data Security</h2>

              <p>We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, please note that no method of transmission over the internet or electronic storage is 100% secure. Therefore, while we strive to use commercially acceptable means to protect your information, we cannot guarantee its absolute security.</p>

              <h2>5. International Data Transfer</h2>

              <p>Your personal information may be transferred to and stored on servers located outside your country of residence. By using Blokkiti, you consent to the transfer of your information to countries that may have different data protection laws than your jurisdiction.</p>

              <h2>6. Children's Privacy</h2>

              <p>Blokkiti is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children. If you become aware that your child has provided us with personal information without your consent, please contact us, and we will take steps to delete such information.</p>

              <h2>7. Changes to this Privacy Policy</h2>

              <p>We may update this Privacy Policy from time to time. We will notify you of any material changes by posting the updated policy on our Website or by sending you an email notification. Your continued use of Blokkiti after any modifications will constitute your acceptance of the revised policy.</p>

              <h2>8. Contact Us</h2>

              <p>If you have any questions, concerns, or suggestions regarding this Privacy Policy or our privacy practices, please contact us at <a href="mailto:hello@runner.studio">hello@runner.studio</a>.</p>

              <p>By using Blokkiti, you acknowledge that you have read and understood this Privacy Policy and consent to the collection, use, and disclosure of your personal information as described herein.</p>
            
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

export default withRouter(PrivacyPolicy);
