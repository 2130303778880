import { IonContent, IonHeader, IonFooter, IonPage, IonTitle, IonToolbar, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonText, IonAvatar, IonList, IonItem, IonChip, IonLabel, IonRow, IonCol, IonPopover, IonButtons, IonButton, IonBackButton, IonIcon, IonTabBar, IonTabButton, IonMenu, IonMenuToggle, IonMenuButton, IonToast } from '@ionic/react';
import React, { useEffect, useState } from "react";
import { withRouter, RouteComponentProps } from 'react-router';
import { useLocation, useHistory } from 'react-router-dom';
import { personOutline, cogOutline, logOutOutline, logoTiktok, logoInstagram, logoYoutube, logoReddit, shirtOutline } from 'ionicons/icons';
import { signOutUser } from '../services/firebaseAuth';
import UserAvatar from "./UserAvatar";
import { getCachedUser } from "../services/userCache";

type Props = RouteComponentProps;

const SideMenu: React.FC<Props> = (props) => {
  const location = useLocation();
  const [displayName, setDisplayName] = useState("");
  const [username, setUsername] = useState("");
  const [profileColor, setProfileColor] = useState("");
  const [initials, setInitials] = useState("");
  const [showToast, setShowToast] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const fetchUser = async () => {
      const user = getCachedUser();
      if (user) {
        setDisplayName(user.displayName);
        setUsername(user.username);
        setProfileColor(user.profileColor);
        setInitials(user.initials);
      }
    };
    fetchUser();
  }, []);

  const handleSignOut = async () => {
    try {
      await signOutUser();
      // Redirect the user to the login page or any other desired page
      props.history.push('/');
    } catch (error) {
      console.log('Error signing out:', error);
      // Show a toast or error message to the user
      setShowToast(true);
    }
  };

  const goToProfile = () => {
    props.history.push(`/${username}`);
  };

  return (
    <IonMenu contentId="main-content" className="sideMenu">
      <IonHeader>
        <IonToolbar>
          <div className="profileHeader">
            <UserAvatar profileColor={profileColor} initials={initials} />
            <div className="profileText">
              <div>
                <h1>{displayName}</h1>
                <div className="username">
                  <span>
                    <IonText color="medium">@{username}</IonText>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonList lines="none">
          <IonItem button onClick={goToProfile} className="menu-item">
            <IonIcon icon={personOutline} slot="start" />
            <IonLabel>Profile</IonLabel>
          </IonItem>
          {/*
          <IonItem button className="menu-item" routerLink="/settings">
            <IonIcon icon={cogOutline} slot="start" />
            <IonLabel>Settings</IonLabel>
          </IonItem>
          */}
          <IonItem button onClick={handleSignOut} className="menu-item">
            <IonIcon icon={logOutOutline} slot="start" />
            <IonLabel>Sign Out</IonLabel>
          </IonItem>
        </IonList>

{/*
        <hr className="divider" />

        <IonList className="secondary-list" lines="none">
          <IonItem button className="menu-item" routerLink="/support-blokkiti">
            <IonLabel>✨ Support Blokkiti ✨</IonLabel>
          </IonItem>
        </IonList>
*/}

        <hr className="divider" />

        <IonList className="secondary-list" lines="none">
          <IonItem button className="menu-item" href="https://tiktok.com/@blokkiti" target="_blank" rel="noopener noreferrer">
            <IonIcon icon={logoTiktok} slot="start" />
            <IonLabel>TikTok</IonLabel>
          </IonItem>
          <IonItem button className="menu-item" href="https://instagram.com/blokkiti" target="_blank" rel="noopener noreferrer">
            <IonIcon icon={logoInstagram} slot="start" />
            <IonLabel>Instagram</IonLabel>
          </IonItem>
          <IonItem button className="menu-item" href="https://youtube.com/@blokkiti" target="_blank" rel="noopener noreferrer">
            <IonIcon icon={logoYoutube} slot="start" />
            <IonLabel>YouTube</IonLabel>
          </IonItem>
          {/*<IonItem button className="menu-item" href="https://reddit.com/user/blokkiti" target="_blank" rel="noopener noreferrer">
            <IonIcon icon={logoReddit} slot="start" />
            <IonLabel>Reddit</IonLabel>
          </IonItem>*/}
        </IonList>

        <hr className="divider" />

        <IonList className="secondary-list" lines="none">
          <IonItem button className="menu-item" routerLink="/privacy">
            <IonLabel>Privacy Policy</IonLabel>
          </IonItem>
          <IonItem button className="menu-item" routerLink="/terms">
            <IonLabel>Terms of Service</IonLabel>
          </IonItem>
        </IonList>

        <hr className="divider" />

        <div className="disclaimer">
          <p>Blokkiti Beta</p>
          <p><span>&copy; {new Date().getFullYear()} Runner LLC. All rights reserved.</span></p>
        </div>
      </IonContent>
      <IonToast
        isOpen={showToast}
        message="Error signing out"
        duration={3000}
        onDidDismiss={() => setShowToast(false)}
        position="top"
        color="danger"
      />
    </IonMenu>
  );
};

export default withRouter(SideMenu);
