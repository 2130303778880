import React, { useEffect, useState, useRef } from "react";
import { IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonMenuButton, IonBackButton, IonTitle, IonToast, IonPopover, IonContent, IonList, IonItem, IonLabel } from '@ionic/react';
import { chevronBackOutline, createOutline, shareOutline, radioOutline, } from 'ionicons/icons';
import { useLocation, useHistory } from 'react-router-dom';
import { onAuthStateChange } from '../services/firebaseAuth';
import { getCachedUser } from "../services/userCache";
import UserAvatar from "./UserAvatar";
import './HeaderToolbar.css';
import logo from '../assets/logomark_color.svg';

interface HeaderToolbarProps {
  showBackButton?: boolean;
}

const HeaderToolbar: React.FC<HeaderToolbarProps> = ({ showBackButton }) => {
  const user = getCachedUser();
  const location = useLocation();
  const history = useHistory();
  const isCreatePage = location.pathname.startsWith('/create/');
  const { profileColor, initials } = user || {};

  const copyConfirmationToastRef = useRef<HTMLIonToastElement>(null);

  // Function to extract the project id from the URL using regex
  const extractProjectIdFromURL = (url: string): string | null => {
    const regex = /\/create\/([a-zA-Z0-9_-]+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  const handleCopyLink = () => {
    const currentURL = window.location.href;
    const cachedUser = getCachedUser();
    const userData = cachedUser || {};
    const username = userData.username || "defaultUsername"; // Replace "defaultUsername" with a default value if needed
    const projectId = extractProjectIdFromURL(currentURL); // Implement a function to extract the project id from the URL (e.g., using regex)

    // Get the base URL (domain)
    const baseURL = window.location.origin;
    const newURL = `${baseURL}/view/${username}/${projectId}`;

    navigator.clipboard.writeText(newURL).then(() => {
      if (copyConfirmationToastRef.current) {
        copyConfirmationToastRef.current.present();
      }
    });
  };

  const handleShare = async () => {
    const currentURL = window.location.href;
    const cachedUser = getCachedUser();
    const userData = cachedUser || {};
    const username = userData.username || "defaultUsername"; // Replace "defaultUsername" with a default value if needed
    const projectId = extractProjectIdFromURL(currentURL); // Implement a function to extract the project id from the URL (e.g., using regex)

    // Get the base URL (domain)
    const baseURL = window.location.origin;
    const newURL = `${baseURL}/view/${username}/${projectId}`;

    try {
      await navigator.share({
        title: 'Share Current Page',
        url: newURL,
      });
    } catch (error) {
      // Handle errors or do nothing if the user cancels the share action
    }
  };

  const handleBackButtonClick = () => {
    history.goBack();
  };

  return (

    <>

    <IonHeader className="overlay preventSelect app-header">
      <IonToolbar mode="ios" className="header-toolbar">
        {showBackButton && (
          <IonButtons slot="start">
            <IonButton onClick={handleBackButtonClick}>
              <IonIcon icon={chevronBackOutline} /> Back
            </IonButton>
          </IonButtons>
        )}
        {isCreatePage && (
          <>

          <IonButtons slot="start">
            <IonButton onClick={handleBackButtonClick}>
              <IonIcon icon={chevronBackOutline} /> Back
            </IonButton>
          </IonButtons>

          <IonButtons slot="end">
            <IonButton id="share-popover-view">
              <IonIcon icon={shareOutline} />
            </IonButton>
          </IonButtons>

          </>
        )}
        <IonTitle>
          <img src={logo} alt="Logo" className="center-logo" />
        </IonTitle>
        {user && (
          <IonButtons slot="start">
            <IonMenuButton>
              <UserAvatar profileColor={profileColor} initials={initials} />
            </IonMenuButton>
          </IonButtons>
        )}
      </IonToolbar>
    </IonHeader>

    <IonPopover trigger="share-popover-view" className="commentOptionPopover" dismissOnSelect={true}>
      <IonContent>
        <IonList lines="none">
          <IonItem button={true} detail={false} onClick={handleCopyLink}>
            <IonIcon slot="start" icon={radioOutline} color="primary" />
            <IonLabel>Copy live-view link</IonLabel>
          </IonItem>
          <IonItem button={true} detail={false} onClick={handleShare}>
            <IonIcon slot="start" icon={shareOutline} color="primary" />
            <IonLabel>Share live-view link</IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPopover>

    <IonToast
      ref={copyConfirmationToastRef}
      message="Link copied to clipboard!"
      color="medium"
      position="top"
      duration={3000}
      class="auto-width-toast"
    />

    </>

  );
};

export default HeaderToolbar;
