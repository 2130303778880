import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { app } from './firebase';
import { loadUserData, getCachedUser } from './userCache';

export const auth = getAuth(app);
export const db = getFirestore(app);

export const createUser = (email: string, password: string) => createUserWithEmailAndPassword(auth, email, password);
export const signIn = (email: string, password: string) => signInWithEmailAndPassword(auth, email, password);
export const signOutUser = () => signOut(auth);

export const onAuthStateChange = (callback: any) => {
  return onAuthStateChanged(auth, async (user) => {
    if (user) {
      await loadUserData(user.uid);
      callback({ ...(user as any), ...getCachedUser() });
    } else {
      callback(null);
    }
  });
};
