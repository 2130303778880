import { IonButton, IonCard, IonPage, IonCardContent, IonCardHeader, IonCardTitle, IonCardSubtitle, IonContent, IonList, IonInput, IonItem, IonLabel } from '@ionic/react';
import React, { useState } from 'react';
import { signIn } from '../../services/firebaseAuth';

import './LoginScreen.css';

const LoginScreen: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [isTouched, setIsTouched] = useState(false);
  const [isValid, setIsValid] = useState<boolean>();

  const validateEmail = (email: string) => {
    return email.match(
      /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    );
  };

  const validate = (ev: Event) => {
    const value = (ev.target as HTMLInputElement).value;

    setIsValid(undefined);

    if (value === '') return;

    validateEmail(value) !== null ? setIsValid(true) : setIsValid(false);
  };

  const markTouched = () => {
    setIsTouched(true);
  };

  const handleSignIn = async () => {
    try {
      await signIn(email, password);
    } catch (error: any) {
      switch (error.code) {
        case "auth/invalid-email":
          setError("Please enter a valid email address");
          break;
        case "auth/user-not-found":
          setError("Invalid email or password");
          break;
        case "auth/wrong-password":
          setError("Invalid email or password");
          break;
        case "auth/too-many-requests":
          setError("Your account has been temporarily disabled. Please try again later.");
          break;
        case "auth/network-request-failed":
          setError("Unable to connect to the server. Please check your internet connection and try again.");
          break;
        case "auth/internal-error":
          setError("Something went wrong. Please try again later.");
          break;
        default:
          setError("Something went wrong. Please try again later.");
          break;
      }
    }
  };

  return (
    <IonPage>
      <IonContent className="ion-padding">
        <IonCard className="ion-padding" style={{ maxWidth: "25rem", margin: "0 auto" }}>
          <IonCardHeader>
            <IonCardTitle>Sign In</IonCardTitle>
            <IonCardSubtitle>Existing users only for now.</IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonInput
              className={`${isValid && 'ion-valid'} ${isValid === false && 'ion-invalid'} ${isTouched && 'ion-touched'}`}
              type="email"
              fill="solid"
              label="Email"
              labelPlacement="floating"
              errorText="Invalid email"
              value={email}
              onIonChange={(event: CustomEvent) => setEmail(event.detail.value as string)}
            />
            <IonInput
              type="password"
              fill="solid"
              label="Password"
              labelPlacement="floating"
              value={password}
              onIonChange={(event: CustomEvent) => setPassword(event.detail.value as string)}
            />
            {error && <p style={{ color: 'var(--ion-color-primary)' }}>{error}</p>}
            <br/>
            <IonButton expand="block" onClick={handleSignIn}>Sign In</IonButton>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default LoginScreen;
