// Reusable Utilities

export const getFormattedCreatedAt = (timestamp: any, format?: string): string => {
  const date = new Date(timestamp.seconds * 1000);
  const now = new Date();

  const minute = 60 * 1000;
  const hour = 60 * minute;
  const day = 24 * hour;
  const week = 7 * day;

  const timeDiff = now.getTime() - date.getTime();

  if (format === 'justNow' && timeDiff < minute) {
    return 'just now';
  } else if (format === 'minutesAgo' && timeDiff < hour) {
    const minutes = Math.floor(timeDiff / minute);
    return `${minutes} min ago`;
  } else if (format === 'hoursAgo' && timeDiff < day) {
    const hours = Math.floor(timeDiff / hour);
    if (hours === 1) {
      return '1 hour ago';
    } else {
      return `${hours} hours ago`;
    }
  } else if (format === 'daysAgo' && timeDiff < week) {
    const days = Math.floor(timeDiff / day);
    if (days === 1) {
      return '1 day ago';
    } else if (days === 0 && now.getDate() !== date.getDate()) {
      return 'yesterday';
    } else {
      return `${days} days ago`;
    }
  } else if (format === 'local') {
    const formatter = new Intl.DateTimeFormat(undefined, {
      hour: 'numeric',
      minute: 'numeric',
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
    return formatter.format(date);
  } else if (timeDiff < minute) {
    return 'today';
  } else if (timeDiff < hour) {
    const minutes = Math.floor(timeDiff / minute);
    return `${minutes} min ago`;
  } else if (timeDiff < day) {
    const hours = Math.floor(timeDiff / hour);
    if (hours === 1) {
      return '1 hour ago';
    } else {
      return `${hours} hours ago`;
    }
  } else if (timeDiff < week) {
    const days = Math.floor(timeDiff / day);
    if (days === 1) {
      return '1 day ago';
    } else if (days === 0 && now.getDate() !== date.getDate()) {
      return 'yesterday';
    } else {
      return `${days} days ago`;
    }
  } else if (date.getFullYear() === now.getFullYear()) {
    const formatter = new Intl.DateTimeFormat(undefined, { month: 'long', day: 'numeric' });
    return formatter.format(date);
  } else {
    const formatter = new Intl.DateTimeFormat(undefined, { month: 'long', year: 'numeric' });
    return formatter.format(date);
  }
};


export const getFormattedLastUpdated = (lastUpdated: any) => {
  const currentDate = new Date();
  const lastUpdatedDate = new Date(lastUpdated.seconds * 1000);

  if (
    currentDate.getFullYear() === lastUpdatedDate.getFullYear() &&
    currentDate.getMonth() === lastUpdatedDate.getMonth() &&
    currentDate.getDate() === lastUpdatedDate.getDate()
  ) {
    // It's the same day, display time
    return lastUpdatedDate.toLocaleTimeString(navigator.language, {
      hour: 'numeric',
      minute: 'numeric',
    });
  } else if (
    currentDate.getFullYear() === lastUpdatedDate.getFullYear() &&
    currentDate.getMonth() === lastUpdatedDate.getMonth()
  ) {
    // It's a different day of the same month, display month and day
    return lastUpdatedDate.toLocaleDateString(navigator.language, {
      month: 'long',
      day: 'numeric',
    });
  } else if (currentDate.getFullYear() === lastUpdatedDate.getFullYear()) {
    // It's a different month of the same year, display month, day, and year
    return lastUpdatedDate.toLocaleDateString(navigator.language, {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  } else {
    // It's a different year, display month, day, and year
    return lastUpdatedDate.toLocaleDateString(navigator.language);
  }
};

// Check if a URL is valid
export const isValidUrl = (url: string) => {
  try {
    let normalizedUrl = url.trim();
    if (!normalizedUrl.startsWith('http://') && !normalizedUrl.startsWith('https://')) {
      normalizedUrl = `http://${normalizedUrl}`;
    }
    const validUrl = new URL(normalizedUrl);
    const domainParts = validUrl.hostname.split('.');
    const domainExtension = domainParts[domainParts.length - 1];
    return domainParts.length > 1 && domainExtension.length > 1;
  } catch (error) {
    return false;
  }
};

// Treat emoji as a single character
export function isSingleEmoji(value: string): boolean {
  const emojiRegex = /^[\uD800-\uDBFF][\uDC00-\uDFFF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDEFF]$/;
  return emojiRegex.test(value);
}

// Display and link website from any format
export const getWebsiteDomain = (url: string) => {
  const domainRegex = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/g;
  const matches = domainRegex.exec(url);
  return matches ? matches[1] : '';
};

// Take color, return contrasting color
export function getContrastingTextColor(backgroundColor: any) {
  // Convert the background color to RGB components
  const r = parseInt(backgroundColor.substr(1, 2), 16);
  const g = parseInt(backgroundColor.substr(3, 2), 16);
  const b = parseInt(backgroundColor.substr(5, 2), 16);

  // Calculate the relative luminance of the background color
  const relativeLuminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;

  // Determine the text color based on the relative luminance
  return relativeLuminance > 0.5 ? '#000000' : '#FFFFFF';
}

// Extract base domain from URL
export function getBaseDomain(url: string): string {
  const matches = url.match(/^https?:\/\/([^/?#]+)(?:[/?#]|$)/i);
  if (matches && matches.length >= 2) {
    const domain = matches[1];
    const parts = domain.split(".");
    if (parts.length > 2) {
      parts.shift();
    }
    return parts.join(".");
  }
  return "";
}

// Convert @username mentions to links using the base domain
export function convertUsernameMentionsToLinks(text: string, domain: string): string {
  const protocol = window.location.protocol;
  const fullDomain = protocol + '//' + domain;
  return text.replace(/@(\w+)/g, '<a href="/$1" target="_blank" onclick="event.stopPropagation();">' + '@$1</a>');
}

// Shorten number (e.g. 1K vs 1,000)
export function shortenNumber(number: number) {
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1) + 'M';
  } else if (number >= 1000) {
    return (number / 1000).toFixed(1) + 'K';
  } else {
    return number.toString();
  }
}