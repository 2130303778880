import React, { useState, useRef, useEffect } from 'react';
import { IonModal, IonHeader, IonToolbar, IonButtons, IonButton, IonTitle, IonContent, IonTextarea } from '@ionic/react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (replyText: string) => void;
  submitCommentConfirmationToastRef: React.RefObject<any>;
};

const ReplyModal: React.FC<Props> = ({ isOpen, onClose, onSubmit, submitCommentConfirmationToastRef }) => {
  const [replyText, setReplyText] = useState('');
  const messageInputRef = useRef<string>('');
  const characterCountRef = useRef<number>(0);

  useEffect(() => {
    const characterCountElement = document.getElementById('characterCount');
    if (characterCountElement) {
      characterCountElement.textContent = characterCountRef.current.toString();
    }
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLIonTextareaElement>) => {
    const inputValue = event.target.value || '';
    const newlineCount = (inputValue.match(/\n/g) || []).length + 1;
    const totalCharacterCount = inputValue.length + newlineCount - 1;
    const characterCount = Math.min(totalCharacterCount, 1000);

    setReplyText(inputValue);
    characterCountRef.current = characterCount;

    // Enable or disable the "Post" button based on textarea value
    const postButtonElement = document.getElementById('postButton') as HTMLButtonElement;
    if (postButtonElement) {
      postButtonElement.disabled = inputValue.trim() === '';
    }

    // Update the character count display element
    const characterCountElement = document.getElementById('characterCount');
    if (characterCountElement) {
      characterCountElement.textContent = characterCount.toString();
    }
  };

  const handleSubmit = () => {
    onSubmit(replyText);
    setReplyText('');
    onClose();
    submitCommentConfirmationToastRef.current?.present();
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose} id="newReplyModal">
      <IonHeader>
        <IonToolbar color="medium" mode="ios">
          <IonButtons slot="start">
            <IonButton onClick={onClose}>Cancel</IonButton>
          </IonButtons>
          <IonTitle>Reply to comment</IonTitle>
          <IonButtons slot="end">
            <IonButton
              className="postButton"
              onClick={handleSubmit}
              fill="solid"
              style={{ fontWeight: 'bold' }}
              color="primary"
              disabled={replyText.trim() === ''}
              id="postButton"
            >
              Reply
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <div style={{ width: '100%' }}>
            <IonTextarea
              className="messageInput"
              placeholder="Write your reply..."
              rows={5}
              value={replyText}
              onInput={handleInputChange}
              maxlength={1000}
            />
          </div>
          <div className="messageDetails" style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 0px', fontSize: '12px' }}>
            <span id="characterCount">{characterCountRef.current}</span> / 1000
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default ReplyModal;
