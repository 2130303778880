import React, { useEffect, useRef, useState, useCallback } from "react";
import { Redirect, Route, useLocation, Switch, useRouteMatch, matchPath, useHistory, RouteComponentProps, withRouter } from 'react-router-dom';
import {
  IonApp,
  IonPage,
  IonContent,
  IonFooter,
  IonRouterOutlet,
  setupIonicReact,
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonActionSheet,
  IonAlert,
  IonButtons,
  IonButton,
  IonHeader,
  IonInput,
  IonModal,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonTextarea,
  IonToast,
  IonFab,
  IonFabButton,
  IonThumbnail,
  IonBadge,
} from "@ionic/react";
import {
  addOutline,
  addCircleOutline,
  home,
  globe,
  apps,
  notifications,
  person,
  homeOutline,
  compassOutline,
  briefcaseOutline,
  notificationsOutline,
  personOutline,
  constructOutline,
  construct,
  createOutline,
  cubeOutline,
  sendOutline,
  add,
  checkmarkCircleOutline,
  attachOutline,
  closeOutline,
} from "ionicons/icons";

import {
  getFirestore,
  collection,
  doc,
  addDoc,
  updateDoc,
  deleteDoc,
  setDoc,
  onSnapshot,
  query,
  where,
  getDoc,
  getDocs,
  orderBy, 
  limit,
  serverTimestamp,
} from 'firebase/firestore';
import { auth, onAuthStateChange } from '../services/firebaseAuth';
import { User } from 'firebase/auth';

import GraphemeSplitter from 'grapheme-splitter';

import { getCachedUser, cacheBuilds } from "../services/userCache";

import {
  getFormattedCreatedAt,
  getContrastingTextColor,
  isValidUrl,
  getWebsiteDomain,
  isSingleEmoji,
  getBaseDomain,
  convertUsernameMentionsToLinks,
  shortenNumber,
  getFormattedLastUpdated
} from '../utils/UtilityFunctions';

import BuildPreviewCapture from '../components/BuildPreviewCapture';

import LoginScreen from '../screens/LoginScreen/LoginScreen';
import HomeScreen from '../screens/HomeScreen/HomeScreen';
import DiscoverScreen from '../screens/DiscoverScreen/DiscoverScreen';
import WorkshopScreen from '../screens/WorkshopScreen/WorkshopScreen';
import NotificationsScreen from '../screens/NotificationsScreen/NotificationsScreen';
import ProfileScreen from '../screens/ProfileScreen/ProfileScreen';
import CreateScreen from '../screens/CreateScreen/CreateScreen';
import ViewScreen from '../screens/ViewScreen/ViewScreen';
import PostScreen from '../screens/PostScreen/PostScreen';
import SettingsScreen from '../screens/SettingsScreen/SettingsScreen';

import Sponsorship from '../pages/Sponsorship';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsOfService from '../pages/TermsOfService';

interface TabNavigationProps {
  user: User | null;
  username: string | null;
}

interface Build {
  id: string;
  name: string;
  thumbnail?: string;
  timestampUpdated?: any;
}

const TabNavigation: React.FC<TabNavigationProps> = (props) => {

  const actionSheetRef = useRef<HTMLIonActionSheetElement | null>(null);
  const newBuildModalRef = useRef<HTMLIonModalElement>(null);

  const attachBuildModalRef = useRef<HTMLIonModalElement>(null);
  const captureBuildModalRef = useRef<HTMLIonModalElement>(null);
  const [attachedBuildID, setAttachedBuildID] = useState<any>(null);
  const [capturedImage, setCapturedImage] = useState<string | null>(null);
  const [isImageCaptured, setIsImageCaptured] = useState(false);
  const [is3DToggled, setIs3DToggled] = useState(false);

  const newPostModalRef = useRef<HTMLIonModalElement>(null);
  const postConfirmationToastRef = useRef<HTMLIonToastElement>(null);

  const newBuildInputRef = useRef<HTMLIonInputElement>(null);
  const newBuildButtonRef = useRef<HTMLIonButtonElement>(null);
  
  const [userBuilds, setUserBuilds] = useState<Build[]>([]);

  const history = useHistory();
  const location = useLocation();
  const db = getFirestore();

  const postButtonRef = useRef<HTMLIonButtonElement>(null);
  const titleInputRef = useRef<string>('');
  const messageInputRef = useRef<string>('');
  const characterCountRef = useRef<number>(0);

  useEffect(() => {
    const fetchUserBuilds = async () => {
      const buildsQuery = query(
        collection(db, 'builds'),
        where('userID', '==', auth.currentUser?.uid),
        orderBy('timestampUpdated', 'desc')
      );

      const unsubscribe = onSnapshot(buildsQuery, (snapshot) => {
        const buildsData: Build[] = snapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id, // Include the document ID
            name: data.name,
            thumbnail: data.thumbnail,
            timestampUpdated: data.timestampUpdated,
          };
        });
        setUserBuilds(buildsData);
      });

      // Cleanup the listener when the component unmounts
      return () => unsubscribe();
    };

    fetchUserBuilds();
  }, []);

  const renderCreateScreen = useCallback(() => {
    if (props.user) {
      return <CreateScreen />;
    } else {
      return <Redirect to="/home" />;
    }
  }, [props.user]);

  const renderViewScreen = useCallback(() => {
    if (props.user) {
      return <ViewScreen />;
    } else {
      return <Redirect to="/home" />;
    }
  }, [props.user]);

    const renderPostScreen = useCallback(() => {
    if (props.user) {
      return <PostScreen />;
    } else {
      return <Redirect to="/home" />;
    }
  }, [props.user]);

  // conditionally render the IonTabs component based on the current route
  if (location.pathname.startsWith('/create')) {
    return <CreateScreen />;
  }

  // Check if the given path matches the current location exactly
  const isPathExact = (path: string): boolean => {
    const match = matchPath(location.pathname, {
      path,
      exact: true,
      strict: false,
    });

    return !!match;
  };

  // Handle when the user tries to create a new project
  const handleCreateBuild = async () => {
    const inputValue = newBuildInputRef.current?.value?.toString().trim() || 'Untitled';

    // Handle the creation of a new build
    try {
      await handleCreate(inputValue);
      closeCreateModal();
    } catch (error) {
      //console.log(error);
      // Handle the error if the new build creation fails
    }
  };

  // Add new project to the db
  const handleCreate = async (buildName: string) => {
    // Get the current user's UID
    const uid = auth.currentUser?.uid;
    if (!uid) {
      return false;
    }

    // Generate a random ID for the project
    const buildId = doc(collection(db, 'builds')).id;

    // Create a new project document in Firestore using the project name from the input
    await setDoc(doc(collection(db, `builds`), buildId), {
      name: buildName,
      timestampCreated: new Date(),
      timestampUpdated: new Date(),
      userID: uid,
      objects: [],
      thumbnail: "",
      live: false,
    });

    // Navigate to the newly created project screen
    history.push(`/create/${buildId}`);
  };

  // Add posts to the db
  const handlePost = async () => {
    const uid = auth.currentUser?.uid;
    if (!uid) {
      return false;
    }

    const title = titleInputRef.current.trim().slice(0, 200);
    const postText = messageInputRef.current.trim().slice(0, 1000);
    if (postText === '') {
      return;
    }

    const newlineCount = (postText.match(/\n/g) || []).length + 1;
    const formattedText = postText.replace(/\n/g, "<br>");

    const postID = doc(collection(db, 'posts')).id;

    // Fetch the attached build from the 'builds' collection
    let buildRef;
    let buildData;
    if (attachedBuildID) {
      buildRef = doc(collection(db, 'builds'), attachedBuildID);
      const buildSnapshot = await getDoc(buildRef);
      buildData = buildSnapshot.data();
    }

    // Copy the 'objects' array from the build to the 'blueprints' field in the post (conditionally)
    const blueprint = is3DToggled ? (buildData?.objects || []) : undefined;

    const postData = {
      title: title,
      text: formattedText,
      timestamp: new Date(),
      userID: uid,
      rootID: "",
      parentID: "",
      image: capturedImage,
      likeCount: 0,
      replyCount: 0,
      ...(blueprint && { blueprint: blueprint }), // Add blueprint property conditionally
    };

    await setDoc(doc(collection(db, 'posts'), postID), postData);

    // Dismiss the modal immediately after posting
    if (newPostModalRef.current) {
      newPostModalRef.current.dismiss().then(() => {
        // Reset the input fields
        messageInputRef.current = '';
        characterCountRef.current = 0;

        // Show the post confirmation toast
        if (postConfirmationToastRef.current) {
          postConfirmationToastRef.current.present();
        }
      });
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLIonTextareaElement>) => {
    const inputValue = event.target.value || '';
    const newlineCount = (inputValue.match(/\n/g) || []).length + 1;
    const totalCharacterCount = inputValue.length + newlineCount - 1;
    const characterCount = Math.min(totalCharacterCount, 1000);

    messageInputRef.current = inputValue;
    characterCountRef.current = characterCount;

    // Enable or disable the "Post" button based on textarea value
    if (postButtonRef.current) {
      postButtonRef.current.disabled = inputValue.trim() === '';
    }

    // Update the character count display element
    const characterCountElement = document.getElementById('characterCount');
    if (characterCountElement) {
      characterCountElement.textContent = characterCount.toString();
    }
  };

  const openActionSheet = () => {
    if (actionSheetRef.current) {
      actionSheetRef.current.present();
    }
  };

  const openNewBuildModal = async () => {
    // Get the current user's UID
    const uid = auth.currentUser?.uid;
    if (!uid) {
      return false;
    }

    // Get the user's data from the cached data
    const cachedUser = getCachedUser();
    if (!cachedUser) {
      return false;
    }

    // Get the user's role from the cached data
    const userRole = cachedUser.role;

    // Set the build limits based on the user's role
    let buildLimit;
    if (userRole === "admin") {
      buildLimit = 5;
    } else if (userRole === "basic") {
      buildLimit = 5;
    } else {
      // Default for other roles or in case of any issues with role data
      buildLimit = 5;
    }

    // Query the builds collection to get the total number of builds for the user
    const buildsQuerySnapshot = await getDocs(
      query(collection(db, "builds"), where("userID", "==", uid))
    );
    const totalBuilds = buildsQuerySnapshot.size;

    //console.log("");

    // Check if the user has reached the build limit
    if (totalBuilds >= buildLimit) {
      // Display an alert if the limit is reached
      alert(
        "You have reached the maximum number of builds. Delete one more or more existing builds before creating a new one."
      );
    } else {
      // Open the new build modal
      if (newBuildModalRef.current) {
        newBuildModalRef.current.present();
      }
    }
  };

  const openAttachBuildModal = () => {
    if (attachBuildModalRef.current) {
      attachBuildModalRef.current.present();
    }
  };

  const closeAttachModal = () => {
    if (attachBuildModalRef.current) {
      attachBuildModalRef.current.dismiss();
    }
  };

  const openCaptureModal = (buildId: string) => {
    // Set the build ID using setAttachedBuildID
    setAttachedBuildID(buildId);

    //console.log(buildId);

    // Open the capture modal
    if (captureBuildModalRef.current) {
      captureBuildModalRef.current.present();
    }
  };

  const closeCaptureModal = () => {
    if (captureBuildModalRef.current) {
      captureBuildModalRef.current.dismiss();
    }
  };

  const openNewPostModal = () => {
    if (newPostModalRef.current) {
      newPostModalRef.current.present();
    }
  };

  const closeCreateModal = () => {
    if (newBuildModalRef.current) {
      newBuildModalRef.current.dismiss();
    }
  };

  const closePostModal = () => {
    if (newPostModalRef.current) {
      newPostModalRef.current.dismiss();
    }
    titleInputRef.current = '';
    messageInputRef.current = '';
    characterCountRef.current = 0;
    setIs3DToggled(false);
    removeCapturedImage();
  };

  const handleCaptureImage = (image: string | null) => {
    
    setCapturedImage(image);
    setIsImageCaptured(true);

    closeCaptureModal();
    closeAttachModal();
    
  };

  const removeCapturedImage = () => {
    // Remove the captured image and update the flag
    setCapturedImage(null);
    setIsImageCaptured(false);
  };

  return (
    <>
      <IonTabs>
        <IonRouterOutlet>
          <Switch>
            <Redirect exact path="/" to="/home" />
            <Route path="/home" render={() => <HomeScreen />} exact={true} />
            <Route path="/discover" render={() => <DiscoverScreen />} exact={true} />
            <Route path="/workshop" render={() => <WorkshopScreen />} exact={true} />
            <Route path="/notifications" render={() => <NotificationsScreen />} exact={true} />
            <Route path="/settings" render={() => <SettingsScreen />} exact={true} />

            {/*<Route path="/support-blokkiti" render={() => <Sponsorship />} exact={true} />*/}
            <Route path="/privacy" render={() => <PrivacyPolicy />} exact={true} />
            <Route path="/terms" render={() => <TermsOfService />} exact={true} />

            <Route path="/create" render={renderCreateScreen} />

            <Route path="/view/:username/:projectid" render={renderViewScreen} />
            <Route path="/:username" render={() => <ProfileScreen />} exact={true} />

            <Route path="/:username/post/:postid" render={renderPostScreen} />

            <Redirect from="*" to="/home" />
          </Switch>
        </IonRouterOutlet>
        <IonTabBar slot="bottom" className="overlay">
          <IonTabButton tab="home" href="/home" selected={isPathExact('/home')}>
            <IonIcon icon={homeOutline} />
            <IonLabel>Home</IonLabel>
          </IonTabButton>
          <IonTabButton tab="discover" href="/discover" selected={isPathExact('/discover')}>
            <IonIcon icon={compassOutline} />
            <IonLabel>Discover</IonLabel>
          </IonTabButton>
          <IonTabButton className="empty-tab-button" style={{ pointerEvents: 'none' }} />
          <IonTabButton tab="workshop" href="/workshop" selected={isPathExact('/workshop')}>
            <IonIcon icon={constructOutline} />
            <IonLabel>Workshop</IonLabel>
          </IonTabButton>
          <IonTabButton tab="notifications" href="/notifications" selected={isPathExact('/notifications')}>
            <IonIcon icon={notificationsOutline} />
            <IonLabel>Notifications</IonLabel>

            {/* Display the notifications count as an IonBadge */}
            {getCachedUser().notifications > 0 && <IonBadge className="notifications-badge" color="danger">{getCachedUser().notifications}</IonBadge>}
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
      
      <IonFab
        className="main-app-action-button"
        slot="fixed"
        vertical="bottom"
        horizontal="center"
        edge={true}
        style={{
          position: 'absolute',
          bottom: 'env(safe-area-inset-bottom, 0px)'
        }}
      >
        <IonFabButton
          className="newButton"
          onClick={openActionSheet}
          style={{ transform: 'scale(0.7)' }}
        >
          <IonIcon icon={add}></IonIcon>
        </IonFabButton>
      </IonFab>
      
      <IonActionSheet
        ref={actionSheetRef}
        buttons={[
          {
            text: 'New Build',
            icon: cubeOutline,
            handler: openNewBuildModal,
          },
          {
            text: 'New Post',
            icon: createOutline,
            handler: openNewPostModal,
          },
          {
            text: 'Cancel',
            role: 'cancel'
          }
        ]}
      />

      <IonModal id="newBuildModal" ref={newBuildModalRef}>
        <IonHeader>
          <IonToolbar color="medium" mode="ios">
            <IonButtons slot="start">
              <IonButton onClick={closeCreateModal}>Cancel</IonButton>
            </IonButtons>
            <IonTitle>New build</IonTitle>
            <IonButtons slot="end">
              <IonButton
                className="buildButton"
                onClick={handleCreateBuild}
                fill="solid"
                style={{ fontWeight: "bold" }}
                color="primary"
              >
                Build
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={{ width: '100%' }}>
              <IonItem className="buildNameInput">
                <IonInput
                  placeholder="Name your build..."
                  ref={newBuildInputRef}
                  maxlength={100}
                ></IonInput>
              </IonItem>
              <div className="new-build-illustration">
                <img src="/assets/images/new-build.svg" alt="illustration of floating cubes and magical particles" height="300px" width="400px" />
              </div>
            </div>
          </div>
        </IonContent>
      </IonModal>
      <IonModal id="captureBuildModal" ref={captureBuildModalRef}>
        <IonHeader>
          <IonToolbar color="medium" mode="ios">
            <IonButtons slot="start">
              <IonButton
                onClick={closeCaptureModal}
              >
                <IonIcon slot="start" icon={closeOutline} />
              </IonButton>
            </IonButtons>
            <IonTitle>Capture image</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="capture-window">
          <div className="build-preview-canvas-div-div">
            <div id="build-preview-canvas-div">
              <BuildPreviewCapture attachedBuildID={attachedBuildID} onCaptureImage={handleCaptureImage} />
            </div>
          </div>
        </IonContent>
      </IonModal>
      <IonModal id="attachBuildModal" ref={attachBuildModalRef}>
        <IonHeader>
          <IonToolbar color="medium" mode="ios">
            <IonButtons slot="start">
              <IonButton
                onClick={closeAttachModal}
              >
                Cancel
              </IonButton>
            </IonButtons>
            <IonTitle>Attach build</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={{ width: '100%' }}>
              {userBuilds.length === 0 ? (
                <div className="no-builds-text">
                  <img
                    src="/assets/images/no-builds.svg"
                    alt="illustration of a cube magically forming with particle floating around it"
                    height="200px"
                    width="400px"
                  />
                  <p>
                    <b>No builds yet</b>
                    <br />
                    <span>Click the big yellow + button below to start creating</span>
                  </p>
                </div>
              ) : (
                <IonList>
                  {userBuilds.map((build) => (
                    <IonItem
                      key={build.id}
                      detail={true}
                      lines="none"
                      button
                      onClick={() => openCaptureModal(build.id)}
                    >
                      <IonThumbnail slot="start">
                        {build.thumbnail ? (
                          <img src={build.thumbnail} alt="Project Thumbnail" style={{}} />
                        ) : (
                          <div
                            className="thumbnailBlank"
                            style={{
                              width: '100%',
                              height: '100%',
                              backgroundColor: '#d8ebf2',
                            }}
                          ></div>
                        )}
                      </IonThumbnail>
                      <IonLabel>
                        {build.name}
                        <span style={{ color: 'var(--ion-color-medium)', fontSize: '0.8em' }}>
                          <br />
                          Last modified {getFormattedLastUpdated(build.timestampUpdated)}
                        </span>
                      </IonLabel>
                    </IonItem>
                  ))}
                </IonList>
              )}
            </div>
          </div>
        </IonContent>
      </IonModal>
      <IonModal
        id="newPostModal"
        ref={newPostModalRef}
        onDidDismiss={() => {

          // Reset the input fields when the modal is dismissed
          if (titleInputRef.current) titleInputRef.current = '';
          messageInputRef.current = '';
          characterCountRef.current = 0;
          closePostModal();
        }}
      >
        <IonHeader>
          <IonToolbar color="medium" mode="ios">
            <IonButtons slot="start">
              <IonButton onClick={closePostModal}>Cancel</IonButton>
            </IonButtons>
            <IonTitle>Compose</IonTitle>
            <IonButtons slot="end">
              <IonButton
                className="postButton"
                onClick={handlePost}
                fill="solid"
                style={{ fontWeight: 'bold' }}
                color="primary"
                ref={postButtonRef}
                disabled={messageInputRef.current.trim() === ''}
              >
                Post
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={{ width: '100%' }}>
              <IonItem className="postTitleInput">
                <IonInput
                  placeholder="Title (optional)"
                  value={titleInputRef.current}
                  maxlength={200}
                  onIonChange={(e) => (titleInputRef.current = e.detail.value?.toString() ?? '')}
                ></IonInput>
              </IonItem>
              <IonItem className="postTextInput">
                <IonTextarea
                  className="messageInput"
                  placeholder="What's up?"
                  rows={5}
                  value={messageInputRef.current}
                  onInput={handleInputChange}
                  maxlength={1000}
                />
              </IonItem>
              <div className="messageDetails" style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '12px' }}>
                <span id="characterCount">{characterCountRef.current}</span> / 1000
              </div>
              <div className="attachmentDetails">
                {isImageCaptured && capturedImage ? (
                  <div style={{ position: 'relative' }}>
                    <div className="capturedImage">
                      <img src={capturedImage} alt="Captured Image" className="fade-in" />
                      <div className="toggleOverlay">
                        <button
                          className={`toggleButton ${is3DToggled ? "" : "active"}`}
                          onClick={() => setIs3DToggled(false)}
                        >
                          2D
                        </button>
                        <button
                          className={`toggleButton ${is3DToggled ? "active" : ""}`}
                          onClick={() => setIs3DToggled(true)}
                        >
                          3D
                        </button>
                      </div>
                    </div>
                    <div
                      className="removeButton"
                      onClick={removeCapturedImage}
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        width: '26px',
                        height: '26px',
                        borderRadius: '50%',
                        padding: '0',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        color: 'rgba(255, 255, 255, 1)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      <IonIcon icon={closeOutline} />
                    </div>
                  </div>

                ) : (
                  <div className="attachment-container">
                    <IonButton
                      className="attachButton"
                      fill="solid"
                      style={{ fontWeight: 'bold' }}
                      color="primary"
                      size="small"
                      onClick={openAttachBuildModal}
                    >
                      <IonIcon slot="start" icon={attachOutline} />
                      Attach build
                    </IonButton>
                  </div>
                )}
              </div>
            </div>
          </div>
        </IonContent>
      </IonModal>
      <IonToast
        id="showPostConfirmation"
        ref={postConfirmationToastRef}
        message="Your post was created."
        icon={checkmarkCircleOutline}
        color="medium"
        position="top"
        duration={3000}
        class="auto-width-toast"
      ></IonToast>
    </>
  );
};
export default TabNavigation;