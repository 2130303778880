import { IonPage, IonContent, IonText, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonButton, IonIcon, IonGrid, IonRow, IonCol } from '@ionic/react';
import { shareOutline, copyOutline, cubeOutline, linkOutline, chevronUpOutline, chevronDownOutline, chevronBackOutline, chevronForwardOutline, } from 'ionicons/icons';
import { RouteComponentProps, withRouter } from 'react-router';
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useRouteMatch, useHistory, useParams } from 'react-router-dom';
import { getFirestore, doc, updateDoc, getDoc, getDocs, onSnapshot, collection, query, where, limit, DocumentData, DocumentReference } from 'firebase/firestore';
import { auth, onAuthStateChange } from '../services/firebaseAuth';
import { User } from 'firebase/auth';
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

interface Object {
  id: string,
  shape: string,
  color: number,
  position: [number, number, number],
  scale: [number, number, number],
  rotation: [number, number, number, EulerOrder]
}
type EulerOrder = "XYZ" | "YZX" | "ZXY" | "XZY" | "YXZ" | "ZYX";

interface BuildPreviewCaptureProps {
  attachedBuildID: string;
  onCaptureImage: (image: string | null) => void;
}

const BuildPreviewCapture: React.FC<BuildPreviewCaptureProps> = ({ attachedBuildID, onCaptureImage }) => {

  const [canvasDimensions, setCanvasDimensions] = useState({ width: 800, height: 600 });
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const sceneRef = useRef<THREE.Scene | undefined>();
  const cameraRef = useRef<THREE.PerspectiveCamera | undefined>();
  const controlsRef = useRef<OrbitControls | null>(null);

  const [zoomLevel, setZoomLevel] = useState<string>("14.46");


  const [skyObjectRefs, setSkyObjectRefs] = useState<THREE.Mesh[]>([]);
  const [objectRefs, setObjectRefs] = useState<THREE.Mesh[]>([]);
  let floorMesh: THREE.Mesh | null = null;

  const [userAuthenticated, setUserAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userProject, setUserProject] = useState<any>(null);
  const [currentProjectId, setCurrentProjectId] = useState<string | undefined>(undefined);
  const [projectExists, setProjectExists] = useState<boolean>(false);

  const [projectName, setProjectName] = useState<string>("");
  
  const [createdBy, setCreatedBy] = useState<string>("");
  const [lastUpdated, setLastUpdated] = useState<string>("");
  const [blokkiCount, setBlokkiCount] = useState<number>(0);

  const [capturedImage, setCapturedImage] = useState<string | null>(null);

  const [rootDomain, setRootDomain] = useState<string>("");
  const history = useHistory();
  const user = auth.currentUser;
  const db = getFirestore();

  const [formattedLastUpdated, setFormattedLastUpdated] = useState<string>("");

  useEffect(() => {
    setCurrentProjectId(attachedBuildID);

    const fetchProjectInfo = async () => {
      if (!attachedBuildID) return;

      const projectsRef = collection(db, 'builds');
      const projectRef = doc(projectsRef, attachedBuildID);
      
      try {
        const projectDoc = await getDoc(projectRef);

        if (projectDoc.exists()) {
          setUserProject(projectDoc.data());
        } else {
          setUserProject(null);
        }
      } catch (error) {
        console.error('Error fetching project info:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProjectInfo();
  }, [attachedBuildID, db]);

  // Helper function to check if a file exists at a given URL
  async function checkFileExists(url: string) {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      return response.ok;
    } catch (error) {
      console.error(`Error checking if file exists at ${url}:`, error);
      return false;
    }
  }

  // Add objects from DB to the scene
  useEffect(() => {
    if (!currentProjectId) return;

    const projectsRef = collection(db, 'builds');
    const projectRef = currentProjectId ? doc(projectsRef, currentProjectId) : null;

    if (projectRef) {
      getDoc(projectRef).then((projectDoc) => {
        if (projectDoc.exists()) {
          setProjectExists(true);

          const projectData = projectDoc.data();
          setProjectName(projectData.name);
          setLastUpdated(projectData.timestampUpdated?.toDate() || null);

          const objectsData = projectData?.objects;
          setBlokkiCount(objectsData.length); // Set blokkiCount to the total number of objects

          // Create a Three.js Mesh for each object and add it to the scene
          objectsData.forEach(async (object: Object) => {
            const shapeFileName = `${object.shape}.gltf`;
            const objectFileUrl = `/assets/objects/${shapeFileName}`;

            // Check if the file exists before loading it
            const fileExists = await checkFileExists(objectFileUrl);
            if (!fileExists) {
              console.error(`File not found at ${objectFileUrl}`);
              return;
            }

            new GLTFLoader().load(objectFileUrl, (gltf) => {
              const mesh = gltf.scene.children[0] as THREE.Mesh;
              const euler = new THREE.Euler(
                object.rotation[0], // X angle
                object.rotation[1], // Y angle
                object.rotation[2], // Z angle
                object.rotation[3] // Euler order
              );
              const quaternion = new THREE.Quaternion().setFromEuler(euler);
              mesh.setRotationFromQuaternion(quaternion);
              mesh.position.set(
                object.position[0] as number,
                object.position[1] as number,
                object.position[2] as number
              );
              mesh.scale.set(
                object.scale[0] as number,
                object.scale[1] as number,
                object.scale[2] as number
              );
              const color = new THREE.Color(object.color);
              mesh.material = new THREE.MeshStandardMaterial({
                color: color,
                roughness: 1.0,
                metalness: 0.0,
                transparent: true,
                opacity: 1
              });

              mesh.castShadow = true;
              mesh.receiveShadow = true;
              mesh.userData.isSelectable = true;
              mesh.userData.color = object.color; // Add color value to userData
              mesh.userData.shape = object.shape; // Add shape value to userData
              mesh.userData.id = object.id;

              mesh.geometry.computeBoundingBox();

              sceneRef.current?.add(mesh);

              setObjectRefs((prevObjectRefs) => [...prevObjectRefs, mesh]);
            });
          });
        } else {
          // console.log('No objects found in database.'); // Debugging statement
          setProjectExists(false);
          history.push('/error');
        }
      });
    }
  }, [currentProjectId, db]);

  useEffect(() => {
    // Constants
    const colorPalette = {
      background: 0xd8ebf2,
      floor: 0xb4ccd6,
      cube: 0xe5eef2,
    };
    
    const boxDimensions = new THREE.Box3(
      new THREE.Vector3(-3, -3, -3),
      new THREE.Vector3(3, 3, 3)
    );
    const forbiddenArea = new THREE.Box3(
      new THREE.Vector3(-5, -0.0625, -5),
      new THREE.Vector3(5, 10.0625, 5)
    );
    const cubeCount = 20;
    const cubeSize = { width: 0.25, height: 0.25, depth: 0.25 };

    // Initialize the Three.js scene
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(colorPalette.background);
    scene.fog = new THREE.Fog(colorPalette.background, 8, 40);
    sceneRef.current = scene;


    // Create and set up the camera
    const camera = new THREE.PerspectiveCamera(50, 1920 / 1080, 0.1, 1000);
    camera.position.set(10, 3, 10);
    camera.lookAt(0, 0, 0); // Adjust the target position to y = 1

    cameraRef.current = camera;

    // Create the renderer
    const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current!, antialias: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    renderer.setSize(canvasDimensions.width, canvasDimensions.height);

    // Initialize the camera aspect ratio
    camera.aspect = canvasDimensions.width / canvasDimensions.height;
    camera.updateProjectionMatrix();

    // Initialize objects, lights, and controls
    const initScene = () => {
      // Create the floor
      const floorGeometry = new THREE.BoxGeometry(10, 0.0625, 10);
      const floorMaterial = new THREE.MeshStandardMaterial({ color: colorPalette.floor, visible: false });
      const floor = createMesh(floorGeometry, floorMaterial);
      floor.receiveShadow = true;
      floor.position.y = -0.15625;
      scene.add(floor);
    }

    // Create a mesh with specified geometry and material
    const createMesh = (geometry: THREE.BufferGeometry, material: THREE.Material) => {
      const mesh = new THREE.Mesh(geometry, material);
      mesh.material.transparent = true;
      mesh.material.opacity = 0.25;
      return mesh;
    };

    // Initialize lights
    const initLights = () => {
      // Lighting // Directional Light 1
      const dirLight = new THREE.DirectionalLight(0xffffff, 0.1); // Adjust color and intensity
      dirLight.position.set(2.15, 30, 2.15); // Adjust x, y, and z positions
      dirLight.target.position.set(0, 0, 0);
      dirLight.castShadow = true;
      dirLight.shadow.mapSize.width = 2048;
      dirLight.shadow.mapSize.height = 2048;
      dirLight.shadow.bias = -0.001; // Adjust as needed
      dirLight.shadow.normalBias = .02;
      dirLight.shadow.radius = 1;
      dirLight.shadow.camera.near = .1;
      dirLight.shadow.camera.far = 50;
      dirLight.shadow.camera.left = -15;
      dirLight.shadow.camera.right = 15;
      dirLight.shadow.camera.top = 15;
      dirLight.shadow.camera.bottom = -15;

      scene.add(dirLight);

      const ambientLight = new THREE.AmbientLight(0xffffff, .82); // color, intensity
      scene.add(ambientLight);

      // Lighting // Hemisphere Light 3
      const hemiLight3 = new THREE.HemisphereLight(0xf7efe3, 0x888888, .22); // Adjust color and intensity
      hemiLight3.position.set(15, 5, -3);

      hemiLight3.castShadow = false;
      scene.add(hemiLight3);
    };

    // Create random cubes outside the central part and forbidden area
    const createRandomCubes = () => {
      const geometry = new THREE.BoxGeometry(cubeSize.width, cubeSize.height, cubeSize.depth);
      const material = new THREE.MeshStandardMaterial({ color: colorPalette.cube });

      for (let i = 0; i < cubeCount; i++) {
        const cube = createMesh(geometry, material);
        cube.material.opacity = 1;

        let isInsideSceneBox = true;
        while (isInsideSceneBox) {
          cube.position.set(
            (Math.random() > 0.5 ? 1 : -1) * (Math.random() * 14 + 0),
            Math.random() * 40 - 20,
            (Math.random() > 0.5 ? 1 : -1) * (Math.random() * 14 + 0)
          );

          // Update cubeBox with the new cube position
          const cubeBox = new THREE.Box3().setFromObject(cube);

          // Check if the updated cubeBox intersects with forbiddenArea or boxDimensions
          isInsideSceneBox = boxDimensions.intersectsBox(cubeBox) || forbiddenArea.intersectsBox(cubeBox);
        }

        scene.add(cube);
        setSkyObjectRefs(prevSkyObjectRefs => [...prevSkyObjectRefs, cube]);
      }
    };

    // Set camera position and add orbit controls
    const initCameraControls = () => {
      const controls = new OrbitControls(camera, renderer.domElement);
      
      controlsRef.current = controls;
      
      controls.enableDamping = true;
      controls.dampingFactor = 0.05;
      controls.screenSpacePanning = false;
      controls.minDistance = 0.1;
      controls.maxDistance = 30;
      controls.maxPolarAngle = Math.PI / 1;
      controlsRef.current = controls; // Store the controls in a ref

      // Listen for the 'change' event on the controls
      controls.addEventListener('change', () => {
        const distance = controls.target.distanceTo(camera.position);
        setZoomLevel(distance.toFixed(2));
      });

    };

    // Initialize the scene, lights, cubes, and camera controls
    initScene();
    initLights();
    createRandomCubes();
    initCameraControls();

    const animate = () => {
      renderer.render(scene, camera);
      requestAnimationFrame(animate);

       if (controlsRef.current) {
        controlsRef.current.update();
      }
    };

    animate();


    // Clean up event listeners and Three.js objects on unmount
    return () => {

      controlsRef.current?.dispose();
      renderer.dispose();
      scene.remove(...scene.children);
    };
  }, []);

  const handleCaptureClick = () => {
    const canvas = canvasRef.current;

    if (!canvas) {
      return;
    }

    // Schedule the capture for the next frame
    requestAnimationFrame(() => {
      const context = canvas.getContext('2d');
      const dataUrl = canvas.toDataURL('image/png');

      const image = new Image();
      image.src = dataUrl;

      image.onload = () => {
        // Calculate the new dimensions while maintaining the aspect ratio
        const aspectRatio = image.width / image.height;
        const targetWidth = 1600;
        const targetHeight = 1200;

        // Calculate the dimensions of the cropped region
        let cropWidth, cropHeight;
        if (aspectRatio > targetWidth / targetHeight) {
          cropHeight = image.height;
          cropWidth = cropHeight * (targetWidth / targetHeight);
        } else {
          cropWidth = image.width;
          cropHeight = cropWidth / (targetWidth / targetHeight);
        }

        // Calculate the position to start cropping
        const cropX = (image.width - cropWidth) / 2;
        const cropY = (image.height - cropHeight) / 2;

        // Create a new canvas with the desired dimensions
        const newCanvas = document.createElement('canvas');
        newCanvas.width = targetWidth;
        newCanvas.height = targetHeight;

        const newContext = newCanvas.getContext('2d');

        if (newContext) {
          // Draw the cropped image on the new canvas
          newContext.drawImage(
            image,
            cropX,
            cropY,
            cropWidth,
            cropHeight,
            0,
            0,
            targetWidth,
            targetHeight
          );

          const newDataUrl = newCanvas.toDataURL('image/png');

          setCapturedImage(newDataUrl || null);

          if (typeof onCaptureImage === 'function') {
            onCaptureImage(newDataUrl || null);
          }
        }
      };
    });
  };

  const handleMoveUp = () => {
    if (controlsRef.current) {
      const controls = controlsRef.current;
      const target = controls.target;
      target.y += 0.1;
    }
  };

  const handleMoveDown = () => {
    if (controlsRef.current) {
      const controls = controlsRef.current;
      const target = controls.target;
      target.y -= 0.1;
    }
  };

  const handleMoveLeft = () => {
    if (controlsRef.current) {
      const controls = controlsRef.current;
      const target = controls.target;
      const camera = controls.object;

      const direction = camera.getWorldDirection(new THREE.Vector3());
      const cross = new THREE.Vector3().crossVectors(direction, camera.up).normalize();
      target.addScaledVector(cross, -0.1);
    }
  };

  const handleMoveRight = () => {
    if (controlsRef.current) {
      const controls = controlsRef.current;
      const target = controls.target;
      const camera = controls.object;

      const direction = camera.getWorldDirection(new THREE.Vector3());
      const cross = new THREE.Vector3().crossVectors(direction, camera.up).normalize();
      target.addScaledVector(cross, 0.1);
    }
  };

  return (
    <>
      
      <canvas
        ref={canvasRef}
        width="400"
        height="300"
        className="build-preview-canvas"
      />
      <div className="build-preview-viewfinder">
        <img src="/assets/images/capture-overlay.svg" width="400" height="300" />
      </div>
      <div className="build-preview-viewfinder">
        <div className="info-text">
          <div className="zoom-level">
            {zoomLevel}
          </div>
        </div>

        <div className="camera-controls left-buttons">
          <button
            className="move-left"
            onClick={handleMoveLeft}
            style={{
              borderRadius: '50%',
              backgroundColor: 'rgba(255, 255, 255, .5)',
              border: 'none',
              outline: 'none',
              cursor: 'pointer',
              transition: 'transform 0.1s, background-color 0.1s', // Add transition for smooth effect
              transform: 'scale(1)', // Initial scale
              animation: 'none', // Disable animation by default
            }}
            onMouseEnter={(e) => {
              // Scale up on hover
              e.currentTarget.style.transform = 'scale(1.1)';
              e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 1)'; // Change background color to white
            }}
            onMouseLeave={(e) => {
              // Reset scale and background color on hover exit
              e.currentTarget.style.transform = 'scale(1)';
              e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, .5)'; // Change background color back to the original
            }}
          >
            <IonIcon icon={chevronBackOutline} className="icon-small" color="dark" />
          </button>
          <button
            className="move-right"
            onClick={handleMoveRight}
            style={{
              borderRadius: '50%',
              backgroundColor: 'rgba(255, 255, 255, .5)',
              border: 'none',
              outline: 'none',
              cursor: 'pointer',
              transition: 'transform 0.1s, background-color 0.1s', // Add transition for smooth effect
              transform: 'scale(1)', // Initial scale
              animation: 'none', // Disable animation by default
            }}
            onMouseEnter={(e) => {
              // Scale up on hover
              e.currentTarget.style.transform = 'scale(1.1)';
              e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 1)'; // Change background color to white
            }}
            onMouseLeave={(e) => {
              // Reset scale and background color on hover exit
              e.currentTarget.style.transform = 'scale(1)';
              e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, .5)'; // Change background color back to the original
            }}
          >
            <IonIcon icon={chevronForwardOutline} className="icon-small" color="dark" />
          </button>
        </div>

        <div className="camera-controls right-buttons">
          <button
            className="move-up"
            onClick={handleMoveUp}
            style={{
              borderRadius: '50%',
              backgroundColor: 'rgba(255, 255, 255, .5)',
              border: 'none',
              outline: 'none',
              cursor: 'pointer',
              transition: 'transform 0.1s, background-color 0.1s', // Add transition for smooth effect
              transform: 'scale(1)', // Initial scale
              animation: 'none', // Disable animation by default
            }}
            onMouseEnter={(e) => {
              // Scale up on hover
              e.currentTarget.style.transform = 'scale(1.1)';
              e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 1)'; // Change background color to white
            }}
            onMouseLeave={(e) => {
              // Reset scale and background color on hover exit
              e.currentTarget.style.transform = 'scale(1)';
              e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, .5)'; // Change background color back to the original
            }}
          >
            <IonIcon icon={chevronUpOutline} className="icon-small" color="dark" />
          </button>
          <button
            className="move-down"
            onClick={handleMoveDown}
            style={{
              borderRadius: '50%',
              backgroundColor: 'rgba(255, 255, 255, .5)',
              border: 'none',
              outline: 'none',
              cursor: 'pointer',
              transition: 'transform 0.1s, background-color 0.1s', // Add transition for smooth effect
              transform: 'scale(1)', // Initial scale
              animation: 'none', // Disable animation by default
            }}
            onMouseEnter={(e) => {
              // Scale up on hover
              e.currentTarget.style.transform = 'scale(1.1)';
              e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 1)'; // Change background color to white
            }}
            onMouseLeave={(e) => {
              // Reset scale and background color on hover exit
              e.currentTarget.style.transform = 'scale(1)';
              e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, .5)'; // Change background color back to the original
            }}
          >
            <IonIcon icon={chevronDownOutline} className="icon-small" color="dark" />
          </button>
        </div>

      </div>

      <div
        className="capture-overlay"
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-end',
          position: 'absolute',
          top: '0',
          objectFit: 'cover',
          pointerEvents: 'none',
        }}
      >
        <div
          style={{
            width: '76px',
            height: '76px',
            borderRadius: '50%',
            border: '4px solid white',
            backgroundColor: 'rgba(0, 0, 0, 0.15)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '20px',
            pointerEvents: 'auto',
          }}
        >
          <button
            style={{
              width: '60px',
              height: '60px',
              borderRadius: '50%',
              backgroundColor: '#f5fafc',
              border: 'none',
              outline: 'none',
              cursor: 'pointer',
              transition: 'transform 0.1s, background-color 0.1s', // Add transition for smooth effect
              transform: 'scale(1)', // Initial scale
              animation: 'none', // Disable animation by default
            }}
            onMouseEnter={(e) => {
              // Scale up on hover
              e.currentTarget.style.transform = 'scale(1.1)';
              e.currentTarget.style.backgroundColor = '#ffffff'; // Change background color to white
            }}
            onMouseLeave={(e) => {
              // Reset scale and background color on hover exit
              e.currentTarget.style.transform = 'scale(1)';
              e.currentTarget.style.backgroundColor = '#f5fafc'; // Change background color back to the original
            }}
            onClick={(e) => {
              // Bounce effect when clicked
              e.currentTarget.style.animation = 'bounce 0.1s';
              e.currentTarget.style.transform = 'scale(0.9)';
              handleCaptureClick();
            }}
            onAnimationEnd={(e) => {
              // Reset animation and scale after bounce
              e.currentTarget.style.animation = 'none';
              e.currentTarget.style.transform = 'scale(1)';
            }}
          />

        </div>
      </div>
    </>
  );

};

export default BuildPreviewCapture;
