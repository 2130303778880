// React Components
import React, { useState, useRef, useEffect } from 'react';
import { RouteComponentProps, withRouter, useRouteMatch, } from 'react-router';
import { useParams, Link, useLocation, useHistory, } from 'react-router-dom';

import {
  IonPage,
  IonContent,
  IonSpinner,
  IonText,
  IonIcon,
  IonAvatar,
  IonModal,
  IonToast,
  IonButton,
  IonButtons,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonLabel,
  IonTextarea,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonInput,
  IonCard,
  IonCardContent,
  IonPopover,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRouterLink,
  IonAlert,
} from '@ionic/react';
import {
  personCircleOutline,
  informationCircleOutline,
  calendarOutline,
  shareSocialOutline,
  linkOutline,
  chatbubbleOutline,
  timeOutline,
  heart,
  heartOutline,
  addCircleOutline,
  repeatOutline,
  shareOutline,
  copyOutline,
  bookmarkOutline,
  ellipsisVertical,
  ellipsisHorizontal,
  trashOutline,
  personAddOutline,
  personRemoveOutline,
  happy,
  happyOutline,
  logInOutline,
} from 'ionicons/icons';

// Firebase Services
import firebase from 'firebase/compat/app';
import { auth } from '../../services/firebaseAuth';
import {
  getFirestore,
  DocumentSnapshot,
  onSnapshot,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  addDoc,
  deleteDoc,
  query,
  collection,
  where,
  limit,
  orderBy,
  startAfter,
  FieldValue,
  writeBatch,
  increment,
  Timestamp,
} from 'firebase/firestore';

// Libraries
import * as linkify from 'linkifyjs';
import linkifyHtml from 'linkify-html';

// Custom Utilities & Services
import { getCachedUser, cacheUser } from "../../services/userCache";
import UserAvatar from "../../components/UserAvatar";
import {
  getFormattedCreatedAt,
  getContrastingTextColor,
  isValidUrl,
  getWebsiteDomain,
  isSingleEmoji,
  getBaseDomain,
  convertUsernameMentionsToLinks,
  shortenNumber
} from '../../utils/UtilityFunctions';
import SharePopover from "../../components/SharePopover";
import SignInModal from '../../components/SignInModal';
import BuildPreviewLive from '../../components/BuildPreviewLive';

// Custom Components and Style Sheets
import HeaderToolbar from '../../components/HeaderToolbar';
import CommentSection from '../../components/CommentSection';
import './ViewScreen.css';

type Props = RouteComponentProps;

const ViewScreen: React.FC<Props> = ({  }) => {

  const [canvasDimensions, setCanvasDimensions] = useState({ width: window.innerWidth, height: window.innerHeight / 1.5 });
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [displayName, setDisplayName] = useState<string>('');
  const [profileColor, setProfileColor] = useState<string>('');
  const [initials, setInitials] = useState<string>('');
  const { username } = useParams<{ username: string }>();
  const [userAuthenticated, setUserAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userProject, setUserProject] = useState<any>(null);
  const [currentProjectId, setCurrentProjectId] = useState<string | undefined>(undefined);
  const [projectExists, setProjectExists] = useState<boolean>(false);
  const [projectTitle, setProjectTitle] = useState<string>("");

  const [timestampLive, setTimestampLive] = useState<string>('');
  const [isLive, setIsLive] = useState<boolean>(false);

  const [rootDomain, setRootDomain] = useState<string>("");
  const history = useHistory();
  const user = auth.currentUser;
  const match = useRouteMatch<{ username: string; projectid: string }>('/view/:username/:projectid');
  const db = getFirestore();

  const [objectsData, setObjectsData] = useState<any[]>([]);

  useEffect(() => {
    if (!currentProjectId || !username) return;

    // Reference to the Firestore document that contains the array of objects
    const projectsRef = collection(db, 'builds');
    const projectRef = doc(projectsRef, currentProjectId);

    // Create a snapshot listener to listen for real-time updates
    const unsubscribe = onSnapshot(projectRef, (snapshot) => {
      if (snapshot.exists()) {
        // Update the component state with the latest data from the Firestore document
        const projectData = snapshot.data();
        setUserProject(projectData);
        setObjectsData(projectData?.objects || []);
        setIsLive(projectData?.live || false); // Set the 'live' state based on the fetched data
        setProjectTitle(projectData?.name);
        setTimestampLive(projectData?.timestampLive);

        // Calculate the timestamp for 1.5 minutes ago
        const oneAndHalfMinutesAgo = Date.now() - 90000; // 1.5 minutes = 90,000 milliseconds

        // If 'timestampLive' is older than 1.5 minutes from the current time, set 'live' to false in the document
        if (projectData.timestampLive?.toDate().getTime() < oneAndHalfMinutesAgo) {
          try {
            // Update the 'live' field to false in Firestore
            updateDoc(projectRef, { live: false });
            setIsLive(false)
            return;
          } catch (error) {
            // Handle the error
          }
        }
      } else {
        setUserProject(null);
      }
    });

    // Define the async function to fetch the user's data
    const fetchUserData = async () => {
      try {
        // Get the reference to the 'users' collection
        const usersCollectionRef = collection(db, 'users');

        // Create a query to find the document where 'username' matches the URL username
        const querySnapshot = await getDocs(query(usersCollectionRef, where('username', '==', username)));

        // Check if the user exists
        if (!querySnapshot.empty) {
          // Get the first document (assuming there's only one document with the same username)
          const userData = querySnapshot.docs[0].data();
          // Get the 'displayName' from the user's data
          const userDisplayName = userData.displayName;
          const userProfileColor = userData.profileColor;
          const userInitials = userData.initials;
          // Update the state with the 'displayName'
          setDisplayName(userDisplayName);
          setProfileColor(userProfileColor);
          setInitials(userInitials);
        } else {
          // If the user doesn't exist or there was an error, handle it here
          console.log('User not found');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    // Call the function to fetch the user data
    fetchUserData();

    // Cleanup the snapshot listener when the component unmounts
    return () => unsubscribe();
  }, [currentProjectId, username, db]);

  // Helper function to check if a file exists at a given URL
  async function checkFileExists(url: string) {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      return response.ok;
    } catch (error) {
      console.error(`Error checking if file exists at ${url}:`, error);
      return false;
    }
  }

  useEffect(() => {
    if (match?.params) {
      const { username, projectid } = match.params;
      setCurrentProjectId(match.params.projectid);
    }
  }, [match]);

  const handleShare = () => {
    const currentURL = window.location.href;
    // Use the appropriate sharing mechanism based on your requirements
    // For example, you can use the Web Share API or open a sharing dialog
    if (navigator.share) {
      navigator.share({
        title: 'Share Current Page',
        url: currentURL
      })
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.log('Error sharing:', error));
    } else {
      // Fallback for browsers that do not support the Web Share API
      console.log('Sharing not supported');
      // You can customize the sharing behavior for non-supported browsers here
    }
  };

  const handleCopyLink = () => {
    const currentURL = window.location.href;
    navigator.clipboard.writeText(currentURL)
      .then(() => console.log('Link copied successfully'))
      .catch((error) => console.log('Error copying link:', error));
  };

  return (
    <IonPage id="main-content">
      <HeaderToolbar showBackButton />
      <IonContent>
        <div className="limitedWidth view-screen">
          <IonCard style={{ width: "100%" }} className="postItem">
            <IonCardContent className="singlePostContent">
              <div className="post-preview-canvas-div-div">
                <div id="post-preview-canvas-div" className={isLive ? '' : 'offline'}>

                  {isLive ? (
                    <>
                      <BuildPreviewLive attachedBuildObjects={objectsData} />
                      <div className="liveOverlayLabel">LIVE</div>
                    </>
                  ) : (
                    // Add content for the else block if necessary
                    <>
                      <div className="offlineOverlayLabel">OFFLINE</div>
                    </>
                  )}

                </div>
              </div>
            </IonCardContent>
          </IonCard>
          <IonCard style={{ width: "100%" }} className="postItem">
            <IonCardContent className="singlePostContent">
              <div className="postCredits">
                <UserAvatar
                  profileColor={profileColor}
                  initials={initials}
                />
                <div className="postText">
                  <p>
                    <IonText style={{ fontWeight: "bold" }}>
                      <span className="display-name">{displayName}</span>
                    </IonText>{" "}
                    <IonText color="medium">
                      <a
                        href={`/${username}`}
                        style={{ color: "inherit", textDecoration: "none" }}
                        className="hoverUnderline"
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      >
                        @{username}
                      </a>
                    </IonText>
                  </p>
                  <p className="post-text-line">
                    {projectTitle}
                  </p>
                </div>
              </div>
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default withRouter(ViewScreen);
