import React from 'react';
import { IonModal, IonHeader, IonToolbar, IonButtons, IonButton, IonTitle, IonContent } from '@ionic/react';

interface SignInModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const SignInModal: React.FC<SignInModalProps> = ({ isOpen, onClose }) => {
  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onClose}>Close</IonButton>
          </IonButtons>
          <IonTitle>Please Sign In</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <p>Please sign in to perform this action.</p>
        {/* Sign-in form or sign-in options can be added here */}
      </IonContent>
    </IonModal>
  );
};

export default SignInModal;
