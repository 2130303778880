import React, { useEffect, useState, useRef } from 'react';
import {
  IonCard,
  IonToast,
  IonCardContent,
  IonAvatar,
  IonText,
  IonButton,
  IonIcon,
  IonPopover,
  IonItem,
  IonAlert,
  IonContent,
  IonList,
  IonLabel,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonModal,
  IonSpinner,
  IonRouterLink,
} from '@ionic/react';
import {
  getFirestore,
  collection,
  doc,
  addDoc,
  query,
  where,
  getDocs,
  updateDoc,
  orderBy,
  increment,
  DocumentData,
  getDoc,
  DocumentReference,
  deleteDoc,
  writeBatch,
  limit
} from 'firebase/firestore';
import {
  chatbubbleOutline,
  happy,
  happyOutline,
  checkmarkCircle,
  checkmarkCircleOutline,
  personAddOutline,
  personRemoveOutline,
  ellipsisVertical,
  ellipsisHorizontal,
  trashOutline,
  heart,
  heartOutline,
  bookmarkOutline,
  shareOutline,
  copyOutline,
  shareSocialOutline
} from 'ionicons/icons';
import UserAvatar from "../components/UserAvatar";
import {
  getFormattedCreatedAt,
  getContrastingTextColor,
  isValidUrl,
  getWebsiteDomain,
  isSingleEmoji,
  getBaseDomain,
  convertUsernameMentionsToLinks,
  shortenNumber
} from '../utils/UtilityFunctions';
import ReplyModal from '../components/ReplyModal';
import SharePopover from "../components/SharePopover";
import SignInModal from '../components/SignInModal';

// Libraries
import * as linkify from 'linkifyjs';
import linkifyHtml from 'linkify-html';

type Comment = {
  id: string;
  userID: string;
  title: string;
  text: string;
  rootID: string;
  parentID: string;
  timestamp: number;
  replyCount: number;
  likeCount: number;
  likes: Like[];
  user?: User;
  replies?: Comment[];
};

type Like = {
  userID: string;
  postID: string;
  timestamp: number;
};

type User = {
  displayName: string;
  username: string;
  initials: string;
  profileColor: string;
};

type CommentSectionProps = {
  postId: string;
  commentCount: number;
  currentUserUid: string | undefined; // Add this line
};

const CommentSection: React.FC<CommentSectionProps> = ({
  postId,
  commentCount,
  currentUserUid,
}) => {
  const [comments, setComments] = useState<Comment[]>([]);
  const [followedUserIDs, setFollowedUserIDs] = useState<string[]>([]);
  const [author, setAuthor] = useState<User | null>(null);

  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const [selectedComment, setSelectedComment] = useState<Comment | null>(null);
  const [isReplyModalOpen, setReplyModalOpen] = useState(false);

  const [deleteAlert, setDeleteAlert] = useState<string | undefined>(undefined);
  const [commentToDelete, setCommentToDelete] = useState<Comment | null>(null);

  const deleteCommentConfirmationToastRef = useRef<HTMLIonToastElement>(null);
  const submitCommentConfirmationToastRef = useRef<HTMLIonToastElement>(null);
  const copyConfirmationToastRef = useRef<HTMLIonToastElement>(null);
  const followConfirmationToastRef = useRef<HTMLIonToastElement>(null);
  const unfollowConfirmationToastRef = useRef<HTMLIonToastElement>(null);

  const [showSignInModal, setShowSignInModal] = useState(false);

  const db = getFirestore();

  useEffect(() => {
    const fetchComments = async () => {

      setIsUpdating(true);

      const commentsRef = collection(db, 'posts');
      const commentsQuery = query(
        commentsRef,
        where('parentID', '==', postId),
        orderBy('timestamp', 'desc')
      );

      const commentsSnapshot = await getDocs(commentsQuery);

      const commentsData: Comment[] = [];

      commentsSnapshot.forEach((doc) => {
        const commentData = doc.data() as Comment;
        const comment: Comment = {
          id: doc.id,
          userID: commentData.userID,
          text: commentData.text,
          title: commentData.title,
          rootID: commentData.rootID,
          parentID: commentData.parentID,
          timestamp: commentData.timestamp,
          replyCount: commentData.replyCount,
          likeCount: commentData.likeCount,
          likes: [],
        };
        commentsData.push(comment);
      });

      const commentsWithUserInformation = await Promise.all(
        commentsData.map(async (comment) => {
          const userDocRef = doc(collection(db, 'users'), comment.userID);
          const userDoc = await getDoc(userDocRef);
          const userData = userDoc.data() as User;
          return {
            ...comment,
            user: userData,
          };
        })
      );

      const commentIds = commentsData.map((comment) => comment.id);

      if (commentIds.length === 0) {
        setIsUpdating(false);
        setComments([]);
        return; // Exit the function if there are no commentIds
      }

      if (currentUserUid) {
        const likesQuery = query(
          collection(db, 'likes'),
          where('postID', 'in', commentIds),
          where('userID', '==', currentUserUid)
        );
        const likesSnapshot = await getDocs(likesQuery);

        likesSnapshot.forEach((likeDoc) => {
          const likeData = likeDoc.data() as Like;
          const postId = likeData.postID;
          const commentIndex = commentsData.findIndex((comment) => comment.id === postId);
          if (commentIndex !== -1) {
            commentsData[commentIndex].likes.push(likeData);
          }
        });

        const followsQuery = query(
          collection(db, 'follows'),
          where('followerID', '==', currentUserUid),
          where('followingID', 'in', commentsData.map((comment) => comment.userID))
        );
        const followsSnapshot = await getDocs(followsQuery);
        const followedUserIDs = followsSnapshot.docs.map((doc) => doc.data().followingID);
        setFollowedUserIDs(followedUserIDs);
      }

      const fetchReplies = async (comment: Comment): Promise<Comment> => {
        const repliesRef = collection(db, 'posts');
        const repliesQuery = query(
          repliesRef,
          where('parentID', '==', comment.id),
          orderBy('likeCount', 'desc'), // Sort replies by likeCount in descending order
          limit(1) // Fetch only one reply per comment
        );

        const repliesSnapshot = await getDocs(repliesQuery);
        const repliesData: Comment[] = [];

        repliesSnapshot.forEach((doc) => {
          const replyData = doc.data() as Comment;
          const reply: Comment = {
            id: doc.id,
            userID: replyData.userID,
            text: replyData.text,
            title: replyData.title,
            rootID: replyData.rootID,
            parentID: replyData.parentID,
            timestamp: replyData.timestamp,
            replyCount: replyData.replyCount,
            likeCount: replyData.likeCount,
            likes: [],
          };
          repliesData.push(reply);
        });

        const repliesWithUserInformation = await Promise.all(
          repliesData.map(async (reply) => {
            const userDocRef = doc(collection(db, 'users'), reply.userID);
            const userDoc = await getDoc(userDocRef);
            const userData = userDoc.data() as User;
            return {
              ...reply,
              user: userData,
            };
          })
        );

        const commentRepliesWithLikes = await Promise.all(
          repliesWithUserInformation.map(async (reply) => {
            if (currentUserUid) {
              const likesQuery = query(
                collection(db, 'likes'),
                where('postID', '==', reply.id),
                where('userID', '==', currentUserUid)
              );
              const likesSnapshot = await getDocs(likesQuery);
              likesSnapshot.forEach((likeDoc) => {
                const likeData = likeDoc.data() as Like;
                reply.likes.push(likeData);
              });
            }
            return reply;
          })
        );

        comment.replies = commentRepliesWithLikes;

        return comment;
      };

      const commentsWithReplies = await Promise.all(
        commentsWithUserInformation.map((comment) => fetchReplies(comment))
      );

      // Sort the comments based on popularity score, replyCount, and likeCount
      const sortedComments = commentsWithReplies.sort((a, b) => {
        const aPopularity = a.replyCount + a.likeCount;
        const bPopularity = b.replyCount + b.likeCount;

        if (aPopularity !== bPopularity) {
          return bPopularity - aPopularity; // Sort by popularity score in descending order
        }
        if (a.replyCount !== b.replyCount) {
          return b.replyCount - a.replyCount; // Sort by replyCount in descending order
        }
        return b.timestamp - a.timestamp; // Sort by timestamp in descending order
      });

      setIsUpdating(false);

      setComments(sortedComments);
    };

    fetchComments();
  }, [postId]);

  useEffect(() => {
    const fetchUserInformation = async () => {

      const usersRef = collection(db, 'users');

      const fetchUserRecursive = async (comment: Comment): Promise<Comment> => {
        if (comment.userID === 'Deleted') {
          // Set default values for deleted users
          const userData: User = {
            displayName: 'Deleted',
            username: 'deleted',
            initials: '',
            profileColor: '#7a93a0',
          };

          return {
            ...comment,
            user: userData,
          };
        }

        const userDocRef = doc(usersRef, comment.userID);
        const userDoc = await getDoc(userDocRef);

        if (!userDoc.exists()) {
          // Handle the case where the user document doesn't exist (e.g., deleted user)
          const userData: User = {
            displayName: 'Deleted',
            username: 'deleted',
            initials: '',
            profileColor: '#888888',
          };

          return {
            ...comment,
            user: userData,
          };
        }

        const userData = userDoc.data() as User;
        const commentWithUser: Comment = {
          ...comment,
          user: userData,
        };
        return commentWithUser;
      };

      const commentsWithUserInformation = await Promise.all(comments.map(fetchUserRecursive));

      setComments(commentsWithUserInformation); // Update the state with the updated commentsWithUserInformation array
    };

    if (comments.length > 0) {
      fetchUserInformation();
    }
  }, []);

  const toggleFollow = async (userID: string, username: string) => {
    if (!currentUserUid) {
      return; // Exit the function if currentUserUid is undefined
    }

    const followsCollectionRef = collection(db, 'follows');
    const followQuerySnapshot = await getDocs(followsCollectionRef);
    const followDocs = followQuerySnapshot.docs;
    const currentUserFollowDoc = followDocs.find(
      (doc) => doc.data().followerID === currentUserUid && doc.data().followingID === userID
    );
    const isFollowed = !!currentUserFollowDoc;

    const batch = writeBatch(db);

    if (isFollowed) {
      batch.delete(currentUserFollowDoc.ref);
      unfollowConfirmationToastRef.current?.present();
    } else {
      const followsCollectionRef = collection(db, 'follows');

      // Check if an existing follow document already exists
      const existingFollowQuerySnapshot = await getDocs(
        query(
          followsCollectionRef,
          where('followerID', '==', currentUserUid),
          where('followingID', '==', userID)
        )
      );
      const existingFollowDocs = existingFollowQuerySnapshot.docs;

      if (existingFollowDocs.length === 0) {
        const newFollow = {
          followerID: currentUserUid,
          followingID: userID,
          timestamp: new Date(),
        };
        const newFollowRef = doc(followsCollectionRef);
        batch.set(newFollowRef, newFollow);
        followConfirmationToastRef.current?.present();

        // Add a new notification document
        const notificationsCollectionRef = collection(db, 'notifications');
        const newNotification = {
          userID: userID,
          timestamp: new Date(),
          type: 'follow',
          followerID: currentUserUid,
          unread: true,
        };
        const newNotificationRef = await addDoc(notificationsCollectionRef, newNotification);
        //console.log('New notification added with ID: ', newNotificationRef.id);

        // Increase the 'notifications' count for the user being followed
        const userRef = doc(collection(db, 'users'), userID);
        batch.update(userRef, {
          notifications: increment(1), // Increase the 'notifications' count by 1
        });
        
      }
    }

    // Decrease/increase the 'following' count for the signed-in user
    const currentUserRef = doc(collection(db, 'users'), currentUserUid);
    batch.update(currentUserRef, {
      following: increment(isFollowed ? -1 : 1),
    });

    // Decrease/increase the 'followers' count for the user being followed/unfollowed
    const userRef = doc(collection(db, 'users'), userID);
    batch.update(userRef, {
      followers: increment(isFollowed ? -1 : 1),
    });

    await batch.commit();

    setComments((prevComments) => {
      const updatedComments = prevComments.map((comment) => {
        if (comment.user && comment.user.username === username) {
          return {
            ...comment,
            isFollowed: !isFollowed,
          };
        }
        return comment;
      });
      return updatedComments;
    });

    setFollowedUserIDs((prevFollowedUserIDs) => {
      if (isFollowed) {
        return prevFollowedUserIDs.filter((id) => id !== userID);
      } else {
        return [...prevFollowedUserIDs, userID];
      }
    });
  };

  const toggleLike = async (postId: string) => {
    if (!currentUserUid) {
      return; // Exit the function if currentUserUid is undefined
    }

    const likeCollectionRef = collection(db, 'likes');
    const likeQuerySnapshot = await getDocs(likeCollectionRef);
    const likeDocs = likeQuerySnapshot.docs;
    const currentUserLikeDoc = likeDocs.find(
      (doc) => doc.data().userID === currentUserUid && doc.data().postID === postId
    );
    const isLiked = !!currentUserLikeDoc;

    const postRef = doc(collection(db, 'posts'), postId);
    const postDoc = await getDoc(postRef);
    const post = postDoc.data() as Comment;

    const batch = writeBatch(db);

    if (isLiked) {
      batch.delete(currentUserLikeDoc.ref);
      batch.update(postRef, { likeCount: post.likeCount - 1 });
    } else {
      const newLike: Like = {
        userID: currentUserUid,
        postID: postId,
        timestamp: Date.now(),
      };
      const newLikeRef = doc(collection(db, 'likes'));
      batch.set(newLikeRef, newLike);
      batch.update(postRef, { likeCount: post.likeCount + 1 });
    }

    await batch.commit();

    setComments((prevComments) => {
      const updatedComments = prevComments.map((comment) => {
        if (comment.id === postId) {
          return {
            ...comment,
            likeCount: isLiked ? comment.likeCount - 1 : comment.likeCount + 1,
            likes: isLiked
              ? comment.likes.filter((like) => like.userID !== currentUserUid)
              : [...comment.likes, { userID: currentUserUid, postID: postId, timestamp: Date.now() }],
          };
        } else if (comment.replies && comment.replies.length > 0) {
          // Update the state for replies if present
          const updatedReplies = comment.replies.map((reply) => {
            if (reply.id === postId) {
              return {
                ...reply,
                likeCount: isLiked ? reply.likeCount - 1 : reply.likeCount + 1,
                likes: isLiked
                  ? reply.likes.filter((like) => like.userID !== currentUserUid)
                  : [...reply.likes, { userID: currentUserUid, postID: postId, timestamp: Date.now() }],
              };
            }
            return reply;
          });

          return {
            ...comment,
            replies: updatedReplies,
          };
        }

        return comment;
      });

      return updatedComments;
    });
  };

  const openReplyModal = (comment: Comment) => {
    const parentID = comment.parentID ? comment.parentID : comment.id;
    setSelectedComment({ ...comment, parentID });
    setReplyModalOpen(true);
  };

  const closeReplyModal = () => {
    setSelectedComment(null);
    setReplyModalOpen(false);
  };

  const handleReplySubmit = async (replyText: string) => {
    if (!selectedComment || !currentUserUid) {
      return;
    }

    const commentsRef = collection(db, 'posts');
    
    // Create a new comment document with the reply data
    const replyData = {
      userID: currentUserUid,
      timestamp: new Date(),
      rootID: selectedComment.rootID || selectedComment.id,
      parentID: selectedComment.id,
      text: replyText.replace(/\n/g, "<br>"), // Replace line breaks with <br> tags
      likeCount: 0,
      replyCount: 0,
      image: "",
      blueprint: "",
    };

    try {
      // Add the reply document to the 'posts' collection
      const replyDocRef: DocumentReference<DocumentData> = await addDoc(commentsRef, replyData);

      // Retrieve the newly added reply from the database
      const replySnapshot = await getDoc(replyDocRef);
      const newReplyData = replySnapshot.data() as Comment; // Cast the data to the Comment type

      // Update the replyCount field of the parent comment
      setComments((prevComments) => {
        const updatedComments = [...prevComments];

        const updateReplyCount = (comments: Comment[]) => {
          for (let i = 0; i < comments.length; i++) {
            const comment = comments[i];

            if (comment.id === selectedComment.parentID) {
              comment.replyCount += 1; // Increment the replyCount
              break;
            }
          }
        };

        updateReplyCount(updatedComments);
        return updatedComments;
      });

      // Update the replyCount field of the parent comment
      await updateDoc(doc(commentsRef, selectedComment.id), {
        replyCount: increment(1),
      });

      closeReplyModal();
    } catch (error) {
      //console.error('Error adding reply to the database:', error);
    }
  };

  const handleDeleteConfirm = async () => {
    if (commentToDelete) {
      try {
        const postRef = doc(db, 'posts', commentToDelete.id);
        
        // Update the comment document to clear the userID and text fields
        await updateDoc(postRef, {
          userID: 'Deleted',
          text: 'Deleted',
          blueprint: [],
          image: '',
        });
        
        //console.log('Comment deleted successfully');
        deleteCommentConfirmationToastRef.current?.present();
        
        // Update the state to remove the deleted comment
        setComments((prevComments) => {
          const updatedComments = prevComments.filter((c) => c.id !== commentToDelete.id);
          return updatedComments;
        });
      } catch (error) {
        //console.error('Error deleting comment:', error);
      }
    }
  };

  const openDeleteAlert = (comment: Comment) => {
    setCommentToDelete(comment);
    setDeleteAlert(`comment-delete-alert-${comment.id}`);
  };

  const closeDeleteAlert = () => {
    setCommentToDelete(null);
    setDeleteAlert(undefined);
  };

  const openSignInModal = () => {
    setShowSignInModal(true);
  };

  const closeSignInModal = () => {
    setShowSignInModal(false);
  };

  const renderComment = (comment: Comment) => {
    const isFollowed = followedUserIDs.includes(comment.userID); // Check if the post author is followed by the current user
    const isLikedByCurrentUser = comment.likes.some(like => like.userID === currentUserUid);
    const isCurrentUserComment = currentUserUid === comment.userID; // Check if the comment is written by the signed-in user
    const isDeletedUser = comment.userID === 'Deleted'; // Check if the user is deleted

    const isReplyLikedByCurrentUser = comment.replies?.some((reply) =>
      reply.likes.some((like) => like.userID === currentUserUid)
    );

    const isReplyDeletedUser = comment.replies?.some((reply) =>
      reply.userID === 'Deleted'
    );

    const isCurrentUserReply = comment.replies?.some((reply) =>
      reply.userID === currentUserUid
    );

    return (
      <>
        <div className="comment-group">
          <a className="post-item-link" href={`/${comment.user?.username || 'deleted'}/post/${comment.id}`}>
             <IonItem
                button
                //routerLink={`/${comment.user?.username || 'deleted'}/post/${comment.id}`}
                //key={comment.id}
                detail={false}
                lines="none"
                className={`commentItem firstLevel ${isDeletedUser ? 'deleted-post' : ''}`}
              >
                <IonCard style={{ width: "100%" }}>
                  <IonCardContent className="singleCommentContent">
                    {isDeletedUser && (
                      <div className="postCredits">
                        <UserAvatar profileColor="#7a93a0" initials="" /> {/* Default avatar */}
                        <div className="postText">
                          <p>
                            <IonText style={{ fontWeight: "bold" }}>
                              <span className="display-name">User unavailable</span>
                            </IonText>
                          </p>
                          <p>
                            <IonText color="medium" className="postTimestamp">
                              {getFormattedCreatedAt(comment.timestamp, "justNow")}
                            </IonText>
                          </p>
                        </div>

                      </div>
                    )}

                    {!isDeletedUser && comment.user && (
                      <div className="postCredits">
                        <UserAvatar profileColor={comment.user.profileColor} initials={comment.user.initials} />
                        <div className="postText">
                          <p>
                            <IonText style={{ fontWeight: "bold" }}>
                              <a
                                href={`/${comment.user.username}`}
                                style={{ color: "inherit", textDecoration: "none" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                {comment.user.displayName}
                              </a>
                            </IonText>{" "}
                            <IonText color="medium">
                              <a
                                href={`/${comment.user?.username}`}
                                style={{ color: "inherit", textDecoration: "none" }}
                                className="hoverUnderline"
                                onClick={(event) => {
                                  if (event.button === 1) { // Middle click
                                    event.preventDefault();
                                    event.stopPropagation();
                                    window.open(`/${comment.user?.username || 'deleted'}`, '_blank');
                                  } else {
                                    event.stopPropagation();
                                  }
                                }}
                              >
                                @{comment.user?.username}
                              </a>
                            </IonText>
                          </p>
                          <p>
                            <a
                              href={`/${comment.user?.username || 'deleted'}/post/${comment.id}`}
                              style={{ color: "inherit", textDecoration: "none" }}
                              onClick={(event) => {
                                if (event.button === 1) {
                                  // Middle click
                                  event.preventDefault();
                                  event.stopPropagation();
                                  window.open(`/${comment.user?.username || 'deleted'}/post/${comment.id}`, '_blank');
                                } else {
                                  event.stopPropagation();
                                }
                              }}
                              className="postTimestamp hoverUnderline"
                            >
                              {getFormattedCreatedAt(comment.timestamp, "justNow")}
                            </a>
                          </p>
                        </div>
                        <div className="ellipsisButtonContainer">
                          <IonButton
                            fill="clear"
                            size="small"
                            className="ellipsisButton"
                            id={`option-popover-${comment.id}`}
                            onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                            }}
                          >
                            <IonIcon icon={ellipsisHorizontal} color="medium" />
                          </IonButton>
                        </div>
                      </div>
                    )}
                    <div className="postMessage">
                      {!isDeletedUser ? (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: convertUsernameMentionsToLinks(
                              linkifyHtml(comment.text, {
                                defaultProtocol: "https",
                                formatHref: function (href: string, type: string) {
                                  if (type === "email") {
                                    return `mailto:${href}`;
                                  }
                                  return href;
                                },
                                attributes: {
                                  onclick: "event.stopPropagation();",
                                  target: "_blank", // Open links in new tabs
                                },
                                className: {
                                  url: "link",
                                  email: "email-link",
                                  hashtag: "hashtag-link",
                                  mention: "mention-link", // CSS class for @username mentions
                                },
                              }),
                              getBaseDomain(window.location.href)
                            ),
                          }}
                        ></p>
                      ) : (
                        <p>This post has been deleted.</p>
                      )}
                    </div>
                    <div className="postDetails">
                      <div className="buttonsLeft">
                        <div className="buttonContainer">
                          <IonButton
                            fill="clear"
                            id={`likeButton_${comment.id}`}
                            className={isLikedByCurrentUser ? 'liked' : ''}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();

                              if (currentUserUid) {
                                toggleLike(comment.id);
                              } else {
                                openSignInModal();
                              }
                              
                            }}
                          >
                          <IonIcon
                            slot="icon-only"
                            icon={isLikedByCurrentUser ? heart : heartOutline}
                            color={isLikedByCurrentUser ? 'danger' : 'medium'}
                          />
                          </IonButton>
                          <span>{shortenNumber(comment.likeCount)}</span>
                        </div>
                        <div className="buttonContainer">
                          <IonButton
                            fill="clear"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();

                              if (currentUserUid) {
                                openReplyModal(comment);
                              } else {
                                openSignInModal();
                              }

                            }}
                          >
                            <IonIcon slot="icon-only" icon={chatbubbleOutline} color="medium" />
                          </IonButton>
                          <span>{shortenNumber(comment.replyCount)}</span>
                        </div>
                      </div>
                      <div className="buttonsRight">
                        <div className="buttonContainer">
                          <IonButton
                            fill="clear"
                            className="share-button"
                            id={`share-popover-${comment.id}`}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            <IonIcon slot="icon-only" icon={shareOutline} color="medium" />
                          </IonButton>
                        </div>
                      </div>
                    </div>

                    <IonPopover trigger={`option-popover-${comment.id}`} className="commentOptionPopover" dismissOnSelect={true}>
                      <IonContent>
                        <IonList lines="none">
                          {isCurrentUserComment && (
                            <IonItem
                              button={true}
                              detail={false}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                openDeleteAlert(comment);
                              }}
                            >
                              <IonIcon slot="start" icon={trashOutline} color="primary" />
                              <IonLabel>Delete comment</IonLabel>
                            </IonItem>
                          )}
                          {!isCurrentUserComment && (
                            <IonItem
                              button={true}
                              detail={false}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                if (currentUserUid) {
                                  toggleFollow(comment.userID, comment.user?.username || '');
                                } else {
                                  openSignInModal();
                                }

                              }}
                            >
                              <IonIcon
                                slot="start"
                                icon={isFollowed ? personRemoveOutline : personAddOutline}
                                color="primary"
                              />

                              <IonLabel>
                                {isFollowed ? `Unfollow @${comment.user?.username}` : `Follow @${comment.user?.username}`}
                              </IonLabel>
                            </IonItem>
                          )}
                        </IonList>
                      </IonContent>
                    </IonPopover>

                    <SharePopover postID={comment.id} username={comment.user?.username ?? ''} copyConfirmationToastRef={copyConfirmationToastRef} />

                  </IonCardContent>
                </IonCard>
              </IonItem>
            </a>

          {comment.replies && comment.replies.length > 0 && (
            <>

              
                {comment.replies.map((reply) => (
                  <>
                    <div className="repliesContainer">
                      <a className="post-item-link" href={`/${reply.user?.username || 'deleted'}/post/${reply.id}`}>
                        <IonItem
                          button
                          //routerLink={`/${reply.user?.username || 'deleted'}/post/${reply.id}`}
                          detail={false}
                          lines="none"
                          className={`commentItem replyItem ${isReplyDeletedUser ? 'deleted-post' : ''}`}
                        >
                          <IonCard style={{ width: "100%" }}>
                            <IonCardContent className="singleCommentContent">
                              {isReplyDeletedUser && (
                                <div className="postCredits">
                                  <UserAvatar profileColor="#7a93a0" initials="" /> {/* Default avatar */}
                                  <div className="postText">
                                    <p>
                                      <IonText style={{ fontWeight: "bold" }}>
                                        <span className="display-name">User unavailable</span>
                                      </IonText>
                                    </p>
                                    <p>
                                      <IonText color="medium" className="postTimestamp">
                                        {getFormattedCreatedAt(reply.timestamp, "justNow")}
                                      </IonText>
                                    </p>
                                  </div>

                                </div>
                              )}

                              {!isReplyDeletedUser && reply.user && (
                                <div className="postCredits">
                                  <UserAvatar profileColor={reply.user.profileColor} initials={reply.user.initials} />
                                  <div className="postText">
                                    <p>
                                      <IonText style={{ fontWeight: "bold" }}>
                                        <a
                                          href={`/${reply.user.username}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{ color: "inherit", textDecoration: "none" }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                          }}
                                        >
                                          {reply.user.displayName}
                                        </a>
                                      </IonText>{" "}
                                      <IonText color="medium">
                                        <a
                                          href={`/${reply.user?.username}`}
                                          style={{ color: "inherit", textDecoration: "none" }}
                                          className="hoverUnderline"
                                          onClick={(event) => {
                                            if (event.button === 1) { // Middle click
                                              event.preventDefault();
                                              event.stopPropagation();
                                              window.open(`/${reply.user?.username || 'deleted'}`, '_blank');
                                            } else {
                                              event.stopPropagation();
                                            }
                                          }}
                                        >
                                          @{reply.user?.username}
                                        </a>
                                      </IonText>
                                    </p>
                                    <p>
                                      <a
                                        href={`/${reply.user?.username || 'deleted'}/post/${reply.id}`}
                                        style={{ color: "inherit", textDecoration: "none" }}
                                        onClick={(event) => {
                                          if (event.button === 1) {
                                            // Middle click
                                            event.preventDefault();
                                            event.stopPropagation();
                                            window.open(`/${reply.user?.username || 'deleted'}/post/${reply.id}`, '_blank');
                                          } else {
                                            event.stopPropagation();
                                          }
                                        }}
                                        className="postTimestamp hoverUnderline"
                                      >
                                        {getFormattedCreatedAt(reply.timestamp, "justNow")}
                                      </a>
                                    </p>
                                  </div>
                                  <div className="ellipsisButtonContainer">
                                    <IonButton
                                      fill="clear"
                                      size="small"
                                      className="ellipsisButton"
                                      id={`option-popover-${reply.id}`}
                                      onClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        //setSelectedPostId(post.id);
                                      }}
                                    >
                                      <IonIcon icon={ellipsisHorizontal} color="medium" />
                                    </IonButton>
                                  </div>
                                </div>
                              )}
                              <div className="postMessage">
                                {!isReplyDeletedUser ? (
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: convertUsernameMentionsToLinks(
                                        linkifyHtml(reply.text, {
                                          defaultProtocol: "https",
                                          formatHref: function (href: string, type: string) {
                                            if (type === "email") {
                                              return `mailto:${href}`;
                                            }
                                            return href;
                                          },
                                          attributes: {
                                            onclick: "event.stopPropagation();",
                                            target: "_blank", // Open links in new tabs
                                          },
                                          className: {
                                            url: "link",
                                            email: "email-link",
                                            hashtag: "hashtag-link",
                                            mention: "mention-link", // CSS class for @username mentions
                                          },
                                        }),
                                        getBaseDomain(window.location.href)
                                      ),
                                    }}
                                  ></p>
                                ) : (
                                  <p>This post has been deleted.</p>
                                )}
                              </div>
                              <div className="postDetails">
                                <div className="buttonsLeft">
                                  <div className="buttonContainer">
                                    <IonButton
                                      fill="clear"
                                      id={`likeButton_${reply.id}`}
                                      className={isReplyLikedByCurrentUser ? 'liked' : ''}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        if (currentUserUid) {
                                          toggleLike(reply.id);
                                        } else {
                                          openSignInModal();
                                        }
                                        
                                      }}
                                    >
                                    <IonIcon
                                      slot="icon-only"
                                      icon={isReplyLikedByCurrentUser ? heart : heartOutline}
                                      color={isReplyLikedByCurrentUser ? 'danger' : 'medium'}
                                    />
                                    </IonButton>
                                    <span>{shortenNumber(reply.likeCount)}</span>
                                  </div>
                                  <div className="buttonContainer">
                                    <IonButton
                                      fill="clear"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        if (currentUserUid) {
                                          openReplyModal(reply);
                                        } else {
                                          openSignInModal();
                                        }

                                      }}
                                    >
                                      <IonIcon slot="icon-only" icon={chatbubbleOutline} color="medium" />
                                    </IonButton>
                                    <span>{shortenNumber(reply.replyCount)}</span>
                                  </div>
                                </div>
                                <div className="buttonsRight">
                                  <div className="buttonContainer">
                                    <IonButton
                                      fill="clear"
                                      className="share-button"
                                      id={`share-popover-${reply.id}`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                      }}
                                    >
                                      <IonIcon slot="icon-only" icon={shareOutline} color="medium" />
                                    </IonButton>
                                  </div>
                                </div>
                              </div>

                              <IonPopover trigger={`option-popover-${reply.id}`} className="commentOptionPopover" dismissOnSelect={true}>
                                <IonContent>
                                  <IonList lines="none">
                                    {isCurrentUserReply && (
                                      <IonItem
                                        button={true}
                                        detail={false}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          openDeleteAlert(reply);
                                        }}
                                      >
                                        <IonIcon slot="start" icon={trashOutline} color="primary" />
                                        <IonLabel>Delete comment</IonLabel>
                                      </IonItem>
                                    )}
                                    {!isCurrentUserReply && (
                                      <IonItem
                                        button={true}
                                        detail={false}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();

                                          if (currentUserUid) {
                                            toggleFollow(reply.userID, reply.user?.username || '');
                                          } else {
                                            openSignInModal();
                                          }

                                        }}
                                      >
                                        <IonIcon
                                          slot="start"
                                          icon={isFollowed ? personRemoveOutline : personAddOutline}
                                          color="primary"
                                        />

                                        <IonLabel>
                                          {isFollowed ? `Unfollow @${reply.user?.username}` : `Follow @${reply.user?.username}`}
                                        </IonLabel>
                                      </IonItem>
                                    )}
                                  </IonList>
                                </IonContent>
                              </IonPopover>

                              <SharePopover postID={reply.id} username={reply.user?.username ?? ''} copyConfirmationToastRef={copyConfirmationToastRef} />

                            </IonCardContent>
                          </IonCard>
                        </IonItem>
                      </a>
                    </div>
                    {comment.replyCount >= 2 ? (
                      <a className="post-item-link" href={`/${comment.user?.username || 'deleted'}/post/${comment.id}`}>
                        <IonItem
                          button
                          //routerLink={`/${comment.user?.username || 'deleted'}/post/${comment.id}`}
                          detail={false}
                          lines="none"
                          className={`more-replies-button commentItem ${isDeletedUser ? 'deleted-post' : ''}`}
                        >
                          <IonLabel>More replies...</IonLabel>
                        </IonItem>
                      </a>
                    ):(

                      <br/>

                    )}
                  </>
                ))}
              
            </>
          )}
        </div>
      </>
    );
  };

  const renderComments = (comments: Comment[]) => {
    return comments.map((comment) => (
      <React.Fragment key={comment.id}>
        {renderComment(comment)}
      </React.Fragment>
    ));
  };

  return (
    <div className="commentsContent">

      {isUpdating ? (
        <div className="loadingSpinner ion-text-center">
          <IonSpinner name="circular" color="primary" />
        </div>
      ):(
        <>
          {renderComments(comments)}
        </>
      )}

      <ReplyModal isOpen={isReplyModalOpen} onClose={closeReplyModal} onSubmit={handleReplySubmit} submitCommentConfirmationToastRef={submitCommentConfirmationToastRef} />
      <IonAlert
        isOpen={deleteAlert === `comment-delete-alert-${commentToDelete?.id}`}
        onDidDismiss={closeDeleteAlert}
        header="Delete comment"
        message="Are you sure you want to delete this comment?"
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              closeDeleteAlert();
            },
          },
          {
            text: 'Delete',
            handler: () => {
              handleDeleteConfirm();
            },
          },
        ]}
      />
      <IonToast
        ref={copyConfirmationToastRef}
        message="Link copied to clipboard!"
        color="medium"
        position="top"
        duration={3000}
        class="auto-width-toast"
      />
      <IonToast
        ref={followConfirmationToastRef}
        message="Now following!"
        color="medium"
        position="top"
        duration={3000}
        class="auto-width-toast"
      />
      <IonToast
        ref={unfollowConfirmationToastRef}
        message="No longer following."
        color="medium"
        position="top"
        duration={3000}
        class="auto-width-toast"
      />
      <IonToast
        ref={submitCommentConfirmationToastRef}
        message="Comment submitted."
        color="medium"
        position="top"
        duration={3000}
        class="auto-width-toast"
      />
      <IonToast
        ref={deleteCommentConfirmationToastRef}
        message="Comment deleted."
        color="medium"
        position="top"
        duration={3000}
        class="auto-width-toast"
      />
      <SignInModal isOpen={showSignInModal} onClose={closeSignInModal} />
    </div>
  );
};

export default CommentSection;
