import { IonPage, IonContent, IonButton } from '@ionic/react';
import { RouteComponentProps, withRouter } from 'react-router';
import React from 'react';
import HeaderToolbar from '../components/HeaderToolbar';
import SideMenu from '../components/SideMenu';

type Props = RouteComponentProps;

const TermsOfService: React.FC<Props> = ({ history }) => {
  return (
    <>
      <SideMenu/>
      <IonPage id="main-content">
        <HeaderToolbar />
        <IonContent className="ion-padding">
          <div className="limitedWidth">
            
              <h1>Terms of Service</h1>

              <p>These Terms of Service ("Terms") govern your use of the website Blokkiti (https://blokkiti.com) ("Website") operated by Runner LLC ("we," "us," or "our"). By accessing or using Blokkiti, you agree to be bound by these Terms.</p>

              <h2>1. Use of Blokkiti</h2>

              <p>1.1 Eligibility: You must be at least 13 years old to use Blokkiti. By using the Website, you represent and warrant that you meet the age requirement.</p>

              <p>1.2 User Account: To access certain features of Blokkiti, you may be required to create a user account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to provide accurate, current, and complete information during the registration process. You must promptly update your account information to keep it accurate and complete.</p>

              <h2>2. User Content</h2>

              <p>2.1 Ownership: By creating and sharing content on Blokkiti, you retain ownership of the intellectual property rights in that content. However, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, adapt, publish, translate, distribute, and display your content for the purposes of operating and improving Blokkiti.</p>

              <p>2.2 Prohibited Content: You agree not to post or share any content that is unlawful, infringing, defamatory, obscene, or otherwise objectionable. We reserve the right to remove or disable any content that violates these Terms or our policies.</p>

              <h2>3. Intellectual Property</h2>

              <p>All intellectual property rights in Blokkiti, including but not limited to copyrights, trademarks, and trade secrets, are owned by Runner LLC or its licensors. You agree not to copy, modify, distribute, or create derivative works based on Blokkiti or its content without our prior written consent.</p>

              <h2>4. Third-Party Websites and Services</h2>

              <p>Blokkiti may contain links to third-party websites or services that are not owned or controlled by us. We are not responsible for the content, privacy policies, or practices of these third-party websites or services. We encourage you to read the terms and conditions and privacy policies of any third-party websites or services that you visit.</p>

              <h2>5. Limitation of Liability</h2>

              <p>To the fullest extent permitted by applicable law, in no event shall Runner LLC or its affiliates, officers, directors, employees, or agents be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of Blokkiti.</p>

              <h2>6. Indemnification</h2>

              <p>You agree to indemnify and hold harmless Runner LLC and its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or in connection with your use of Blokkiti or any violation of these Terms.</p>

              <h2>7. Modifications to the Terms</h2>

              <p>We reserve the right to modify or replace these Terms at any time. The updated Terms will be posted on Blokkiti or provided to you via email. Your continued use of Blokkiti after any modifications constitute your acceptance of the updated Terms.</p>

              <h2>8. Governing Law and Jurisdiction</h2>

              <p>These Terms shall be governed by and construed in accordance with the laws of the United States and the State of Wisconsin. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts located in Dane County, Wisconsin.</p>

              <h2>9. Contact Us</h2>

              <p>If you have any questions, concerns, or suggestions regarding these Terms, please contact us at <a href="mailto:hello@runner.studio">hello@runner.studio</a>.</p>

              <p>By using Blokkiti, you acknowledge that you have read and understood these Terms of Service and agree to be bound by them.</p>

          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

export default withRouter(TermsOfService);
