import {
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
} from "@ionic/react";
import React, { useState } from "react";
import SVG from 'react-inlinesvg';

const shapeSets = [

	/* Stock Blokki included in basic accounts */

	{
	category: "Building Foundations",
	shapes: [

		{ id: "BasicCube_N_F_1x1", name: "Simple-Cube", pack: "Building Essentials", icon: require("../../assets/shapeIcons/BasicCube_N_F_1x1.svg").default },
		{ id: "Wedge_VU_F_1x1", name: "Wedge", pack: "Building Essentials", icon: require("../../assets/shapeIcons/Wedge_VU_F_1x1.svg").default },
		{ id: "Wedge_VD_F_1x1", name: "Upside-Down Wedge", pack: "Building Essentials", icon: require("../../assets/shapeIcons/Wedge_VD_F_1x1.svg").default },
		{ id: "OuterCornerWedge_VU_F_1x1", name: "Outer-Corner Wedge", pack: "Building Essentials", icon: require("../../assets/shapeIcons/OuterCornerWedge_VU_F_1x1.svg").default },
		{ id: "OuterCornerWedge_VD_F_1x1", name: "Upside-Down Outer-Corner Wedge", pack: "Building Essentials", icon: require("../../assets/shapeIcons/OuterCornerWedge_VD_F_1x1.svg").default },
		{ id: "InnerCornerWedge_VU_F_1x1", name: "Inner-Corner Wedge", pack: "Building Essentials", icon: require("../../assets/shapeIcons/InnerCornerWedge_VU_F_1x1.svg").default },
		{ id: "InnerCornerWedge_VD_F_1x1", name: "Upside-Down Inner-Corner Wedge", pack: "Building Essentials", icon: require("../../assets/shapeIcons/InnerCornerWedge_VD_F_1x1.svg").default },
		{ id: "Wedge_H_F_1x1", name: "Sideways Wedge", pack: "Building Essentials", icon: require("../../assets/shapeIcons/Wedge_H_F_1x1.svg").default },
		{ id: "BasicCube_N_HW_1x1", name: "Standing Half-Cube", pack: "Building Essentials", icon: require("../../assets/shapeIcons/BasicCube_N_HW_1x1.svg").default },
		{ id: "BasicCube_N_QW_1x1", name: "Standing Quarter-Cube", pack: "Building Essentials", icon: require("../../assets/shapeIcons/BasicCube_N_QW_1x1.svg").default },
		{ id: "BasicCube_N_HH_1x1", name: "Half-Cube", pack: "Building Essentials", icon: require("../../assets/shapeIcons/BasicCube_N_HH_1x1.svg").default },
		{ id: "BasicCube_N_QH_1x1", name: "Quarter-Cube", pack: "Building Essentials", icon: require("../../assets/shapeIcons/BasicCube_N_QH_1x1.svg").default },
		{ id: "Prism_VU_F_1x1", name: "Prism", pack: "", icon: require("../../assets/shapeIcons/Prism_VU_F_1x1.svg").default },
		{ id: "Prism_VD_F_1x1", name: "Upside-Down Prism", pack: "", icon: require("../../assets/shapeIcons/Prism_VD_F_1x1.svg").default },
		{ id: "Prism_HV_F_1x1", name: "Standing Prism", pack: "", icon: require("../../assets/shapeIcons/Prism_HV_F_1x1.svg").default },
		{ id: "Prism_H_F_1x1", name: "Sideways Prism", pack: "", icon: require("../../assets/shapeIcons/Prism_H_F_1x1.svg").default },
		{ id: "Pyramid_VU_F_1x1", name: "Pyramid", pack: "", icon: require("../../assets/shapeIcons/Pyramid_VU_F_1x1.svg").default },
		{ id: "Pyramid_VD_F_1x1", name: "Upside-Down Pyramid", pack: "", icon: require("../../assets/shapeIcons/Pyramid_VD_F_1x1.svg").default },
		{ id: "Pyramid_H_F_1x1", name: "Sideways Pyramid", pack: "", icon: require("../../assets/shapeIcons/Pyramid_H_F_1x1.svg").default },
		{ id: "Wedge_H_HH_1x1", name: "Sideways Half-Height Wedge", pack: "Enhanced Foundations", icon: require("../../assets/shapeIcons/Wedge_H_HH_1x1.svg").default },
		{ id: "Wedge_H_QH_1x1", name: "Sideways Quarter-Height Wedge", pack: "Enhanced Foundations", icon: require("../../assets/shapeIcons/Wedge_H_QH_1x1.svg").default },

	  ],
	},	
	{
	category: "Enhanced Essentials",
	shapes: [
		{ id: "QuarterCircle_H_F_1x1", name: "Quarter Circle", pack: "Enhanced Foundations", icon: require("../../assets/shapeIcons/QuarterCircle_H_F_1x1.svg").default },
		{ id: "QuarterCircle_VU_F_1x1", name: "Quarter Circle-Ramp", pack: "Enhanced Foundations", icon: require("../../assets/shapeIcons/QuarterCircle_VU_F_1x1.svg").default },
		{ id: "QuarterCircle_VD_F_1x1", name: "Upside-Down Quarter Circle-Ramp", pack: "Enhanced Foundations", icon: require("../../assets/shapeIcons/QuarterCircle_VD_F_1x1.svg").default },
		{ id: "QuarterHemisphere_VU_F_1x1", name: "Quarter Hemisphere", pack: "Enhanced Foundations", icon: require("../../assets/shapeIcons/QuarterHemisphere_VU_F_1x1.svg").default },
		{ id: "QuarterHemisphere_VD_F_1x1", name: "Upside-Down Quarter Hemisphere", pack: "Enhanced Foundations", icon: require("../../assets/shapeIcons/QuarterHemisphere_VD_F_1x1.svg").default },
		{ id: "CurvedInnerCorner_VU_F_1x1", name: "Curved Inner-Corner", pack: "Enhanced Foundations", icon: require("../../assets/shapeIcons/CurvedInnerCorner_VU_F_1x1.svg").default },
		{ id: "CurvedInnerCorner_VD_F_1x1", name: "Upside-Down Curved Inner-Corner", pack: "Enhanced Foundations", icon: require("../../assets/shapeIcons/CurvedInnerCorner_VD_F_1x1.svg").default },
		{ id: "Round_V_F_1x1", name: "Round", pack: "Enhanced Foundations", icon: require("../../assets/shapeIcons/Round_V_F_1x1.svg").default },
		{ id: "Round_V_HH_1x1", name: "Half-Height Round", pack: "Enhanced Foundations", icon: require("../../assets/shapeIcons/Round_V_HH_1x1.svg").default },
		{ id: "Round_V_QH_1x1", name: "Quarter-Height Round", pack: "Enhanced Foundations", icon: require("../../assets/shapeIcons/Round_V_QH_1x1.svg").default },
		{ id: "Round_H_F_1x1", name: "Sideways Round", pack: "Enhanced Foundations", icon: require("../../assets/shapeIcons/Round_H_F_1x1.svg").default },
		{ id: "Round_H_HW_1x1", name: "Sideways Half-Height Round", pack: "Enhanced Foundations", icon: require("../../assets/shapeIcons/Round_H_HW_1x1.svg").default },

		{ id: "Round_H_QW_1x1", name: "Sideways Quarter-Height Round", pack: "Enhanced Foundations", icon: require("../../assets/shapeIcons/Round_H_QW_1x1.svg").default },
		
		{ id: "Cone_VU_F_1x1", name: "Simple-Cone", pack: "Enhanced Foundations", icon: require("../../assets/shapeIcons/Cone_VU_F_1x1.svg").default },
		{ id: "Cone_VD_F_1x1", name: "Upside-Down Cone", pack: "Enhanced Foundations", icon: require("../../assets/shapeIcons/Cone_VD_F_1x1.svg").default },
		{ id: "Cone_H_F_1x1", name: "Sideways Cone", pack: "Enhanced Foundations", icon: require("../../assets/shapeIcons/Cone_H_F_1x1.svg").default },
		{ id: "Sphere_N_F_1x1", name: "Sphere", pack: "Enhanced Foundations", icon: require("../../assets/shapeIcons/Sphere_N_F_1x1.svg").default },
		{ id: "Hemisphere_VU_F_1x1", name: "Hemisphere", pack: "Enhanced Foundations", icon: require("../../assets/shapeIcons/Hemisphere_VU_F_1x1.svg").default },
		{ id: "Hemisphere_VD_F_1x1", name: "Upside-Down Hemisphere", pack: "Enhanced Foundations", icon: require("../../assets/shapeIcons/Hemisphere_VD_F_1x1.svg").default },
		{ id: "Hemisphere_H_F_1x1", name: "Sideways Hemisphere", pack: "Enhanced Foundations", icon: require("../../assets/shapeIcons/Hemisphere_H_F_1x1.svg").default },


		],
	},
/*
	{
	category: "Mighty Masonry",
	shapes: [

		],
	},
	{
	category: "Cornerstones & Curves",
	shapes: [

		],
	},
	{
	category: "Square Joints & Junctions",
	shapes: [

		],
	},
	{
	category: "Tubes & Turns Toolkit",
	shapes: [

		],
	},
	{
	category: "Shingle Minded",
	shapes: [

		],
	},
	{
	category: "Green Thumb Kit",
	shapes: [

		],
	},
*/

];
type OptionsShapesProps = {
  handleObjectChange: (value: string, objectName: string, imageSrc: string) => void;
  selectedItem: string;
  itemColor: string;
};
const OptionsShapes = ({ handleObjectChange, selectedItem, itemColor }: OptionsShapesProps) => {
  
const handleClick = (value: string, objectName: string, imageSrc: string) => {
  handleObjectChange(value, objectName, imageSrc); // Call the handleObjectChange function with the correct arguments
};
  return (
<IonGrid>
      {shapeSets.map((set, i) => (
        <IonCard key={i}>
          <IonCardHeader>
            <IonCardTitle className="catTitle">{set.category}</IonCardTitle>
          </IonCardHeader>
          <IonRow>
            {set.shapes.map((shape) => (
              <IonCol size="12/8" size-md="12/8" key={shape.id}>
			  <div
			    className={"shapeButtons"}
			    onClick={() => handleClick(selectedItem, shape.id, shape.icon)}
			    id={shape.id}
			  >
			  	<SVG src={shape.icon} style={{ fill: itemColor }} />
			  </div>
			  </IonCol>
            ))}
          </IonRow>
        </IonCard>
      ))}
    </IonGrid>
  );
};

export default OptionsShapes;
