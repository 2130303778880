import { IonPage, IonContent, IonButton } from '@ionic/react';
import { RouteComponentProps, withRouter } from 'react-router';
import React from 'react';
import HeaderToolbar from '../../components/HeaderToolbar';
import SideMenu from '../../components/SideMenu';

type Props = RouteComponentProps;

const SettingsScreen: React.FC<Props> = ({ history }) => {
  return (
    <>
      <SideMenu/>
      <IonPage id="main-content">
        <HeaderToolbar />
        <IonContent className="ion-padding">
          <div className="limitedWidth">
            <h1>Settings Screen</h1>
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

export default withRouter(SettingsScreen);
